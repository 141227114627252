import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import images from '../../../../../assets/images';
import ContentLoader from "../../../../../commons/components/contentLoader";
import ImagePreload from "../../../../../commons/components/imagePreload/imagePreload";
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-prev"><i className="fi-rs-arrow-small-left"></i></span>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-next"><i className="fi-rs-arrow-small-right"></i></span>
    );
}

export default class AboutUs extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { isImagesLoaded: false };

        this.sliderImgsArr = ["/img/updated/about-us-2.jpg"];
    }

    disableLoader = () => {
        this.setState({ isImagesLoaded: true });
    };

    render() {
        const { isImagesLoaded } = this.state;

        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            arrows: true,
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            loop: true,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };


        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <i className="fi-rs-home mr-5"></i>
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                    Home
                                </Link>
                                <span></span> Pages <span></span> About us
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-10 col-lg-12 m-auto">
                                    <section className="text-center mb-50">
                                        <h2 className="title style-3 mb-40">We take care of your Health and Wealth</h2>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 mb-24">
                                                <div className="featured-card">
                                                    <img src={images.featureIconOne} alt="" />
                                                    <h4>Our Vision</h4>
                                                    <p>Create a progressive Organization matching quality standards and maintaining integrity, High ethical standards transparency and provide an environment of professionalism competence, teamwork & service excellence.</p>
                                                    {/* <a >Read more</a> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-24">
                                                <div className="featured-card">
                                                    <img src={images.featureIconTwo} alt="" />
                                                    <h4>Our Mission</h4>
                                                    <p>We strive to develop a professional organization reaching new horizons with dedication and sincerity. Ensuring complete customer satisfaction in all our endeavors. Making our society to quality standard.</p>
                                                    {/* <a >Read more</a> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-24">
                                                <div className="featured-card">
                                                    <img src={images.featureIconThree} alt="" />
                                                    <h4>Our Ethic</h4>
                                                    <p>- To cater to the real needs of a growing population. - To deliver value for money. - To focus on customer relationship & satisfaction.</p>
                                                    {/* <a >Read more</a> */}
                                                </div>
                                            </div>

                                        </div>
                                    </section>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </React.Fragment>
        );

    }
}
