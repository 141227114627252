import * as ActionTypes from "./actionTypes";


const initialState = {
    compareItems: [],
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_TO_COMPARE:
            return {...state, compareItems: action.payload};
        default:
            return {...state}
    }
};
export default reducer;