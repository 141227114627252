import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProjectRoutes from '../../config/routes/projectRoutes';
import HelperFunctions from '../../utils/HelperFunctions';

class Breadcrumb extends Component {
    render() {
        const { pageName, pageURL, subPage, isBannerBreadcrumb } = this.props;
        return (
            <div className="page-header breadcrumb-wrap">
                <div className="container">
                    {
                        isBannerBreadcrumb ? <div className="archive-header">
                            <div className="row align-items-center">
                                <div className="col-xl-3">
                                    <h1 className="mb-15">Shop</h1>
                                    <div className="breadcrumb">
                                        <Link rel="nofollow" to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                            <i className="fi-rs-home mr-5"></i>Home</Link>
                                        <span></span> {pageName}
                                    </div>
                                </div>
                            </div>
                        </div> : <div className="breadcrumb">
                            <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                <i className="fi-rs-home mr-5"></i>Home
                            </Link>
                            <span></span>
                            {
                                pageURL && <Link to={{ pathname: pageURL, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                    {pageName}
                                </Link>
                            }

                            {
                                subPage && <span></span> + subPage
                            }
                        </div>
                    }

                </div>
            </div>
        );
    }
}

export default Breadcrumb;