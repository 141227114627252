import React, { Component } from 'react';
import commonStyles from '../../../../../assets/styles';
import FeaturedCategories from '../../../common/featuredCate';
import HomeServiceBannerOne from '../../../../../commons/components/servicesBanners/homeServiceBannerOne';
import PopularProducts from '../../../common/popularProducts/popularProducts';
import DailyBestSell from '../../../common/dailyBestSell/dailyBestSell';
import DealsOfDay from '../../../common/dealsOfDay';
import SectionData from '../../../common/sectionData';
import ShopByCategory from '../../../common/shopByCategory';
import NewProductsCarousel from '../../../common/newProducts/newProductsCarousel';
import FeaturedProduct from '../../../common/featuredProducts/featuredProduct';
import TopDiscountsBanner from '../../../../../commons/components/servicesBanners/topDiscounts';
import Banners from './banners';
import { Link } from 'react-router-dom';
import HelperFunctions from '../../../../../utils/HelperFunctions';

class Home extends Component {
    render() {
        const { banners, topDiscountBanners, staticBanners, simpleTwoBanner, homeMainBanners } = this.props;

        // console.log(this.props, "this.props");
        return (
            <main className="main">
                {/* <section className="hero-3 position-relative align-items">
                    <h2 className="mb-30 text-center">What are you looking for?</h2>
                    <form className="form-subcriber d-flex mb-30 text-center">
                        <input type="email" placeholder="Your emaill address" tabIndex="-1" />
                        <button className="btn" type="submit" tabIndex="-1">Subscribe</button>
                    </form>
                    <ul className="list-inline nav nav-tabs links font-xs text-center">
                        <li className="list-inline-item nav-item"><a className="nav-link font-xs" href="shop-grid-right.html">Cake</a></li>
                        <li className="list-inline-item nav-item"><a className="nav-link font-xs" href="shop-grid-right.html">Coffes</a></li>
                        <li className="list-inline-item nav-item"><a className="nav-link font-xs" href="shop-grid-right.html">Pet Foods</a></li>
                        <li className="list-inline-item nav-item"><a className="nav-link font-xs" href="shop-grid-right.html">Vegetables</a></li>
                    </ul>
                </section> */}

                <Banners homeMainBanners={homeMainBanners} historyProps = {this.props.history}/>

                <FeaturedCategories />

                <HomeServiceBannerOne banners={banners} />

                <ShopByCategory />

                <FeaturedProduct />

                <TopDiscountsBanner banners={topDiscountBanners} />

                {/* <PopularProducts /> */}

                {
                    simpleTwoBanner && <section className="section-block mb-25">
                        <div className="container">
                            <div className="row">
                                {
                                    simpleTwoBanner.data.map((item, index) => {
                                        return (
                                            <div key={index} className="col-lg-6 col-md-6">
                                                <Link to={{
                                                    pathname: item.url,
                                                    search: HelperFunctions.getQueryStringFromURL(),
                                                    key: item,
                                                    state: {}
                                                }} className="section-card" aria-current="page">
                                                    <img src={item.base_url + item.image_url} alt="" />
                                                </Link>
                                            </div>
                                        )

                                    })
                                }
                            </div>
                        </div>
                    </section>
                }
                <NewProductsCarousel />
                {
                    staticBanners && <section className="section-block mb-25">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <Link to={{
                                        pathname: staticBanners.data.url,
                                        search: HelperFunctions.getQueryStringFromURL(),
                                        key: staticBanners.data,
                                        state: {}
                                    }} className="section-card" aria-current="page">
                                        <img src={staticBanners.data.base_url + staticBanners.data.image_url} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                {/* <DailyBestSell /> */}



                <DealsOfDay />

                {/* <SectionData /> */}
            </main>
        );
    }
}

export default Home;