import React, { Component } from 'react';
import commonStyles from '../../../../assets/styles';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import home from '../../screens/home';
import HelperFunctions from '../../../../utils/HelperFunctions';
import ProjectRoutes from '../../../../config/routes/projectRoutes';

class SectionData extends Component {

    render() {
        // console.log(this.props);
        const { recentlyAddedProducts, trendingProducts, topSellingProducts, topRatedProducts } = this.props;
        return (
            <section className="section-padding mb-30">
                <div className="container">

                    <div className="row">
                        {
                            topSellingProducts && <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                <h4 className="section-title style-1 mb-30 animated animated">Top Selling</h4>
                                <div className="product-list-small animated animated">

                                    {
                                        topSellingProducts.map((product, index) => {
                                            return (
                                                <article key={index} className="row align-items-center hover-up">
                                                    <figure className="col-md-4 mb-0">
                                                        <Link to={{
                                                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                            state: { product: product }
                                                        }}>
                                                            <img src={product.base_url + product.image_url1} alt="" />

                                                        </Link>
                                                    </figure>
                                                    <div className="col-md-8 mb-0">
                                                        <h6>
                                                            <Link to={{
                                                                pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                                search: HelperFunctions.getQueryStringFromURL(),
                                                                state: { product: product }
                                                            }}>
                                                                {product.name}
                                                            </Link>
                                                        </h6>
                                                        <div className="product-rate-cover">
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating" style={commonStyles.widthNinty}></div>
                                                            </div>
                                                            <span className="font-small ml-5 text-muted"> (4.0)</span>
                                                        </div>
                                                        <div className="product-price">
                                                            <span>{HelperFunctions.calculateFinalPrice(product.rent_per_product, product.discount)}</span>
                                                            <span className="old-price">{'₹' + product.rent_per_product}</span>
                                                        </div>
                                                    </div>
                                                </article>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        }

                        {
                            trendingProducts && <div className="col-xl-3 col-lg-4 col-md-6 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                <h4 className="section-title style-1 mb-30 animated animated">Trending Products</h4>
                                <div className="product-list-small animated animated">
                                    {
                                        trendingProducts.map((product, index) => {
                                            return (
                                                <article key={index} className="row align-items-center hover-up">
                                                    <figure className="col-md-4 mb-0">
                                                        <Link to={{
                                                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                            state: { product: product }
                                                        }}>
                                                            <img src={product.base_url + product.image_url1} alt="" />

                                                        </Link>
                                                    </figure>
                                                    <div className="col-md-8 mb-0">
                                                        <h6>
                                                            <Link to={{
                                                                pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                                search: HelperFunctions.getQueryStringFromURL(),
                                                                state: { product: product }
                                                            }}>
                                                                {product.name}
                                                            </Link>
                                                        </h6>
                                                        <div className="product-rate-cover">
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating" style={commonStyles.widthNinty}></div>
                                                            </div>
                                                            <span className="font-small ml-5 text-muted"> (4.0)</span>
                                                        </div>
                                                        <div className="product-price">
                                                            <span>{HelperFunctions.calculateFinalPrice(product.rent_per_product, product.discount)}</span>
                                                            <span className="old-price">{'₹' + product.rent_per_product}</span>
                                                        </div>
                                                    </div>
                                                </article>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }

                        {
                            recentlyAddedProducts && <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-md-0 d-none d-lg-block wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <h4 className="section-title style-1 mb-30 animated animated">Recently added</h4>
                                <div className="product-list-small animated animated">
                                    {
                                        recentlyAddedProducts.map((product, index) => {
                                            return (
                                                <article key={index} className="row align-items-center hover-up">
                                                    <figure className="col-md-4 mb-0">
                                                        <Link to={{
                                                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                            state: { product: product }
                                                        }}>
                                                            <img src={product.base_url + product.image_url1} alt="" />

                                                        </Link>
                                                    </figure>
                                                    <div className="col-md-8 mb-0">
                                                        <h6>
                                                            <Link to={{
                                                                pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                                search: HelperFunctions.getQueryStringFromURL(),
                                                                state: { product: product }
                                                            }}>
                                                                {product.name}
                                                            </Link>
                                                        </h6>
                                                        <div className="product-rate-cover">
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating" style={commonStyles.widthNinty}></div>
                                                            </div>
                                                            <span className="font-small ml-5 text-muted"> (4.0)</span>
                                                        </div>
                                                        <div className="product-price">
                                                            <span>{HelperFunctions.calculateFinalPrice(product.rent_per_product, product.discount)}</span>
                                                            <span className="old-price">{'₹' + product.rent_per_product}</span>
                                                        </div>
                                                    </div>
                                                </article>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }


                        {
                            topRatedProducts && <div className="col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-md-0 d-none d-xl-block wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <h4 className="section-title style-1 mb-30 animated animated">Top Rated</h4>
                                <div className="product-list-small animated animated">
                                    {
                                        topRatedProducts.map((product, index) => {
                                            return (
                                                <article key={index} className="row align-items-center hover-up">
                                                    <figure className="col-md-4 mb-0">
                                                        <Link to={{
                                                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                            state: { product: product }
                                                        }}>
                                                            <img src={product.base_url + product.image_url1} alt="" />

                                                        </Link>
                                                    </figure>
                                                    <div className="col-md-8 mb-0">
                                                        <h6>
                                                            <Link to={{
                                                                pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                                search: HelperFunctions.getQueryStringFromURL(),
                                                                state: { product: product }
                                                            }}>
                                                                {product.name}
                                                            </Link>
                                                        </h6>
                                                        <div className="product-rate-cover">
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating" style={commonStyles.widthNinty}></div>
                                                            </div>
                                                            <span className="font-small ml-5 text-muted"> (4.0)</span>
                                                        </div>
                                                        <div className="product-price">
                                                            <span>{HelperFunctions.calculateFinalPrice(product.rent_per_product, product.discount)}</span>
                                                            <span className="old-price">{'₹' + product.rent_per_product}</span>
                                                        </div>
                                                    </div>
                                                </article>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </section>

        );
    }
}

const mapStateToProps = state => {
    return {
        recentlyAddedProducts: state[home.constants.NAME].recentlyAddedProducts,
        trendingProducts: state[home.constants.NAME].trendingProducts,
        topSellingProducts: state[home.constants.NAME].topSellingProducts,
        topRatedProducts: state[home.constants.NAME].topRatedProducts,
    }
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionData));