import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../../../../assets/images';
import ContentLoader from '../../../../../commons/components/contentLoader';
import MessageContainer from '../../../../../commons/components/messageContainer';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import Constants from '../../../../../utils/Constants';
import HelperFunctions from '../../../../../utils/HelperFunctions';

class Wishlist extends Component {

    remove = (itemIndex) => {
        this.props.removeFromWishlist(itemIndex);
    };

    goToCart = (product, itemIndex) => {
        // add the product To the cart
        if (this.props.isRequestingForCart)
            return;

        this.props.addToCart(product, 1);
        this.remove(itemIndex);
    };

    render() {
        const { wishlistItemsDetails, removeFromWishlist, isRequestingForWhom } = this.props;
        // console.log(isRequestingForWhom);
        return (
            <main className="main">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                <i className="fi-rs-home mr-5"></i>Home</Link>
                            <span></span> Wishlist
                            {/* <span></span> Cart */}
                        </div>
                    </div>
                </div>
                {
                    isRequestingForWhom === 'wishlist/items' ? <ContentLoader loadertype="content-relative" /> : wishlistItemsDetails.length > 0 ? <div className="container mb-30 mt-50 hr-cart-wrap">
                        <div className="row">
                            <div className="col-xl-10 col-lg-12 m-auto">
                                <div className="mb-50 hr-cart-title">
                                    <h1 className="heading-2 mb-10">Your Wishlist</h1>
                                    <h6 className="text-body">There are <span className="text-brand">{wishlistItemsDetails.length}</span> products in this list</h6>
                                </div>
                                <div className="table-responsive shopping-summery">
                                    <table className="table table-wishlist">
                                        <thead>
                                            <tr className="main-heading">
                                                <th className="custome-checkbox start pl-30">
                                                    {/* <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox11" value="" />
                                                    <label className="form-check-label" htmlFor="exampleCheckbox11"></label> */}
                                                </th>
                                                <th scope="col" colSpan="2">Product</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Stock Status</th>
                                                <th scope="col">Action</th>
                                                <th scope="col" className="end">Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                wishlistItemsDetails.map((product, index) => {
                                                    const { name, base_url, description, discount, fabric, image_url1, rent_per_product, total_quantity, quantity, badge_text, badge_class, id } = product;

                                                    return (
                                                        <tr className="pt-30" key={index}>
                                                            <td className="custome-checkbox pl-30">
                                                                {/* <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox1" value="" />
                                                    <label className="form-check-label" htmlFor="exampleCheckbox1"></label> */}
                                                            </td>
                                                            <td className="image product-thumbnail pt-40">
                                                                <img src={base_url + image_url1} alt="#" />
                                                            </td>
                                                            <td className="product-des product-name">
                                                                <h6>
                                                                    <Link to={{
                                                                        pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(name) + '/' + id,
                                                                        search: HelperFunctions.getQueryStringFromURL(),
                                                                        state: { product: product },
                                                                        key: product.id
                                                                    }} className="product-name mb-10 ">
                                                                        {name}
                                                                    </Link>
                                                                </h6>
                                                                <div className="product-rate-cover">
                                                                    <div className="product-rate d-inline-block">
                                                                        <div className="product-rating" style={{ width: "90%" }}></div>
                                                                    </div>
                                                                    <span className="font-small ml-5 text-muted"> (4.0)</span>
                                                                </div>
                                                            </td>
                                                            <td className="price" data-title="Price">
                                                                <h3 className="text-brand">{HelperFunctions.calculateFinalPrice(rent_per_product, discount)}</h3>
                                                            </td>
                                                            <td className="text-center detail-info" data-title="Stock">
                                                                <span className="stock-status in-stock mb-0"> In Stock </span>
                                                            </td>
                                                            <td className="text-right" data-title="Cart">
                                                                <button className={`btn btn-sm add-loader ${isRequestingForWhom === Constants.cartAddMatch ? "disabled" : ""}`} onClick={this.goToCart.bind(this, product, index)}>
                                                                    {isRequestingForWhom === Constants.cartAddMatch && <ContentLoader loadertype="btn-loader" />}
                                                                    Add to cart
                                                                </button>
                                                            </td>
                                                            <td className="action text-center" data-title="Remove">
                                                                <a className="text-body" onClick={this.remove.bind(this, index)}><i className="fi-rs-trash"></i></a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> : <MessageContainer icon = {images.wishlistIcon} msg={`Your ${Constants.address.name} wishlist is empty.`} />
                }
            </main>

        );
    }
}

export default Wishlist;