import React, { PureComponent } from 'react';

export default class DetailsHelper extends PureComponent {
    constructor(props) {
        super(props);
        // console.log(props, "props")
        this.minQty = 0;
        this.totalQty = 0;
        if(this.props.location && this.props.location.state.product) {
            this.minQty = this.props.location.state.product.min_order_quantity;
            this.totalQty = this.props.location.state.product.total_quantity;
        } else if(props.modelProduct) {
            this.minQty = props.modelProduct.min_order_quantity;
            this.totalQty = props.modelProduct.total_quantity;
        }
        this.state = {
            quantity: this.minQty,
        };
    }

    componentDidMount() {
        if (this.props.onRef)
            this.props.onRef(this);
    }

    incQuantity = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        if (this.state.quantity < this.totalQty)
            this.setState({ quantity: this.state.quantity + 1 });
    };

    decQuantity = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        if (this.state.quantity > this.minQty)
            this.setState({ quantity: this.state.quantity - 1 });
    };

    quanChange = (event) => {
        const value = event.target.value;
        if (value >= this.minQty && value <= this.totalQty)
            this.setState({ quantity: event.target.value });
    };

    render() {
        return (
          

            <div className="detail-qty border radius">
                <a className="qty-down" onClick={this.decQuantity}><i className="fi-rs-angle-small-down"></i></a>
                <span className="qty-val">{this.state.quantity}</span>
                <a className="qty-up" onClick={this.incQuantity}><i className="fi-rs-angle-small-up"></i></a>
            </div>
        );
    }

}