import React from "react";
import { Link } from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class OTP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: ''
        }
    }

    matchOTP = (e) => {
        e.preventDefault();
        if (!this.props.isRequesting) {
            const email_mobile = this.props.location.state ? this.props.location.state.email_mobile : '';
            this.props.matchOTP(email_mobile, this.state.otp, this.props.history);
        }
    };

    requestOTPAgain = (e) => {
        e.preventDefault();
        if (this.props.location.state && this.props.location.state.email_mobile)
            this.props.requestOTPAgain(this.props.location.state.email_mobile, this.props.history);
        else
            this.props.history.push({
                pathname: ProjectRoutes.user.login.url,
                search: HelperFunctions.getQueryStringFromURL(),
            });
    };

    changeHandler = event => {
        this.setState({
            otp: event.target.value
        });
    };

    isEnabledBtn = () => {
        return (HelperFunctions.validateOTP(this.state.otp));
    };

    render() {
        const { matchOTPData, isRequesting, isRequestingOTP } = this.props;
        let isInvalidOTP = null;
        if (matchOTPData && matchOTPData.errors) {
            if (matchOTPData.errors.otp) {
                isInvalidOTP = matchOTPData.errors.otp;
            }
        }
        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                    <i className="fi-rs-home mr-5"></i>Home</Link>
                                <span></span> Enter OTP
                                {/* <span></span> Cart */}
                            </div>
                        </div>
                    </div>

                    <div className="page-content pt-150 pb-150 hr-login-wrap animate__animated animate__slideInDown animate__faster">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                                    <div className="row">
                                        {/* <div className="col-lg-6 pr-30 d-none d-lg-block">
                                            <img className="border-radius-15" src={images.loginImg} alt="" />
                                        </div> */}
                                        <div className="col-lg-6 col-md-8 hr-login-col">
                                            <div className="login_wrap widget-taber-content background-white">
                                                <div className="padding_eight_all bg-white">
                                                    <div className="heading_s1">
                                                        <h1 className="mb-5">Enter OTP</h1>
                                                        <p className="mb-30">Use OTP received on mail/mobile to recover password!
                                                        </p>
                                                    </div>
                                                    {/* <form method="post" onSubmit={this.recoverPas}> */}
                                                    <div className="form-group">
                                                        <label className="form-label">OTP received</label>
                                                        <input className={`form-control ${isInvalidOTP && 'is-invalid'}`}
                                                            type="text"
                                                            placeholder="Enter OTP here..." onChange={this.changeHandler}
                                                            value={this.state.otp} />
                                                        <div className="invalid-feedback">{isInvalidOTP}</div>
                                                    </div>


                                                    <div className="login_footer hr-forgot-pass form-group mb-50">
                                                        <a href="" onClick={this.requestOTPAgain} className="link-secondary text-sm">
                                                            {isRequestingOTP && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                            Request OTP Again
                                                        </a>
                                                    </div>
                                                    <div className="form-group hr-login-btn">
                                                        {/* <button type="submit" className="btn btn-heading btn-block hover-up" name="login">Log in</button> */}
                                                        <a onClick={this.matchOTP} className={`btn btn-heading btn-block hover-up ${this.isEnabledBtn() ? '' : 'disabled'}`}>
                                                            {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                            Submit OTP
                                                        </a>

                                                        {/* <button className={`btn btn-primary ${this.isEnabledBtn() ? '' : 'disabled'}`} type=" submit">
                                                                {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                                Recover Password
                                                            </button> */}
                                                    </div>
                                                    {/* </form> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
