import React from 'react';
import { Link } from 'react-router-dom';
import ContentLoader from "../../../../../commons/components/contentLoader";
import ImagePreload from "../../../../../commons/components/imagePreload/imagePreload";
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import Constants from '../../../../../utils/Constants';
import HelperFunctions from '../../../../../utils/HelperFunctions';

export default class TermsOfUse extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isImagesLoaded: false };

        this.sliderImgsArr = ["/img/updated/bg-about-us.jpg"];
    }

    disableLoader = () => {
        this.setState({ isImagesLoaded: true });
    };

    render() {
        const { isImagesLoaded } = this.state;


        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <i className="fi-rs-home mr-5"></i>
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                    Home
                                </Link>
                                <span></span> Pages <span></span> Terms of use
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 m-auto">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                                                <div className="single-header style-2">
                                                    <h2>Terms of use</h2>
                                                </div>

                                                <div className="single-content mb-50">
                                                    <p>
                                                        You shall register to become a member of the “hindroot.com” Website only if you are of the age of 18 or above and can enter into binding contracts as per applicable laws.
                                                    </p>

                                                    <p>
                                                        · By making use of the Website, and furnishing your personal/contact details, you hereby agree that you are interested in availing and purchasing the Services that you have selected.                                                    </p>

                                                    <p>
                                                        · You will be responsible for all use of the “hindroot.com” website by you and anyone using your password and login information (with or without your permission).
                                                    </p>

                                                    <p>
                                                        · You also agree to provide accurate, current, correct and complete information about yourself as and when prompted by the “hindroot.com” website. If you provide any information that is false, dubious, inaccurate, not updated or incomplete (or later becomes untrue, inaccurate, not updated or incomplete while placing the order), or “hindroot.com” has reasonable grounds to doubt truth, accuracy, updatedness or completeness of the information, we shall have the right to suspend or terminate your account and/or refuse any and all current or future use of the website (or any portion thereof) or Services in connection thereto.
                                                    </p>

                                                    <p>
                                                        · You are responsible for maintaining the secrecy of your passwords, login, account information & other secret information. · You hereby agree that, the representative of “hindroot.com” or its appointed third party may contact you either electronically, through phone or any other mode of communication, to take your feedback, update you for new products & offers or understand your interest in the selected products to fulfil your demand or complete your order.
                                                    </p>

                                                    <p>
                                                        · You specifically understand and agree that by using the website you authorize “hindroot.com” to contact you for any follow-up calls in relation to the Services provided through the website. You agree and consent to receive communications on your phone/mobile number provided by you on our Website and expressly waive any registration made under DND/NCPR list under TRAI regulations.
                                                    </p>

                                                    <p>
                                                        · You agree and authorize “hindroot.com” to share your information with its group companies and other third parties, in so far as required for marketing purposes/offering various Services and/or to provide you with various value-added services, in association with the Services selected by you or otherwise.
                                                    </p>

                                                    <p>
                                                        · You agree to receive communications through e-mails, telephone and/or SMS, from {Constants.address.name} Care or its third-party vendors regarding the Services/ancillary Service updates, information/promotional emails and/or product announcements. If you request not to receive such communication/marketing material any further, it shall be applied prospectively only and shall not apply in respect to your data already shared by {Constants.address.name} Care with its partners upon your prior consent. In case you do not wish to receive any such communication/marketing materials from {Constants.address.name} Care, we request you to kindly get in touch with us “info@hindroot.com”.
                                                    </p>

                                                    <p>
                                                        Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you shall not register as a User of {Constants.address.name} Care website and shall not transact on or use the website. But if a minor wishes to use the service, they can do so under the guidance of their parent/guardian. We reserve the right to terminate your membership and / or refuse to provide you with the services if you do not abide by the above condition.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>
        );

    }
}
