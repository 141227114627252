import * as ActionTypes from "./actionTypes";

const cart_items = localStorage.getItem("cart_items");
const initialState = {
    cartItems: cart_items ? JSON.parse(cart_items) : [],
    cartItemsDetails: [],
    isOrderCompleted: false,
    cartOrderData: {},
    isShowPaymentLoader:false,
    cartProdcutId : null
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_TO_CART:
            return {...state, cartItems: action.payload};
        case ActionTypes.GET_CART_ITEMS:
            return {...state, cartItemsDetails: action.payload};
        case ActionTypes.COMPLETE_ORDER:
            return {...state, ...action.payload};
        case ActionTypes.PAYMENT_LOADER:
            return {...state, ...action.payload};
        case ActionTypes.ADD_ORDER:
            return {...state, cartOrderData: action.payload};
        case ActionTypes.ACTIVE_PRODCUT_CART_ID:
            // console.log(action.payload, "cartProdcutId");
            return {...state, cartProdcutId: action.payload};
        default:
            return {...state}
    }
};
export default reducer;