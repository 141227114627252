import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import ImageWithLoad from '../../../../../commons/components/imagePreload/imageWithLoad';
import HelperFunctions from '../../../../../utils/HelperFunctions';

function SliderBtnNext(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-prev"><i className="fi-rs-arrow-small-left"></i></span>
    );
}

function SliderBtnPrev(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-next"><i className="fi-rs-arrow-small-right"></i></span>
    );
}

class Banners extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dragging: false
        }
    }

    redirectionToDetail = (item) => {
        if (this.state.dragging)
            return;

        this.props.historyProps.push({
            pathname: item.url,
            search: HelperFunctions.getQueryStringFromURL(),
            key: item,
            state: {}
        });
    }


    render() {
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            // fade: true,
            loop: true,
            dots: true,
            arrows: true,
            infinite: true,
            pauseOnHover: true,
            swipeToSlide: true,
            nextArrow: <SliderBtnPrev />,
            prevArrow: <SliderBtnNext />,
            beforeChange: () => this.setState({ dragging: true }),
            afterChange: () => this.setState({ dragging: false }),
        };




        const { homeMainBanners, historyProps } = this.props;
        return homeMainBanners && homeMainBanners.data.length > 0 && (
            <section className="home-slider position-relative mb-30 home-page-slider">
                <div className="home-slide-cover">
                    <div className="container" >
                        <Slider className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1" id="carausel-10-columns" {...settings}>
                            {
                                homeMainBanners.data.map((item, index) => {
                                    return (
                                        <div onClick={this.redirectionToDetail.bind(this, item)} key={index} className="single-hero-slider rectangle single-animation-wrap" style={`background-image: url(${item.base_url + item.image_url})`}>
                                            <a>
                                                <ImageWithLoad className="img-fluid" src={item.base_url + item.image_url} alt=".." />
                                                {/* <img src={item.base_url + item.image_url} /> */}
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </Slider>

                        <div className="slider-arrow hero-slider-1-arrow"></div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Banners;