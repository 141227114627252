import APIEndpoints from "../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../config/routes/projectRoutes";
import Constants from "../../../../utils/Constants";
import HelperFunctions from "../../../../utils/HelperFunctions";
import home from "../home";
import shop from "../shop";
import header from "../header";
import * as ActionTypes from "./actionTypes";
import * as constants from "./constants";
import { Slide, toast } from "react-toastify";

export function callOnPageLoad(history) {
    return (dispatch, getState) => {

        // console.log(history, "history.location");
        let urlParams = history.location.state;
        if (urlParams !== undefined) {
            let cateID = urlParams.category_id ? urlParams.category_id : '';
            let proID = urlParams.product_id ? urlParams.product_id : '';
            let propTypes = { "cat_id": cateID, "prod_id": proID, "defaultNum": Constants.defaultPageSize };
            dispatch(getShopProducts(propTypes));
        } else {
            const { categories } = getState()[header.constants.NAME];
            let pathname = history.location.pathname;
            if (pathname) {
                pathname = pathname.substr(1, pathname.length);
                if (pathname.charAt(pathname.length - 1) === '/') {
                    pathname = pathname.substr(0, pathname.length - 1);
                }
                const urlArr = pathname.split('/');
                if (urlArr.length > 0) {
                    var category = urlArr[1];
                    var cateInfo = null;
                    var prodInfo = null;
                    categories.length > 0 && categories.map((cat, index) => {
                        if (HelperFunctions.replaceSpace(cat.name) === category) {
                            cateInfo = cat;
                        }
                        if (urlArr.length === 3) {
                            var productType = urlArr[2];
                            cat.product_types.map((prodType, prIndex) => {
                                if (HelperFunctions.replaceSpace(prodType.name) === productType) {
                                    prodInfo = prodType;
                                }
                            })
                        }
                    })

                    var urlToset = ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(cateInfo.name);
                    var stateToSet = { category_id: cateInfo.id };
                    var productId = '';
                    if (prodInfo) {
                        urlToset += HelperFunctions.replaceSpace(prodInfo.name);
                        stateToSet = { category_id: cateInfo.id, product_id: prodInfo.id }
                        productId = prodInfo.id;
                    }

                    history.replace({
                        pathname: urlToset,
                        search: HelperFunctions.getQueryStringFromURL(),
                        key: cateInfo.name,
                        state: stateToSet
                    });


                    let propTypes = { "cat_id": cateInfo.id, "prod_id": productId, "defaultNum": Constants.defaultPageSize };
                    dispatch(getShopProducts(propTypes));
                    // dispatch(getShopProducts(cateInfo.id, productId, Constants.defaultPageSize, 1));
                }
            }
        }

        HelperFunctions.removeMobileNavOverlay();

    }
}

export function getProductWithDetail(history) {

    return (dispatch, getState) => {
        let pathname = history.location.pathname;
        // console.log(pathname, "check");
        if (pathname) {
            pathname = pathname.substr(1, pathname.length);
            if (pathname.charAt(pathname.length - 1) === '/') {
                pathname = pathname.substr(0, pathname.length - 1);
            }
            const urlArr = pathname.split('/');

            // console.log(urlArr, "urlArr");

            if (urlArr.length > 0) {

                //check for details first
                if (urlArr[1] && "/" + urlArr[0] + "/" + urlArr[1] === ProjectRoutes.shop.detail.url) {
                    // console.log("check exi")
                    let notMatched = true;
                    if (urlArr.length === 4) {
                        const product_id = urlArr[3];
                        // console.log(product_id);
                        dispatch({
                            type: ActionTypes.GET_PRODUCT_WITH_ID,
                            payload: { isRequestingForWhom: "shop/product-detail" },
                            promise: APIEndpoints.getProductWithId(`?product_id=${product_id}`),
                            callback: (payload) => {
                                if (payload) {
                                    if (payload.statusCode === 200 && payload.content) {
                                        CalculateRating([payload.content]);
                                        history.replace({
                                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(payload.content.name) + '/' + payload.content.id,
                                            search: HelperFunctions.getQueryStringFromURL(),
                                            state: { product: payload.content }
                                        });
                                    }
                                }
                            }
                        });
                    } else {
                        history.replace({
                            pathname: ProjectRoutes.shop.url,
                            search: HelperFunctions.getQueryStringFromURL()
                        });
                    }
                }
                // check for shop url
                // else if ("/" + urlArr[0] === ProjectRoutes.shop.url) {
                //     const { categories } = getState()[shop.constants.NAME];

                //     let notMatched = true;
                //     if (urlArr.length === 1) {
                //         // call get product API based on all products
                //         dispatch(home.actions.getProduct("", "", "", true, Constants.defaultPageSize, 1));
                //         notMatched = false;
                //     }
                //     else if (urlArr.length === 2) { // only category
                //         if (urlArr[1] === "all-products") {
                //             // call get product API based on all products
                //             dispatch(home.actions.getProduct("", "", "", true, Constants.defaultPageSize, 1));
                //         }
                //         else {
                //             for (let i = 0; i < categories.length; i++) {
                //                 if (HelperFunctions.replaceSpace(categories[i].name) === urlArr[1]) { // this is the match for cat id
                //                     // call get product API based on this cat id
                //                     dispatch(home.actions.getProduct("", categories[i].id, "", true, Constants.defaultPageSize, 1));
                //                     // replace the current screen to have the initial states
                //                     history.replace({
                //                         pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(categories[i].name),
                //                         search: HelperFunctions.getQueryStringFromURL(),
                //                         key: categories[i].name,
                //                         state: { category_id: categories[i].id }
                //                     });
                //                     notMatched = false;
                //                     break;
                //                 }
                //             }
                //         }
                //     }
                //     else if (urlArr.length === 3) { // category and productType
                //         let notMatched = true;
                //         for (let i = 0; i < categories.length; i++) {
                //             if (HelperFunctions.replaceSpace(categories[i].name) === urlArr[1]) { // this is the match for cat id
                //                 for (let j = 0; j < categories[i].product_types.length; j++) {
                //                     if (HelperFunctions.replaceSpace(categories[i].product_types[j].name) === urlArr[2]) { // this is the match for product type id
                //                         // call get product API based on this cat id and product type id
                //                         dispatch(home.actions.getProduct("", categories[i].id, categories[i].product_types[j].id, true, Constants.defaultPageSize, 1));
                //                         // replace the current screen to have the initial states
                //                         history.replace({
                //                             pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(categories[i].name) + "/" + HelperFunctions.replaceSpace(categories[i].product_types[j].name),
                //                             search: HelperFunctions.getQueryStringFromURL(),
                //                             key: categories[i].product_types[j].name,
                //                             state: { category_id: categories[i].id, product_type_id: categories[i].product_types[j].id }
                //                         });
                //                         notMatched = false;
                //                         break;
                //                     }
                //                 }
                //                 break;
                //             }
                //         }
                //     }
                //     // if url does not match anywhere just show no products matching message
                //     if (notMatched) {
                //         dispatch(home.actions.setEmptyProduct());
                //     }
                // }
            }
        }
    }
}

export function emptyShopProducts() {
    return (dispatch, getState) => {
        // based on URL on shopping page call the API
        dispatch({
            type: ActionTypes.GET_SHOP_PRODUCTS,
            payload: null
        });
    }
}

export function getRelatedProduct(cateID, proID) {
    return (dispatch, getState) => {

        const { shopProducts } = getState()[constants.NAME];

        // console.log(shopProducts, "api call");
        cateID = cateID ? cateID : '';
        proID = proID ? proID : '';

        var qString = "?page_size=15&page_number=1&is_active=true";

        dispatch({
            type: ActionTypes.GET_SHOP_RELATED_PRODUCTS,
            promise: APIEndpoints.getProduct('', cateID, proID, qString),
            callback: (payload) => {
                if (payload && payload.products.length > 0) {
                    CalculateRating(payload.products);
                }
            }
        });
    }
}

export function getShopProducts(propObj, isLoadMore = false) {
    return (dispatch, getState) => {

        const { shopProducts } = getState()[constants.NAME];

        // console.log(propObj, "api call");
        var cateID = propObj.cat_id ? propObj.cat_id : '';
        var proID = propObj.prod_id ? propObj.prod_id : '';

        dispatch({
            type: ActionTypes.GET_SHOP_PRODUCTS_LOADING,
            payload: isLoadMore ? false : true,
        });

        if (isLoadMore) {
            dispatch({
                type: ActionTypes.PRODUCTS_LOAD_MORE,
                payload: isLoadMore,
            });
        }

        // HelperFunctions.scrollToTop();

        var pageSize = isLoadMore ? propObj.defaultNum : Constants.defaultPageSize;

        var qString = "?page_size=" + pageSize + "&page_number=1&is_active=true";

        dispatch({
            type: ActionTypes.GET_SHOP_PRODUCTS,
            promise: APIEndpoints.getProduct('', cateID, proID, qString),
            isLoadMore: isLoadMore,
            callback: (payload) => {
                // console.log(payload, "payload");
                if (payload && payload.products.length > 0) {
                    CalculateRating(payload.products);
                }

                dispatch({
                    type: ActionTypes.PRODUCTS_LOAD_MORE,
                    payload: false,
                });

                dispatch({
                    type: ActionTypes.GET_SHOP_PRODUCTS_LOADING,
                    payload: false,
                });
            }
        });
    }
}

function CalculateRating(products) {
    products.map((product, index) => {
        var totalRating = 0;
        var fiveStar = 0;
        var fourStar = 0;
        var threeStar = 0;
        var twoStar = 0;
        var oneStar = 0;
        product.ratings.length > 0 && product.ratings.map((rating, ratIndex) => {
            totalRating = totalRating + rating.rating;
            if (rating.rating > 4 && rating.rating <= 5)
                fiveStar++;

            if (rating.rating > 3 && rating.rating <= 4)
                fourStar++;

            if (rating.rating > 2 && rating.rating <= 3)
                threeStar++;

            if (rating.rating > 1 && rating.rating <= 2)
                twoStar++;

            if (rating.rating > 0 && rating.rating <= 1)
                oneStar++;
        })
        if (product.ratings.length > 0) {
            product.avg_rating = (totalRating / product.ratings.length).toFixed(1);

            product.one_rating = oneStar;
            product.two_rating = twoStar;
            product.three_rating = threeStar;
            product.four_rating = fourStar;
            product.five_rating = fiveStar;
        }
    });
}

export function getProductType(group_id, category_id, isEmptyFirst = true) {
    return (dispatch, getState) => {

        /*if (isEmptyFirst)
            dispatch({type: ActionTypes.GET_PRODUCT_TYPE, payload: group_id === '' ? [] : null})
*/
        /*if (group_id === '')
            return;*/

        const categories = HelperFunctions.copyObject(getState()[shop.constants.NAME].categories);

        dispatch({
            type: ActionTypes.GET_PRODUCT_TYPE,
            payload: { isRequestingForWhom: isEmptyFirst && "sale/product-type/list" },
            promise: APIEndpoints.getProductType(group_id, category_id),
            data: { categories, category_id }
        })
    }
}

export function submitReviewForm(data) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.SUBMIT_REVIEW_FORM,
            payload: { isRequestingForWhom: Constants.submitReviewFrom },
            promise: APIEndpoints.postRating(data),
            callback: (payload) => {
                // console.log(payload, "payload");
                if (payload.statusCode === 200) {
                    this.toastId = toast(payload.message, {
                        transition: Slide,
                        closeButton: true,
                        autoClose: 3000,
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.SUCCESS,
                        hideProgressBar: true,
                    })
                } else {
                    this.toastId = toast(payload.message, {
                        transition: Slide,
                        closeButton: true,
                        autoClose: 2500,
                        position: toast.POSITION.TOP_CENTER,
                        type: 'error',
                        hideProgressBar: true,
                    })
                }
            }
        });
    }
}