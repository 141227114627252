import React from "react";
import images from "../../../assets/images";
import DateHelper from "../../../utils/DateHelper";
import "./style.css";
import Constants from "../../../utils/Constants";

export default class Invoice extends React.PureComponent {

    componentDidMount() {
        var self = this;
        setTimeout(() => {
            self.generatePDF.bind(this, self.props.order.order_id, () => {
                this.props.toggle();
            });
        }, 500)
    };

    generatePDF = (order_id, callback) => {
        // Choose the element that our invoice is rendered in.
        // const element = document.getElementById('pdfdiv');
        // Choose the element and save the PDF for our user.
        // var opt = {
        //     margin: 0,
        //     filename: 'invoice-' + order_id + '.pdf',
        //     image: { type: 'jpeg', quality: 0.98 },
        //     html2canvas: { scale: 1 },
        //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        //     pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        // };
        // html2pdf().from(element).set(opt).save().then(() => {
        //     callback();
        // });
    }

    render() {
        const { order } = this.props;
        return (
            <div id="pdfdiv">
                <header className="clearfix">
                    <div id="logo">
                        <img src={images.logo} />
                    </div>
                    <div id="company">
                        <h2 className="name">{Constants.address.name}</h2>
                        <div>{Constants.address.address}</div>
                        <div>{Constants.address.pin}</div>
                        <div>{Constants.address.area}</div>
                        <div><a href={`mailto:${Constants.address.email}`}>{Constants.address.email}</a></div>
                        <div><a href={`tel:${Constants.address.phone}`}>{Constants.address.phone}</a></div>
                    </div>
                </header>
                <main>
                    <div id="details" className="clearfix">
                        <div id="client">
                            <div className="to">INVOICE TO:</div>
                            <h2 className="name">{order.name}</h2>
                            <div className="address">
                                {order.address.address},<br />
                                {order.address.city_name + ' - ' + order.address.pin_code + ', ' + order.address.state_name + ', ' + order.address.country_name}
                            </div>
                            <div className="email"><a href="mailto:john@example.com">{order.email}</a></div>
                        </div>
                        <div id="invoice">
                            <h1>{"INVOICE " + order.order_id}</h1>
                            <div className="date">{"Date of Invoice: " + DateHelper.getDisplayDateFromStamp(order.order_date, false)}</div>
                            <div className="date">{"Due Date: " + DateHelper.getDisplayDateFromStamp(order.order_date, false)}</div>
                        </div>
                    </div>
                    <table border="0" cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <th className="no">#</th>
                                <th className="desc">DESCRIPTION</th>
                                <th className="unit">UNIT PRICE</th>
                                <th className="total">TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.order_items.length > 0 && order.order_items.map((item, index) => {
                                const itemCost = parseInt(item.rent_per_product - item.rent_per_product * item.discount / 100) * item.quantity;
                                return (
                                    <tr>
                                        <td className="no">{index}</td>
                                        <td className="desc">
                                            <h3>{item.name}<span className="text-muted">{" x" + item.quantity}</span></h3>
                                        </td>
                                        <td className="unit">
                                            <span>
                                                <span className="text-decoration-line-through text-muted">
                                                    {'₹' + item.rent_per_product}
                                                </span>
                                                {'    ₹' + itemCost}
                                                <span className="text-success small">{' (' + item.discount + '% off)'}</span>
                                            </span>
                                        </td>
                                        <td className="total">{'₹' + itemCost}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="1"></td>
                                <td colSpan="2">SUBTOTAL</td>
                                <td>{'₹' + order.total}</td>
                            </tr>
                            <tr>
                                <td colSpan="1"></td>
                                <td colSpan="2">5% IGST</td>
                                <td>including in subtotal</td>
                            </tr>
                            <tr>
                                <td colSpan="1"></td>
                                <td colSpan="2">GRAND TOTAL</td>
                                <td>{'₹' + order.total}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <div id="thanks">Thank you!</div>
                    <div id="notices">
                        <div>Hindroot</div>
                        <div className="notice">Invoice was created on a computer and is valid without the signature and seal.</div>
                    </div>
                </main>
            </div>
        );
    }
}