import * as ActionTypes from './actionTypes';
/*
* @isRequesting => if true show global progress else hide it (used in root navigator)
* @isRequestingForWhom => it will be used to show progress on buttons (like no global progress but progress on individual pages) store a string here and 
compare the string in local pages on which you want to show the progress
* @error => @isError ==> if true error occurred while requesting else false
*           @error   ==> complete error object received from server (error message, error code etc.)
* @totalRequestCount => loader will be visible until totalRequestCount === 0
* @networkStatus => what is the network status connected or disconnected,
* @currentOrientation => represents the current orientation of the device and height and width to that particular orientation
*
* */

const initialState = {
    isRequesting: false,
    isRequestingForWhom: '',
    totalRequestCount: 0,
    currentOrientation: {
        orientation: window.innerHeight > window.innerWidth ? 'PORTRAIT' : 'LANDSCAPE',
        height: window.innerHeight,
        width: window.innerWidth,
    },
    error: {
        isError: false,
        error: null
    },
    networkStatus: null,
    userInfo: null,
    userIp: null,
    modelProduct: null,
    featuredCategories: null
}
const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.REQUEST:
            return {
                ...state, isRequesting: true,
                isRequestingForWhom: action.payload ? action.payload.isRequestingForWhom : '',
                totalRequestCount: state.totalRequestCount + 1,
            };
        case ActionTypes.REQUEST_COMPLETE:
            return {
                ...state, isRequesting: false,
                isRequestingForWhom: '',
                totalRequestCount: state.totalRequestCount > 0 ? state.totalRequestCount - 1 : 0,
            };
        case ActionTypes.SET_CURRENT_ORIENTATION:
            return {
                ...state, currentOrientation: action.payload.currentOrientation,
            };
        case ActionTypes.SET_NETWORK_STATUS:
            return {
                ...state, networkStatus: action.payload.networkStatus,
            };
        case ActionTypes.REQUEST_FAILURE:
            return {
                ...state,
                isRequesting: false,
                isRequestingForWhom: '',
                error: {
                    isError: true,
                    error: action.payload.err
                },
            };
        case ActionTypes.MODEL_PRODUCT:
            return { ...state, modelProduct: action.payload };
        case ActionTypes.GET_FEATURED_CATEGORY:
            return { ...state, featuredCategories: action.payload };
        default:
            return { ...state }
    }
};
export default commonReducer;