import React, { Component } from 'react';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import reducer from './reducer';
import { connect } from "react-redux";
import Header from './components';
import user from '../user';
import cart from '../cart';
import wishllist from '../wishllist';
import common from '../../common';
import compare from '../compare';
import ContentLoader from '../../../../commons/components/contentLoader';
import ProjectRoutes from '../../../../config/routes/projectRoutes';
import $ from 'jquery';

class HeaderSmart extends Component {

    componentDidMount() {
        // this.props.callOnPageLoad(this.props.historyProps);
        // console.log(this.props);
        if (this.props.path === "/" || this.props.path === ProjectRoutes.home.url) {
            $("body").addClass("hr-home-wrap");
        } else {
            $("body").removeClass("hr-home-wrap");
        }
    }


    render() {
        const { isRequestingForWhom } = this.props;
        return (
            <React.Fragment>
                <Header {...this.props} />
                {(isRequestingForWhom === "cart/remove-update" ||  isRequestingForWhom === "compare/add" || isRequestingForWhom === "wishlist/add" || isRequestingForWhom === "wishlist/remove-update") && <ContentLoader loaderType="fixed-loader" /> }
            </React.Fragment>

        );
    }
}

const mapStateToProps = state => ({
    categories: state[constants.NAME].categories,
    userData: state[user.constants.NAME].userData,
    cartItemsLength: state[cart.constants.NAME].cartItems.length,
    wishlistItemsLength: state[wishllist.constants.NAME].wishlistItems.length,
    compareItemsLength: state[compare.constants.NAME].compareItems.length,
    productModal: state[constants.NAME].productModal,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (pathname) => dispatch(actions.callOnPageLoad(pathname)),
        showProductModal: (pathname) => dispatch(actions.showProductModal(pathname)),
        addToCart: (modelProduct, quantity) => dispatch(cart.actions.addToCart(modelProduct, quantity)),
    }
};

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderSmart);
export default { actionTypes, actions, reducer, constants };