import React from 'react';
import { Link } from "react-router-dom";
import images from '../../../../../assets/images';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class RecoverPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email_mobile: ''
        }
    }

    recoverPas = (e) => {
        e.preventDefault();
        if (!this.props.isRequesting) {
            this.props.forgotPassword(this.state.email_mobile, this.props.history);
        }
    };

    changeEmailMobileHandler = event => {
        this.setState({
            email_mobile: event.target.value
        });
    };

    isEnabledBtn = () => {
        return (HelperFunctions.validateEmail(this.state.email_mobile) || HelperFunctions.validateMobile(this.state.email_mobile));
    };

    render() {
        const { forgotPassData, isRequesting } = this.props;
        let isInvalidEmailMob = null;
        if (forgotPassData && forgotPassData.message) {
            // isInvalidEmailMob = forgotPassData.message;
        }

        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                    <i className="fi-rs-home mr-5"></i>Home</Link>
                                <span></span> Forgot Password
                                {/* <span></span> Cart */}
                            </div>
                        </div>
                    </div>

                    <div className="page-content pt-150 pb-150 hr-login-wrap animate__animated animate__slideInUp animate__faster">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                                    <div className="row">
                                        {/* <div className="col-lg-6 pr-30 d-none d-lg-block">
                                            <img className="border-radius-15" src={images.loginImg} alt="" />
                                        </div> */}
                                        <div className="col-lg-6 col-md-8 hr-login-col">
                                            <div className="login_wrap widget-taber-content background-white">
                                                <div className="padding_eight_all bg-white">
                                                    <div className="heading_s1">
                                                        <h1 className="mb-5">Forgot Password</h1>
                                                        <p className="mb-30">Use the form below to recover it.
                                                        </p>
                                                    </div>
                                                    {/* <form method="post" onSubmit={this.recoverPas}> */}
                                                        <div className="form-group">
                                                            <input className={`form-control ${isInvalidEmailMob && 'is-invalid'}`}
                                                                type="text"
                                                                placeholder="Email/Mobile here..." onChange={this.changeEmailMobileHandler}
                                                                value={this.state.email_mobile} />
                                                            <div className="invalid-feedback">{isInvalidEmailMob}</div>
                                                        </div>


                                                        <div className="login_footer hr-forgot-pass form-group mb-50">
                                                            <Link to={ProjectRoutes.user.login.url + HelperFunctions.getQueryStringFromURL()} className="link-secondary text-sm">
                                                                Sign in existing account
                                                            </Link>
                                                        </div>
                                                        <div className="form-group hr-login-btn">
                                                            {/* <button type="submit" className="btn btn-heading btn-block hover-up" name="login">Log in</button> */}
                                                            <a onClick={this.recoverPas} className={`btn btn-heading btn-block hover-up ${this.isEnabledBtn() ? '' : 'disabled'}`} >
                                                                {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                                Recover Password
                                                            </a>

                                                            {/* <button className={`btn btn-primary ${this.isEnabledBtn() ? '' : 'disabled'}`} type=" submit">
                                                                {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                                Recover Password
                                                            </button> */}
                                                        </div>
                                                    {/* </form> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>


            </React.Fragment>
        )
    }
}
