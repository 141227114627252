import React, { PureComponent } from 'react';

import $ from 'jquery';
// import OwlCarousel from 'react-owl-carousel2';
import { Link } from "react-router-dom";
// import SwiperCore, { A11y, Autoplay, Navigation, Pagination, Parallax, Thumbs } from 'swiper';
// import { Swiper } from 'swiper/react';
import ImageWithLoad from "../../../../../commons/components/imagePreload/imageWithLoad";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import Constants from "../../../../../utils/Constants";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import DetailsHelper from "./detailsHelper";
import Slider from "react-slick";
import ContentLoader from '../../../../../commons/components/contentLoader';
import RelatedProducts from '../../../common/relatedProduct/relatedProducts';
import images from '../../../../../assets/images';
import ImageMagnify from '../../../../../commons/components/imageMagnify';
import SubmitReview from './submitReview';
import VendorDetails from './vendorDetails';
import ImageSlider from '../../../../../commons/components/imageSlider';

// SwiperCore.use([Navigation, Pagination, A11y, Autoplay, Parallax, Thumbs]);
export default class Detail extends PureComponent {


    constructor(props) {
        super(props);
        this.state = {
            quantity: this.props.location.state.product ? this.props.location.state.product.min_order_quantity : 0,
            activeTab: "desc",
            slider1: null,
            slider2: null,
            showAllReviews: false
        };
        this.dHelperRef = null;
        this.value = 0;
    }

    componentDidMount() {
        const product = this.props.location.state ? this.props.location.state.product : {};
        // console.log(product, "product");
        if (product && product.group_id) {
            this.props.getRelatedProduct(product.category_id, product.product_type_id)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.setState({
            slider1: this.slider1,
            slider2: this.slider2
        });
    }

    onDHelperRef = (ref) => {
        this.dHelperRef = ref;
    };

    relatedItemClick = (product) => {
        this.props.history.replace({
            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
            search: HelperFunctions.getQueryStringFromURL(),
            state: { product: product }
        });
    };

    goToCart = () => {
        // add the product To the cart
        if (this.props.isRequesting)
            return;

        this.props.addToCart(this.props.location.state.product, this.dHelperRef.state.quantity);
    };

    goToWishlist = (wishListId) => {
        // console.log(this.props.isRequesting, "this.props.isRequesting");
        if (this.props.isRequesting)
            return;

        this.props.addToWishlist(this.props.location.state.product, wishListId);
    }

    handleTabs = (tab) => {
        this.setState({
            activeTab: tab
        })
    }



    showReviews = () => {
        this.setState({
            showAllReviews: true
        })
    }

    render() {
        const { products, userData, isRequesting, wishlistItems } = this.props;



        const product = this.props.location.state ? this.props.location.state.product : null;



        if (product === null)
            return null;

        let isWishlisted = false;
        let wishListId = null;
        wishlistItems.length > 0 && wishlistItems.map((item, index) => {
            if (item.id === product.id) {
                isWishlisted = true;
                wishListId = index;
                return;
            }
        })

        const { name, code, badge_text, badge_class, base_url, length, width, description, discount, total_quantity, size, fabric, image_url1, rent_per_product, image_url2, image_url3, image_url4, image_url5, image_url6 } = product;

        const totalRating = product.ratings && product.ratings.length > 0 ? product.ratings.length : 0;
        // console.log(product, totalRating, "product");
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const thumbnailSettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
        };

        // console.log(this.props, "details")
        return (
            <React.Fragment>
                <main className="main">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                    <i className="fi-rs-home mr-5"></i>Home
                                </Link>
                                <span></span>
                                <Link to={{
                                    pathname: ProjectRoutes.shop.url + "/all-products",
                                    search: HelperFunctions.getQueryStringFromURL(),
                                    key: "All Products",
                                    state: { category_id: "", is_scroll_top: false }
                                }} className="reset-anchor">
                                    Shop
                                </Link>
                                <span></span> {name}
                            </div>
                        </div>
                    </div>
                    <div className="container mb-30">
                        <div className="row">
                            <div className="col-xl-10 col-lg-12 m-auto">
                                <div className="product-detail accordion-detail">
                                    <div className="row mb-50 mt-30">
                                        <ImageSlider product={product} />
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="detail-info pr-30 pl-30">
                                                {badge_text && <span className="stock-status out-stock"> {badge_text} </span>}
                                                <h2 className="title-detail">{code + '-' + name}</h2>
                                                {
                                                    product.ratings && product.ratings.length > 0 && <div className="product-detail-rating">
                                                        <div className="product-rate-cover text-end">
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating" style={{ width: HelperFunctions.calculateRating(product.avg_rating) }}></div>
                                                            </div>
                                                            <span className="font-small ml-5 text-muted"> ({product.ratings.length} reviews)</span>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="clearfix product-price-cover">
                                                    <div className="product-price primary-color float-left">
                                                        <span className="current-price text-brand">{'    ₹' + parseInt(rent_per_product - rent_per_product * discount / 100)}</span>
                                                        <span>
                                                            <span className="save-price font-md color3 ml-15">{discount}% Off</span>
                                                            <span className="old-price font-md ml-15">{'₹' + rent_per_product}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="short-desc mb-30">
                                                    {/* <p className="font-lg">{description}</p> */}
                                                    <p className="font-lg" dangerouslySetInnerHTML={{ __html: description }}></p>
                                                </div>
                                                {/* <div className="attr-detail attr-size mb-30">
                                                    <strong className="mr-10">Size / Weight: </strong>
                                                    <ul className="list-filter size-filter font-small">
                                                        <li><a >50g</a></li>
                                                        <li className="active"><a >60g</a></li>
                                                        <li><a >80g</a></li>
                                                        <li><a >100g</a></li>
                                                        <li><a >150g</a></li>
                                                    </ul>
                                                </div> */}
                                                <div className="detail-extralink mb-50">

                                                    <DetailsHelper {...this.props} onRef={this.onDHelperRef} />

                                                    <div className="product-extra-link2">
                                                        <button type="submit" className={`button add-loader button-add-to-cart ${total_quantity <= 0 ? 'disabled' : ''}`} onClick={this.goToCart}>
                                                            <i className="fi-rs-shopping-cart"></i>
                                                            {isRequesting && <ContentLoader loaderType="btn-loader" />} Add to cart
                                                        </button>
                                                        <a onClick={this.goToWishlist.bind(this, wishListId)} aria-label="Add To Wishlist" className={`action-btn hover-up ${isWishlisted ? "active" : ""}`} ><i className="fi-rs-heart"></i></a>
                                                        {/* <a aria-label="Compare" className="action-btn hover-up" href="shop-compare.html"><i className="fi-rs-shuffle"></i></a> */}
                                                    </div>
                                                </div>
                                                {/* <div className="font-xs">
                                                    <ul className="mr-50 float-start">
                                                        <li className="mb-5">Type: <span className="text-brand">Organic</span></li>
                                                        <li className="mb-5">MFG:<span className="text-brand"> Jun 4.2021</span></li>
                                                        <li>LIFE: <span className="text-brand">70 days</span></li>
                                                    </ul>
                                                    <ul className="float-start">
                                                        <li className="mb-5">SKU: <a >FWM15VKT</a></li>
                                                        <li className="mb-5">Tags: <a rel="tag">Snack</a>, <a rel="tag">Organic</a>, <a rel="tag">Brown</a></li>
                                                        <li>Stock:<span className="in-stock text-brand ml-5">{product.total_quantity} Items In Stock</span></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            {/* <!-- Detail Info --> */}
                                        </div>
                                    </div>
                                    <div className="product-info">
                                        <div className="tab-style3">
                                            <ul className="nav nav-tabs text-uppercase">
                                                <li className="nav-item" onClick={this.handleTabs.bind(this, "desc")}>
                                                    <a className={`nav-link ${this.state.activeTab === "desc" ? 'active' : ""}`} id="Description-tab" data-bs-toggle="tab">Description</a>
                                                </li>
                                                {/* <li className="nav-item" onClick={this.handleTabs.bind(this, "additional")}>
                                                    <a className={`nav-link ${this.state.activeTab === "additional" ? 'active' : ""}`} id="Additional-info-tab" data-bs-toggle="tab">Additional info</a>
                                                </li> */}
                                                <li className="nav-item" onClick={this.handleTabs.bind(this, "vendor")}>
                                                    <a className={`nav-link ${this.state.activeTab === "vendor" ? 'active' : ""}`} id="Vendor-info-tab" data-bs-toggle="tab">Vendor</a>
                                                </li>
                                                <li className="nav-item" onClick={this.handleTabs.bind(this, "review")}>
                                                    <a className={`nav-link ${this.state.activeTab === "review" ? 'active' : ""}`} id="Reviews-tab" data-bs-toggle="tab">Reviews ({product.ratings && product.ratings.length > 0 ? product.ratings.length : 0})</a>
                                                </li>
                                            </ul>

                                            <div className="tab-content shop_info_tab entry-main-content">

                                                <div className={`tab-pane fade ${this.state.activeTab === "desc" ? "show active" : ""}`} id="Description">
                                                    <p dangerouslySetInnerHTML={{ __html: description }} />
                                                </div>

                                                <div className={`tab-pane fade ${this.state.activeTab === "vendor" ? "show active" : ""}`} id="Vendor-info">
                                                    <VendorDetails />
                                                </div>

                                                <div className={`tab-pane fade ${this.state.activeTab === "review" ? "show active" : ""}`} id="Reviews">
                                                    {/* <!--Comments--> */}
                                                    <div className="comments-area">
                                                        {
                                                            product.ratings && product.ratings.length > 0 && <div className="row">
                                                                <div className="col-lg-8">
                                                                    <h4 className="mb-30">Customer questions & answers</h4>
                                                                    <div className={`comment-list ${this.state.showAllReviews ? "show-list" : ""}`}>

                                                                        {
                                                                            product.ratings.map((rating, index) => {
                                                                                return (
                                                                                    <div key={index} className="single-comment justify-content-between d-flex mb-30">
                                                                                        <div className="user justify-content-between d-flex">
                                                                                            <div className="thumb text-center">
                                                                                                <img src={images.avatarImg} alt="" />
                                                                                                <a className="font-heading text-brand">{rating.user_name}</a>
                                                                                            </div>
                                                                                            <div className="desc">
                                                                                                <div className="d-flex justify-content-between mb-10">
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <span className="font-xs text-muted">December 4, 2021 at 3:12 pm </span>
                                                                                                    </div>
                                                                                                    <div className="product-rate d-inline-block">
                                                                                                        <div className="product-rating" style={{ width: HelperFunctions.calculateRating(rating.rating) }}></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className="mb-10">{rating.comment}
                                                                                                    {/* <a className="reply">Reply</a> */}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>

                                                                    <div className="view-more-block desk-show" onClick={this.showReviews}>
                                                                        <a className="btn more-btn">
                                                                            <span className="more">View More</span>
                                                                            <span className="less">Show Less</span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <h4 className="mb-30">Customer reviews</h4>
                                                                    <div className="d-flex mb-30">
                                                                        <div className="product-rate d-inline-block mr-15">
                                                                            <div className="product-rating" style={{ width: HelperFunctions.calculateRating(product.avg_rating) }}></div>
                                                                        </div>
                                                                        <h6>{product.avg_rating} out of 5</h6>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <span>5 star</span>
                                                                        <div className="progress-bar" role="progressbar" style={{ width: HelperFunctions.calculateRatingAvg(product.five_rating, totalRating) }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{HelperFunctions.calculateRatingAvg(product.five_rating, totalRating)}</div>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <span>4 star</span>
                                                                        <div className="progress-bar" role="progressbar" style={{ width: HelperFunctions.calculateRatingAvg(product.four_rating, totalRating) }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{HelperFunctions.calculateRatingAvg(product.four_rating, totalRating)}</div>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <span>3 star</span>
                                                                        <div className="progress-bar" role="progressbar" style={{ width: HelperFunctions.calculateRatingAvg(product.three_rating, totalRating) }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{HelperFunctions.calculateRatingAvg(product.three_rating, totalRating)}</div>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <span>2 star</span>
                                                                        <div className="progress-bar" role="progressbar" style={{ width: HelperFunctions.calculateRatingAvg(product.two_rating, totalRating) }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{HelperFunctions.calculateRatingAvg(product.two_rating, totalRating)}</div>
                                                                    </div>
                                                                    <div className="progress mb-30">
                                                                        <span>1 star</span>
                                                                        <div className="progress-bar" role="progressbar" style={{ width: HelperFunctions.calculateRatingAvg(product.one_rating, totalRating) }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{HelperFunctions.calculateRatingAvg(product.one_rating, totalRating)}</div>
                                                                    </div>
                                                                    {/* <a className="font-xs text-muted">How are ratings calculated?</a> */}
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                    {/* <!--comment form--> */}
                                                    <SubmitReview
                                                        isRequestingForReview={this.props.isRequestingForReview}
                                                        submitReviewForm={this.props.submitReviewForm}
                                                        location={this.props.location}
                                                        userData={userData} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Related Product */}
                                    <RelatedProducts
                                        wishlistItems={wishlistItems}
                                        relatedItemClick={this.relatedItemClick}
                                        addToCart={this.props.addToCart}
                                        addToWishlist={this.props.addToWishlist}
                                        addToCompare={this.props.addToCompare}
                                        cartProdcutId={this.props.cartProdcutId}
                                        showProductModal={this.props.showProductModal}
                                        isRequestingForWhom={this.props.isRequesting}
                                        products={products} />
                                </div>
                            </div>
                        </div>
                    </div>

                </main>

            </React.Fragment>
        );
    }

}