import React, { Component } from 'react';
import DateHelper from '../../../../../utils/DateHelper';
import HelperFunctions from '../../../../../utils/HelperFunctions';
import InvoiceButton from "../../../../../commons/components/invoice/invoiceButton";
import $ from 'jquery';

class MyOrders extends Component {
    componentDidMount() {
        $('.view-order-btn').on('click', function () {
            $('.hr-total-order-info').slideUp();
            var cIndex = $(this).attr('data-index');
            if ($(this).hasClass("opened")) {
                // $(".order_detail_" + cIndex).slideDown();
                $(this).removeClass("opened")
            } else {
                $(this).addClass("opened");
                $(".order_detail_" + cIndex).slideDown();
            }
        })
    }

    render() {
        const { orders } = this.props;
        return (
            <div className="card animate__animated animate__fadeIn">
                <div className="card-header">
                    <h3 className="mb-0">Your Orders</h3>
                </div>

                <div className="card-body">
                    {
                        <div className="hr-order-list-wrap">
                            <ul className="hr-order-list-ul">
                                {
                                    orders && orders.map((order, index) => {
                                        return (
                                            <li key={index}>
                                                <div className="hr-order-card">
                                                    <div className="hr-order-bar">
                                                        <div className="hr-order-left">
                                                            <div className="hr-order-items">
                                                                {
                                                                    order.order_items.length > 0 && order.order_items.map((itemImg, imgIndex) => {
                                                                        return imgIndex < 3 && (
                                                                            <figure key={imgIndex}>
                                                                                <img className="default-img" src={itemImg.base_url + itemImg.image_url1} alt="" />
                                                                            </figure>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                            <div className="hr-order-carts">
                                                                <p className="order-id">{'Order #' + order.order_id}</p>
                                                                <p className="order-date">{DateHelper.getDisplayDateFromStamp(order.order_date)}</p>
                                                                <p className="order-method"><strong>Payment Method: </strong>{order.payment_method}</p>
                                                                <div className="hr-order-t-price">₹{order.total}<span className="hr-price-of">for {order.order_items.length} items</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="hr-order-mid">
                                                            <div className="hr-order-status"><div className="product-badges product-badges-position product-badges-mrg"><span className={HelperFunctions.orderBadge(order.status_class)}>{order.status}</span></div></div>
                                                        </div>
                                                        <div className="hr-order-right">
                                                            {
                                                                order.status.includes('Pending') && <div className="hr-order-multi"><a className="btn btn-xs">Pay again</a></div>
                                                            }
                                                            <div className="hr-view-order-btn">
                                                                <a data-index={index} className={`btn btn-xs view-order-btn view_order_${index}`}><i className="fi-rs-eye"></i> View</a>
                                                                <a className={`btn btn-xs invoice-btn `}>Invoice</a>
                                                            </div>
                                                            {/* <InvoiceButton order={order}/> */}
                                                        </div>
                                                    </div>

                                                    <div className={`hr-total-order-info order_detail_${index}`} style={{ display: "none" }}>
                                                        <div className="hr-total-info-inner">
                                                            <div className="hr-order-dis"><p><strong>Order Details</strong> {' '}(Order <span className="mark">#{order.order_id}</span> was placed
                                                                on {DateHelper.getDisplayDateFromStamp(order.order_date)} and is currently <span
                                                                    className="mark">{order.status}</span>)</p></div>

                                                            <div className="hr-selected-products">
                                                                <div className="hr-t-head">
                                                                    <div className="hr-td"><span>Product</span></div>
                                                                    <div className="hr-td"><span>Total</span></div>
                                                                </div>
                                                                <ul className="hr-t-body">
                                                                    {
                                                                        order.order_items.length > 0 && order.order_items.map((item, itemIndex) => {
                                                                            // console.log(item)
                                                                            return (
                                                                                <li key={itemIndex}>
                                                                                    <div className="hr-td">
                                                                                        <span className="prod-name">
                                                                                            {item.name}
                                                                                            <span className="pr-count">{' × ' + item.quantity}</span>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="hr-td">
                                                                                        <span className="prod-prize">
                                                                                            <span className="main-price">₹{item.rent_per_product}</span>
                                                                                            <span className="at-price">{HelperFunctions.calculateFinalPrice(item.rent_per_product, item.discount)}</span>
                                                                                            <span className="price-dis">{' (' + item.discount + '% off)'}</span>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>

                                                                <ul className="hr-t-foot">
                                                                    <li>
                                                                        <div className="hr-td"><span className="sub-price-label">Sub Total</span></div>
                                                                        <div className="hr-td">
                                                                            <span className="prod-prize">
                                                                                <span className="at-price">₹{order.total}</span>
                                                                                <span className="price-dis">{' (Saved ₹' + (order.total_original_cost - order.total + ')')}</span>
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="hr-td"><span className="sub-price-label">Shipping</span></div>
                                                                        <div className="hr-td">
                                                                            <span className="prod-prize">
                                                                                <span className="at-price">{(order.shipping_charges === 0) ? '₹0 (Free shipping)' : '₹' + order.shipping_charges}</span>
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="hr-td"><span className="sub-price-label">Payment Method</span></div>
                                                                        <div className="hr-td"><span className="prod-prize"><span className="at-price">{order.payment_method}</span></span></div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="hr-td"><span className="sub-price-label">Total</span></div>
                                                                        <div className="hr-td"><span className="prod-prize"><span className="at-price">₹{order.total + order.shipping_charges} (include 5 % IGST)</span></span></div>
                                                                    </li>
                                                                </ul>


                                                            </div>
                                                            <div className="hr-address">
                                                                <h3 className="hr-address-title">Adderss Detail</h3>
                                                                <span className="ordered-name">{order.name}</span>
                                                                <p className="hr-address-pre">{order.address.address}<br />
                                                                    {order.address.city_name + ' - ' + order.address.pin_code + ', ' + order.address.state_name + ', ' + order.address.country_name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    }

                </div>
            </div >
        );
    }
}

export default MyOrders;