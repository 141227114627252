import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import ContentLoader from '../../../../commons/components/contentLoader';
import ImageMagnify from '../../../../commons/components/imageMagnify';
import ImageWithLoad from '../../../../commons/components/imagePreload/imageWithLoad';
import ImageSlider from '../../../../commons/components/imageSlider';
import HelperFunctions from '../../../../utils/HelperFunctions';
import DetailsHelper from '../../screens/shop/components/detailsHelper';

export default class QuickViewModel extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            quantity: this.props.modelProduct.min_order_quantity,
            toggleGalleryMode: false
            // quantity: 8,
        }
        this.dHelperRef = null;
    }

    goToCart = () => {
        // add the product To the cart
        this.props.addToCart(this.props.modelProduct, this.dHelperRef.state.quantity);
    };

    close = () => {
        if (this.state.toggleGalleryMode) {
            this.openGallaryMode();
        } else {
            this.props.showProductModal(null);
        }
    }

    onDHelperRef = (ref) => {
        this.dHelperRef = ref;
    };

    openGallaryMode = () => {
        // gallery-modal
        this.setState((prevState, props) => ({
            toggleGalleryMode: !prevState.toggleGalleryMode
        }));

    }

    render() {

        const { name, base_url, isRequesting, description, discount, fabric, image_url1, image_url2, image_url3, image_url4, image_url5, image_url6, rent_per_product, total_quantity, badge_text, badge_class } = this.props.modelProduct;
        // console.log(this.props.isRequesting, "isRequesting")
        return (
            <div style={{ display: "block" }} className={`modal animate__bounceIn animate__animated quick-view-modal custom-modal show ${this.state.toggleGalleryMode ? "gallery-modal" : ""}`} aria-labelledby="quickViewModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button onClick={this.close} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="modal-body">
                            <div className="row">
                               
                                    <ImageSlider product={this.props.modelProduct} />
                                

                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="detail-info pr-30 pl-30">
                                        {badge_text && <span className={`stock-status in-stock`}>{badge_text}</span>}
                                        <h3 className="title-detail"><a href="shop-product-right.html" className="text-heading">{name}</a></h3>
                                        {
                                            this.props.modelProduct && this.props.modelProduct.avg_rating && <div className="product-detail-rating">
                                                <div className="product-rate-cover text-end">
                                                    <div className="product-rate d-inline-block">
                                                        <div className="product-rating" style={{ width: HelperFunctions.calculateRating(this.props.modelProduct.avg_rating) }}></div>
                                                    </div>
                                                    <span className="font-small ml-5 text-muted"> ({this.props.modelProduct.ratings.length} reviews)</span>
                                                </div>
                                            </div>
                                        }

                                        <div className="clearfix product-price-cover">
                                            <div className="product-price primary-color float-left">
                                                <span className="current-price text-brand">{HelperFunctions.calculateFinalPrice(rent_per_product, discount)}</span>
                                                <span>
                                                    <span className="save-price font-md color3 ml-15">{discount}% Off</span>
                                                    <span className="old-price font-md ml-15">{'₹' + rent_per_product}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="detail-extralink mb-30">

                                            <DetailsHelper {...this.props} onRef={this.onDHelperRef} />

                                            <div className="product-extra-link2">
                                                <button type="submit" className={`${this.props.isRequesting ? "disabled" : ""} button add-loader button-add-to-cart ${total_quantity <= 0 ? 'disabled' : ''}`} onClick={this.goToCart}>
                                                    <i className="fi-rs-shopping-cart"></i>
                                                    Add to cart
                                                    {this.props.isRequesting && <ContentLoader loaderType="btn-loader" />}
                                                </button>
                                            </div>
                                        </div>
                                        {/* <div className="font-xs">
                                            <ul>
                                                <li className="mb-5">Vendor: <span className="text-brand">Nest</span></li>
                                                <li className="mb-5">MFG:<span className="text-brand"> Jun 4.2021</span></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}