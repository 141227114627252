/*
* This is Navigation Container
* Presentational component for this container is RootNavigator (/component/Navigator/RootNavigator)
* Container is connected with store with the help of connect() function
* in connect() function mapStateToProps and container is passed
* */

import React, { Component } from 'react';
import { connect } from "react-redux";
import ContentLoader from "../../../../commons/components/contentLoader";
import common from "../../common";
import header from '../../screens/header';
import cart from "../../screens/cart";
import user from "../../screens/user";
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import RootNavigator from "./components";
import * as constants from './constants';
import reducer from "./reducer";
import home from '../../screens/home';
// import shop from "../../screens/shop";

class RootNavigatorSmart extends Component {

    componentDidMount() {
        window.addEventListener("resize", this.resize);

        // get the common APIs only for once from common actions
        this.props.callOnPageLoad();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize = () => {
        let self = this;
        if (self.timeout) {
            clearTimeout(self.timeout);
        }
        this.timeout = setTimeout(() => {
            self.props.setCurrentOrientation();
            clearTimeout(self.timeout);
        }, 100);

    };

    render() {

        const { userData, categories, homePageData, isRequestingForWhom } = this.props;
        // console.log(isRequestingForWhom, "isRequestingForWhom");
        // until user check API is finished show Content loader lese start with pages
        if (userData === undefined || (categories && categories.length === 0) || homePageData.length === 0) return <ContentLoader />;
       

        return <RootNavigator {...this.props} />
    }
}

const mapStateToProps = state => ({
    categories: state[header.constants.NAME].categories,
    // modelProduct: state[common.constants.NAME].modelProduct,
    userData: state[user.constants.NAME].userData,
    homePageData: state[home.constants.NAME].homePageData,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        setCurrentOrientation: (currentOrientation) => dispatch(common.actions.setCurrentOrientation(currentOrientation)),
        callOnPageLoad: () => dispatch(common.actions.callOnPageLoad()),
        // addToCart: (modelProduct, quantity,fromWhere) => dispatch(cart.actions.addToCart(modelProduct, quantity,fromWhere)),
    }
};

export const RootNavigatorContainer = connect(mapStateToProps, mapDispatchToProps)(RootNavigatorSmart);
export default { actionTypes, actions, reducer, constants };