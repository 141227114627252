import React, { Component } from 'react';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import Home from "./components";
import * as constants from './constants';
import reducer from './reducer';
import { connect } from "react-redux";
import {HeaderContainer} from "../header";
import {FooterContainer} from "../footer";
import HelperFunctions from '../../../../utils/HelperFunctions';

class HomeSmart extends Component {
    componentDidMount() {
        HelperFunctions.removeMobileNavOverlay();
    }
    
    render() {
        // console.log(this.props.featuredCategories, "featuredCategories");
        return (
            <React.Fragment>
                <HeaderContainer path={this.props.match.path} historyProps={this.props.history} />
                <Home {...this.props}/>
                <FooterContainer showFooterContent = {true} path={this.props.match.path}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    banners: state[constants.NAME].banners,
    homeMainBanners: state[constants.NAME].homeMainBanners,
    topDiscountBanners: state[constants.NAME].topDiscountBanners,
    simpleTwoBanner: state[constants.NAME].simpleTwoBanner,
    staticBanners: state[constants.NAME].staticBanners,
});

const mapDispatchToProps = dispatch => {
    return {

    }
};

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(HomeSmart);
export default { actionTypes, actions, reducer, constants };