import { Slide, toast } from "react-toastify";
import APIEndpoints from "../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../config/routes/projectRoutes";
import Constants from "../../../../utils/Constants";
import HelperFunctions from "../../../../utils/HelperFunctions";
import cart from "../cart";
import wishllist from "../wishllist";
import * as ActionTypes from "./actionTypes";
import * as constants from "./constants";
import user from "./index";

export function callOnPageLoad(routerHistory) {
    return (dispatch, getState) => {
        const { userData } = getState()[constants.NAME];

        dispatch(checkUser());

        //when user is logged in user will go to profile page
        if (userData && userData.user_id) {
            const pathname = routerHistory.location.pathname;
            if (pathname === ProjectRoutes.user.verifyEmail.url) {

            }
            else {
                routerHistory.replace({
                    pathname: ProjectRoutes.user.account.url,
                    search: HelperFunctions.getQueryStringFromURL(),
                })
            }
        } else {

        }
    }
}

export function logout() {
    return (dispatch, getState) => {

        localStorage.removeItem("user_id");

        dispatch({
            type: ActionTypes.USER_LOGIN,
            payload: undefined
        });
        setTimeout(() => {
            dispatch({
                type: ActionTypes.USER_LOGIN,
                payload: null
            });

        }, 1000);
        window.location.reload();
    }
}

export function login(email_mobile = '', password = '', isRemember = false, routerHistory, isLoginWithOTP, matchLoginOTP) {
    return (dispatch, getState) => {

        // loginWithOTPStatus
        const { loginWithOTPStatus } = getState()[constants.NAME];
        dispatch({
            type: ActionTypes.USER_LOGIN,
            payload: null
        });

        // console.log(isLoginWithOTP, loginWithOTPStatus, "status");

        if (isLoginWithOTP && !loginWithOTPStatus) {
            dispatch({
                type: ActionTypes.USER_LOGIN,
                payload: { isRequestingForWhom: 'user/login' },
                promise: APIEndpoints.loginWithOTP({ email_mobile }),
                callback: (payload) => {
                    var statusCode = "";
                    if (payload.statusCode === 200) {
                        dispatch({ type: ActionTypes.LOGIN_WITH_OTP_STATUS, payload: true });
                        statusCode = 'success';
                    } else {
                        statusCode = 'error';
                    }

                    this.toastId = toast(payload.message, {
                        transition: Slide,
                        closeButton: true,
                        autoClose: 5000,
                        position: toast.POSITION.TOP_CENTER,
                        type: statusCode,
                        hideProgressBar: true,
                    })
                }
            })
        } else {
            dispatch({
                type: ActionTypes.USER_LOGIN,
                payload: { isRequestingForWhom: 'user/login' },
                promise: isLoginWithOTP && loginWithOTPStatus ? APIEndpoints.matchLoginWithOTP({ email_mobile, 'otp': matchLoginOTP }) : APIEndpoints.login({ email_mobile, password }),
                callback: (payload) => {
                    // console.log(payload, isLoginWithOTP);
                    if (payload) {
                        if (payload.message) {
                            this.toastId = toast(payload.message, {
                                transition: Slide,
                                closeButton: true,
                                autoClose: 5000,
                                position: toast.POSITION.TOP_CENTER,
                                type: 'error',
                                hideProgressBar: true,
                            })
                        }
                        else if (payload.is_mobile_verified === false) {
                            routerHistory.push({
                                pathname: ProjectRoutes.user.verify.url,
                                search: HelperFunctions.getQueryStringFromURL(),
                                state: { email_mobile, password }
                            })
                        }
                        else if (payload.status === 'Active') {
                            // if (isRemember) {
                            // HelperFunctions.createCookie('user_id', payload.user_id);
                            localStorage.setItem("user_id", payload.user_id);

                            // calling common state for complete game after login
                            dispatch(user.actions.getState('5da32957a7feed0b6fce495c'));
                            // }
                            // add cart items
                            dispatch(cart.actions.handleLocalStorageCartWhenUserLogsIn(payload.cart_items));
                            dispatch(wishllist.actions.handleLocalStorageWishlistWhenUserLogsIn(payload.wishlist_items));

                            switch (window.redirectToWhere) {
                                case ProjectRoutes.cart.billingInfo.url:
                                    routerHistory.push({
                                        pathname: ProjectRoutes.cart.billingInfo.url,
                                        search: HelperFunctions.getQueryStringFromURL(),
                                    });
                                    break;
                                case ProjectRoutes.cart.completed.url:
                                    routerHistory.push({
                                        pathname: ProjectRoutes.cart.completed.url,
                                        search: HelperFunctions.getQueryStringFromURL(),
                                    });
                                    break;
                                default:
                                    routerHistory.push({
                                        pathname: ProjectRoutes.home.url,
                                        search: HelperFunctions.getQueryStringFromURL(),
                                    });
                            }
                            window.redirectToWhere = "";
                        }
                    }
                }
            });
        }



    }
}

export function checkUser() {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_CHECK,
            payload: { isRequestingForWhom: 'user/login' },
            promise: APIEndpoints.checkLogin(),
            callback: (response) => {
                if (response.content) {
                    if (response.content.is_mobile_verified === false) {
                        response.content = { is_mobile_verified: false };
                    }
                    const payload = response.content;
                    if (payload) {
                        if (payload.status === 'Active') {
                            // HelperFunctions.createCookie('user_id', payload.user_id);
                            localStorage.setItem("user_id", payload.user_id);

                            // setCart Items
                            dispatch(cart.actions.handleLocalStorageCartWhenUserLogsIn(payload.cart_items));

                            // set wishlist Items
                            dispatch(wishllist.actions.handleLocalStorageWishlistWhenUserLogsIn(payload.wishlist_items));

                            //set user data to store
                            dispatch({ type: ActionTypes.USER_LOGIN, payload });
                        }
                    }
                }
                else {
                    dispatch({ type: ActionTypes.USER_LOGIN, payload: null });
                }
            }
        }
        )
    }

};

export function register(name = '', email = '', mobile = '', password = '', routerHistory) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_REGISTER,
            payload: null
        });

        dispatch({
            type: ActionTypes.USER_REGISTER,
            payload: { isRequestingForWhom: 'user/register' },
            promise: APIEndpoints.addUser({ name, email, mobile, password }, ''),
            callback: (payload) => {
                if (payload) {
                    if (payload.statusCode === 200) {
                        // routerHistory.push({
                        //     pathname: ProjectRoutes.user.login.url,
                        //     search: HelperFunctions.getQueryStringFromURL(),
                        // })
                        // after registration send user to auto login
                        dispatch(login(email, password, true, routerHistory))
                    } else if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }

                }
            }
        }
        )
    }
}

export function forgotPassword(email_mobile = '', routerHistory) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_FORGOT_PASSWORD,
            payload: { isRequestingForWhom: 'user/forgot-password' },
            promise: APIEndpoints.forgotPassword({ email_mobile }),
            callback: (payload) => {
                if (payload) {
                    // console.log(payload);
                    if (payload.statusCode === 200) {
                        // console.log(routerHistory);
                        routerHistory.push({
                            pathname: ProjectRoutes.user.otp.url,
                            search: HelperFunctions.getQueryStringFromURL(),
                            state: { email_mobile }
                        })
                    }
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        }
        )
    }
}

export function verifyEmail(email_mobile = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_VERIFY_EMAIL,
            payload: { isRequestingForWhom: 'user/verify-email' },
            promise: APIEndpoints.verifyEmail({ email_mobile }),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        }
        )
    }
}

export function requestOTPAgain(email_mobile = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_REQUEST_OTP,
            payload: { isRequestingForWhom: 'user/request-otp' },
            promise: APIEndpoints.forgotPassword({ email_mobile }),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        }
        )
    }
}

export function updateUser(name = '', email) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE,
            payload: { isRequestingForWhom: 'user/update' },
            promise: APIEndpoints.updateUser({ name, email }),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        if (payload.message) {

                            if (payload.content && payload.content.errors) {
                                const copyUserData = HelperFunctions.copyObject(getState()[constants.NAME].userData);
                                copyUserData.errors = payload.content.errors;
                                dispatch({ type: ActionTypes.USER_LOGIN, payload: copyUserData });
                            }
                            else {
                                this.toastId = toast(payload.message, {
                                    transition: Slide,
                                    closeButton: true,
                                    autoClose: 2000,
                                    position: toast.POSITION.TOP_CENTER,
                                    type: payload.statusCode === 200 ? 'success' : 'error',
                                    hideProgressBar: true,
                                });
                            }
                        }
                        if (payload.statusCode === 200) {
                            dispatch(checkUser());
                        }
                    }
                }
            }
        }
        )
    }
}

export function matchOTP(email_mobile = '', otp = '', routerHistory) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_MATCH_OTP,
            payload: { isRequestingForWhom: 'user/match-otp' },
            promise: APIEndpoints.matchOTP({ email_mobile, otp }),
            callback: (payload) => {
                if (payload) {
                    if (payload.statusCode === 200) {
                        // console.log(routerHistory);
                        routerHistory.push({
                            pathname: ProjectRoutes.user.newPassword.url,
                            search: HelperFunctions.getQueryStringFromURL(),
                            state: { email_mobile }
                        })
                    }
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 2000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        }
        )
    }
}

export function matchEmailVerificationOTP(email_mobile = '', otp = '', routerHostory) {
    return (dispatch, getState) => {
        const is_account_verification = true;
        dispatch({
            type: ActionTypes.USER_MATCH_OTP,
            payload: { isRequestingForWhom: 'user/match-otp' },
            promise: APIEndpoints.matchOTP({ email_mobile, otp, is_account_verification }),
            callback: (payload) => {
                if (payload.statusCode === 200)
                    dispatch(checkUser());
                routerHostory.replace({
                    pathname: ProjectRoutes.user.verifyEmail.url,
                });
            }
        }
        )
    }
}

export function matchVerificationOTP(email_mobile = '', password = '', otp = '', routerHistory) {
    return (dispatch, getState) => {
        const is_account_verification = true;
        dispatch({
            type: ActionTypes.USER_MATCH_OTP,
            payload: { isRequestingForWhom: 'user/match-otp' },
            promise: APIEndpoints.matchOTP({ email_mobile, otp, is_account_verification }),
            callback: (payload) => {
                if (payload) {
                    if (payload.statusCode === 200) {
                        dispatch(login(email_mobile, password, true, routerHistory));
                    } else if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 2000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        }
        )
    }
}

export function newPassword(email_mobile = '', password = '', routerHistory) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_NEW_PASSWORD,
            payload: { isRequestingForWhom: 'user/new-password' },
            promise: APIEndpoints.newPassword({ email_mobile, password }),
            callback: (payload) => {
                if (payload) {
                    if (payload.statusCode === 200) {
                        // console.log(payload);
                        routerHistory.push({
                            pathname: ProjectRoutes.user.login.url,
                            search: HelperFunctions.getQueryStringFromURL(),
                        })
                    }
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 2000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        }
        )
    }
}

export function changePassword(email_mobile = '', old_password = '', password = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_NEW_PASSWORD,
            payload: { isRequestingForWhom: 'user/new-password' },
            promise: APIEndpoints.newPassword({ email_mobile, old_password, password }),
            callback: (payload) => {
                if (payload) {
                    if (payload.statusCode === 200) {
                        // console.log(payload)
                    }
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 2000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        }
        )
    }
}

export function updateAddress(address = {}, routerHistory, callback = null) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_ADDRESS,
            payload: { isRequestingForWhom: 'user/address' },
            promise: APIEndpoints.updateAddress(address),
            callback: (payload) => {
                if (payload) {
                    if (payload.statusCode === 200) {
                        dispatch(checkUser());
                        if (callback) {
                            callback();
                        }
                    }
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 2000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        }
        )
    }
}

export function getCountry() {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_COUNTRY,
            payload: { isRequestingForWhom: "country/list" },
            promise: APIEndpoints.getCountry(),
            callback: (payload) => {

            }
        })
    }
}

export function getState(country_id, isEmptyFirst = true) {
    return (dispatch, getState) => {

        if (isEmptyFirst)
            dispatch({ type: ActionTypes.GET_STATE, payload: country_id === '' ? [] : null });

        if (country_id === '')
            return;

        dispatch({
            type: ActionTypes.GET_STATE,
            payload: { isRequestingForWhom: isEmptyFirst && "state/list" },
            promise: APIEndpoints.getState(country_id),
            callback: (payload) => {
            }
        })
    }
}

export function getCity(country_id, state_id, isEmptyFirst = true) {
    return (dispatch) => {

        if (isEmptyFirst)
            dispatch({ type: ActionTypes.GET_CITY, payload: [] });

        dispatch({
            type: ActionTypes.GET_CITY,
            payload: { isRequestingForWhom: "city/list" },
            promise: APIEndpoints.getCity(country_id, state_id),
            callback: (payload) => {

            }
        })
    }
}

export function getOrders() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.GET_ORDER,
            payload: { isRequestingForWhom: Constants.userOrderMatch },
            promise: APIEndpoints.order(),
            callback: (payload) => {
                // console.log(payload);
            }
        })
    }
}