import PropTypes from "prop-types";
import React, { Component } from 'react';

class ContentLoader extends Component {
    render() {
        return (
            // <div className={`loading-wrapper ${this.props.loaderType}`}>
            //     <div className="page-overlay">
            //         <div className="spinner-grow text-primary" role="status"></div>
            //     </div>
            // </div>

            // <div className={`loading-wrapper ${this.props.loaderType}`} style={{display: "block"}}>
            //     <div className="page-overlay">
            //         <div className="loader-ring"> </div>
            //     </div>
            // </div>

            <div className={`loading-wrapper ${this.props.loaderType}`} style={{display: "block"}}>
                <div className="page-overlay">
                    <div className="hr-loader">
                        <div> </div>
                        <div> </div>
                        <div> </div>
                        <div> </div>
                        <div> </div>
                        <div> </div>
                        <div> </div>
                    </div>
                </div>
            </div>

            // <div id="preloader-active">
            //     <div className="preloader d-flex align-items-center justify-content-center">
            //         <div className="preloader-inner position-relative">
            //             <div className="text-center">
            //                 <img src="assets/imgs/theme/loading.gif" alt="" />
            //             </div>
            //         </div>
            //     </div>
            // </div>
        );
    }
}

ContentLoader.propTypes = {
    loaderType: PropTypes.string,
};

ContentLoader.defaultProps = {
    loaderType: "",
};

export default ContentLoader;