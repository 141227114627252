import React from "react";
import { Link } from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import Constants from "../../../../../utils/Constants";
import Subscribe from "./subscribe";
import Features from "./features";
import images from "../../../../../assets/images";
import ComparePopup from "../../compare/components/comparePopup";

export default class Footer extends React.Component {

    render() {
        const { whyChooseItems, path, showFooterContent, compareItems, removeFromCompare } = this.props;
        // console.log(path, "path");
        return (
            <React.Fragment>
                <div className="hr-overlay mobile-menu-close show"></div>
                <ComparePopup path= {path} removeFromCompare = {removeFromCompare} compareItems = {compareItems}/>

                {
                    showFooterContent && <footer className="main">
                        <Subscribe />

                        <Features whyChooseItems={whyChooseItems} />

                        <section className="section-padding footer-mid">
                            <div className="container pt-15 pb-20">
                                <div className="row">

                                    <div className="col hr-footer-logo-section">
                                        <div className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0">
                                            <div className="logo mb-30">
                                                <a href="index.html" className="mb-15"><img src={images.logo} alt="logo" /></a>
                                                <p className="font-lg text-heading">Awesome grocery store website template</p>
                                            </div>
                                            <ul className="contact-infor">
                                                <li><img src={images.iconLocation} alt="" /><strong>Address: </strong> <span>{Constants.address.name + ' ' + Constants.address.address + ' ' + Constants.address.pin + ' ' + Constants.address.area}</span></li>
                                                <li><img src={images.iconContact} alt="" /><strong>Call Us:</strong><span>(+91) - 7300094000</span></li>
                                                <li><img src={images.iconEmail} alt="" /><strong>Email:</strong><span>sale@hindroot.com</span></li>
                                                {/* <li><img src={images.iconClock} alt="" /><strong>Hours:</strong><span>10:00 - 18:00, Mon - Sat</span></li> */}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="footer-link-widget col hr-footer-cat-section">
                                        <h4 className="widget-title">Categories</h4>
                                        <ul className="footer-list mb-sm-5 mb-md-0">
                                            <li>

                                                <Link to={{
                                                    pathname: ProjectRoutes.user.url,
                                                    search: HelperFunctions.getQueryStringFromURL()
                                                }}>
                                                    My Account
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>

                                    <div className="footer-link-widget col hr-footer-company-section">
                                        <h4 className="widget-title">Company</h4>
                                        <ul className="footer-list mb-sm-5 mb-md-0">
                                            <li>
                                                <Link to={{
                                                    pathname: ProjectRoutes.aboutUs.url,
                                                    search: HelperFunctions.getQueryStringFromURL()
                                                }}>
                                                    About us
                                                </Link>

                                            </li>
                                            <li>
                                                <Link to={{
                                                    pathname: ProjectRoutes.legal.url,
                                                    search: HelperFunctions.getQueryStringFromURL()
                                                }}>
                                                    Legal
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={{
                                                    pathname: ProjectRoutes.privacyPolicy.url,
                                                    search: HelperFunctions.getQueryStringFromURL()
                                                }}>
                                                    Privacy Policy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={{
                                                    pathname: ProjectRoutes.cancellationPolicy.url,
                                                    search: HelperFunctions.getQueryStringFromURL()
                                                }}>
                                                    Cancellation Policy
                                                </Link>
                                            </li>
                                            <li>

                                                <Link to={{
                                                    pathname: ProjectRoutes.shippingPolicy.url,
                                                    search: HelperFunctions.getQueryStringFromURL()
                                                }}>
                                                    Shipping Policy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={{
                                                    pathname: ProjectRoutes.termsCondition.url,
                                                    search: HelperFunctions.getQueryStringFromURL()
                                                }}>
                                                    Terms &amp; Conditions
                                                </Link>
                                            </li>
                                            <li>

                                                <Link to={{
                                                    pathname: ProjectRoutes.termsUse.url,
                                                    search: HelperFunctions.getQueryStringFromURL()
                                                }}>
                                                    Terms of use
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={{
                                                    pathname: ProjectRoutes.contactUs.url,
                                                    search: HelperFunctions.getQueryStringFromURL()
                                                }}>
                                                    Contact us
                                                </Link>
                                            </li>
                                            {/* <li><a >Careers</a></li> */}
                                        </ul>
                                    </div>


                                    {/* <div className="footer-link-widget widget-install-app col">
                               <h4 className="widget-title">Install App</h4>
                               <p className="wow fadeIn animated">From App Store or Google Play</p>
                               <div className="download-app">
                                   <a  className="hover-up mb-sm-2 mb-lg-0"><img className="active" src="assets/imgs/theme/app-store.jpg" alt="" /></a>
                                   <a  className="hover-up mb-sm-2"><img src="assets/imgs/theme/google-play.jpg" alt="" /></a>
                               </div>
                               <p className="mb-20">Secured Payment Gateways</p>
                               <img className="wow fadeIn animated" src="assets/imgs/theme/payment-method.png" alt="" />
                           </div> */}
                                </div>
                            </div>
                        </section>
                        <div className="container pb-30">
                            <div className="row align-items-center">
                                <div className="col-12 mb-30">
                                    <div className="footer-bottom"></div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6">
                                    <p className="font-sm mb-0">&copy; 2021, <strong className="text-brand">{Constants.address.name} Care Ltd. </strong> All rights reserved</p>
                                </div>
                                <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">
                                    {/* <div className="hotline d-lg-inline-flex mr-30">
                               <img src="assets/imgs/theme/icons/phone-call.svg" alt="hotline" />
                               <p>1900 - 6666<span>Working 8:00 - 22:00</span></p>
                           </div> */}
                                    <div className="hotline d-lg-inline-flex">
                                        <img src={images.phoneCallIcon} alt="hotline" />
                                        <p>{Constants.address.phone}<span>24/7 Support Center</span></p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
                                    <div className="mobile-social-icon">
                                        <h6>Follow Us</h6>
                                        <a href={Constants.socialLinks.fb}><img src={images.fbWhiteIcon} alt="" /></a>
                                        <a ><img src={images.twitterWhiteIcon} alt="" /></a>
                                        <a ><img src={images.instaWhiteIcon} alt="" /></a>
                                        <a ><img src={images.pintWhiteIcon} alt="" /></a>
                                        <a ><img src={images.youtubeWhiteIcon} alt="" /></a>
                                    </div>
                                    {/* <p className="font-sm">Up to 15% discount on your first subscribe</p> */}
                                </div>
                            </div>
                        </div>
                    </footer>
                }

                <div className="footer-nav-bar">
                    <div className="container">
                        <nav className="footer-nav">
                            <div className="nav-outer">
                                <ul className="nav-list">
                                    <li className={`${path === ProjectRoutes.home.url ? "active" : ""}`}>
                                        <Link
                                            to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                            <i className="nav-icon fi-rs-home"></i>
                                            <span>Home</span>
                                        </Link>
                                    </li>

                                    <li className={`${path === ProjectRoutes.categories.url ? "active" : ""}`}>
                                        <Link
                                            to={{ pathname: ProjectRoutes.categories.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                            <i className="nav-icon fi-rs-list"></i>
                                            <span>Categories</span>
                                        </Link>
                                    </li>

                                    <li className={`${path === ProjectRoutes.shop.url ? "active" : ""}`}>
                                        <Link
                                            to={{
                                                pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace("All Products"),
                                                search: HelperFunctions.getQueryStringFromURL(),
                                                key: "All Products",
                                                state: { category_id: "", is_scroll_top: false }
                                            }}
                                        >
                                            <i className="nav-icon fi-rs-shop"></i>
                                            <span>Shop</span>
                                        </Link>
                                    </li>

                                    <li className={`${path === ProjectRoutes.user.url ? "active" : ""}`}>
                                        <Link to={{ pathname: ProjectRoutes.user.url, search: HelperFunctions.getQueryStringFromURL() }}
                                        >
                                            <i className="nav-icon fi-rs-user"></i>
                                            <span>Account</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}