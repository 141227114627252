/**
 * used to change screens in root navigator
 * @param screenRouteName => on which screen you want to navigate to
 * @param screenParams  => what screen params you want to pass to that perticular screen
 * @param isResetTheStack => do want to reset the complete screens stack if yes pass true
 * @returns {Function}
 */
export function changeScreen(screenRouteName = null, screenParams = null, isResetTheStack = false) {
    return (dispatch, getState) => {


    }
}