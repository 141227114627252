import React, { Component } from 'react';
import Slider from 'react-slick';
import ImageMagnify from '../imageMagnify';
import ImageWithLoad from '../imagePreload/imageWithLoad';

class ImageSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            slider1: null,
            slider2: null,
        }
    }

    componentDidMount() {
        this.setState({
            slider1: this.slider1,
            slider2: this.slider2
        });
    }

    render() {
        const { product } = this.props;
        const { base_url, image_url1, image_url2, image_url3, image_url4, image_url5, image_url6 } = product;
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const thumbnailSettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
        };
        return (
            <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                <div className="detail-gallery">
                    {/* <span className="zoom-icon"><i className="fi-rs-search"></i></span> */}

                    <Slider asNavFor={this.state.slider2} ref={slider => (this.slider1 = slider)} className="product-image-slider" {...settings}>
                        {image_url1 && <ImageMagnify imagePath={base_url + image_url1} />}
                        {image_url2 && <ImageMagnify imagePath={base_url + image_url2} />}
                        {image_url3 && <ImageMagnify imagePath={base_url + image_url3} />}
                        {image_url4 && <ImageMagnify imagePath={base_url + image_url4} />}
                        {image_url5 && <ImageMagnify imagePath={base_url + image_url5} />}
                        {image_url6 && <ImageMagnify imagePath={base_url + image_url6} />}
                    </Slider>

                    {/* <!-- THUMBNAILS --> */}
                    <Slider focusOnSelect={true} asNavFor={this.state.slider1} ref={slider => (this.slider2 = slider)} className="slider-nav-thumbnails" {...thumbnailSettings}>
                        {image_url1 && <div><ImageWithLoad className="img-fluid" src={base_url + image_url1} alt=".." /></div>}
                        {image_url2 && <div><ImageWithLoad className="img-fluid" src={base_url + image_url2} alt=".." /></div>}
                        {image_url3 && <div><ImageWithLoad className="img-fluid" src={base_url + image_url3} alt=".." /></div>}
                        {image_url4 && <div><ImageWithLoad className="img-fluid" src={base_url + image_url4} alt=".." /></div>}
                        {image_url5 && <div><ImageWithLoad className="img-fluid" src={base_url + image_url5} alt=".." /></div>}
                        {image_url6 && <div><ImageWithLoad className="img-fluid" src={base_url + image_url6} alt=".." /></div>}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default ImageSlider;