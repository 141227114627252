import React from 'react';
import { Link } from "react-router-dom";
import ContentLoader from '../../../../../commons/components/contentLoader';
import MessageContainer from '../../../../../commons/components/messageContainer';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import Constants from '../../../../../utils/Constants';
import DateHelper from '../../../../../utils/DateHelper';
import HelperFunctions from "../../../../../utils/HelperFunctions";
import Address from "./address";
import MyOrders from './myOrders';
// import Order from "./order";
import ProfileUpdate from "./profileUpdate";

export default class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabSelected: 0
        }
    }

    componentDidMount() {
        // console.log(this.props.userData, "this.props.userData");
        if (this.props.userData === null) {
            this.props.history.replace({
                pathname: ProjectRoutes.user.login.url,
                search: HelperFunctions.getQueryStringFromURL(),
            })
        }
    }

    isEnabledBtn = () => {
        return (HelperFunctions.validateEmail(this.state.email_mobile) || HelperFunctions.validateMobile(this.state.email_mobile)) && HelperFunctions.validatePass(this.state.password);
    };

    selectTab = (index) => {
        this.setState({ tabSelected: index });

        switch (index) {
            case 0:
                return;
            case 1:
                this.props.getOrders();
        }
    };

    logout = () => {
        this.props.logout();
    };

    render() {
        // console.log(this.props);
        const { userData, isRequestingForWhom, orders } = this.props;
        const { tabSelected } = this.state;

        // console.log(orders, "orders")
        if (userData === null)
            return null;

        let welcome = "Hello There,";
        const time = new Date().getHours();
        if (time < 12) {
            welcome = ", Good Morning";
        } else if (time < 17) {
            welcome = ", Good Afternoon";
        } else {
            welcome = ", Good Evening";
        }

        return (
            <main className="main pages">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <i className="fi-rs-home mr-5"></i>
                            <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                Home
                            </Link>
                            <span></span> Pages <span></span> My Account
                        </div>
                    </div>
                </div>
                <div className="page-content pt-150 pb-150 hr-account-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 m-auto hr-account-info">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="dashboard-menu">
                                            <ul className="nav flex-column" role="tablist">
                                                <li className="nav-item" onClick={this.selectTab.bind(this, 0)}>
                                                    <a className={`nav-link ${tabSelected === 0 && "active"}`}>
                                                        <i className="fi-rs-settings-sliders mr-10"></i>Dashboard
                                                    </a>
                                                </li>
                                                <li className="nav-item" onClick={this.selectTab.bind(this, 1)}>
                                                    <a className={`nav-link ${tabSelected === 1 && "active"}`}>
                                                        <i className="fi-rs-shopping-bag mr-10"></i>Orders
                                                    </a>
                                                </li>
                                                {/* <li className="nav-item" onClick={this.selectTab.bind(this, 2)}>
                                                    <a className={`nav-link ${tabSelected === 2 && "active"}`}>
                                                        <i className="fi-rs-shopping-cart-check mr-10"></i>Track Your Order
                                                    </a>
                                                </li> */}
                                                <li className="nav-item" onClick={this.selectTab.bind(this, 3)}>
                                                    <a className={`nav-link ${tabSelected === 3 && "active"}`}>
                                                        <i className="fi-rs-marker mr-10"></i>My Address
                                                    </a>
                                                </li>
                                                <li className="nav-item" onClick={this.selectTab.bind(this, 4)}>
                                                    <a className={`nav-link ${tabSelected === 4 && "active"}`}>
                                                        <i className="fi-rs-user mr-10"></i>Account details
                                                    </a>
                                                </li>
                                                <li className="nav-item" onClick={this.logout}>
                                                    <a className={`nav-link ${tabSelected === 5 && "active"}`}>
                                                        <i className="fi-rs-sign-out mr-10"></i>Logout
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="tab-content account dashboard-content pl-50">
                                            <div className={`tab-pane fade animate__animated animate__fadeIn  ${tabSelected === 0 && "active show"}`}>
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h3 className="mb-0">Hello {userData.name + welcome}!</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <p>
                                                            From your account dashboard. you can easily check &amp; view your <a onClick={this.selectTab.bind(this, 1)}>recent orders</a>,<br />
                                                            manage your <a onClick={this.selectTab.bind(this, 3)}>shipping and billing addresses</a> and <a onClick={this.selectTab.bind(this, 4)}>edit your password and account details.</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`tab-pane fade hr-your-oddrs  ${tabSelected === 1 && "active show"}`}>
                                                {
                                                    isRequestingForWhom === Constants.userOrderMatch ? <ContentLoader loaderType="content-relative" /> :
                                                        orders && orders.length > 0 ? <MyOrders orders = {orders} /> : <MessageContainer msg="No Orders Yet." />
                                                }
                                            </div>

                                            <div className={`tab-pane animate__animated animate__fadeIn fade  ${tabSelected === 2 && "active show"}`}>
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h3 className="mb-0">Orders tracking</h3>
                                                    </div>
                                                    <div className="card-body contact-from-area">
                                                        <p>To track your order please enter your OrderID in the box below and press "Track" button. This was given to you on your receipt and in the confirmation email you should have received.</p>
                                                        <div className="row">
                                                            <div className="col-lg-8">
                                                                <form className="contact-form-style mt-30 mb-50" action="#" method="post">
                                                                    <div className="input-style mb-20">
                                                                        <label>Order ID</label>
                                                                        <input name="order-id" placeholder="Found in your order confirmation email" type="text" />
                                                                    </div>
                                                                    <div className="input-style mb-20">
                                                                        <label>Billing email</label>
                                                                        <input name="billing-email" placeholder="Email you used during checkout" type="email" />
                                                                    </div>
                                                                    <button className="submit submit-auto-width" type="submit">Track</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`tab-pane fade  ${tabSelected === 3 && "active show"}`}>
                                                <Address {...this.props} />
                                            </div>

                                            <div className={`tab-pane fade animate__animated animate__fadeIn hr-account-details  ${tabSelected === 4 && "active show"}`}>
                                                <ProfileUpdate {...this.props} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        )
    }
}
