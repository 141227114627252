import APIEndpoints from "../../../../config/APIEndpoints";
import header from "../header";
import * as ActionTypes from "./actionTypes";


export function getHomePageContent() {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_HOME_PAGE_CONTENT,
            promise: APIEndpoints.getHomePageContent(),
            callback: (payload) => {
                // console.log(payload, "payload");
                if (payload && payload.components.length > 0) {
                    payload.components.map((items, index) => {
                        switch (items.type) {
                            case "FeaturedCategoriesCarouselComponent":
                                dispatch(showFeaturedCategory(items));
                                break;
                            case "BannersWithTextComponent":
                                dispatch(showBanners(items));
                                break;
                            case "DealsProductCarouselComponent":
                                dispatch(showDealsofDayProducts(items));
                                break;
                            case "ShopByCategory":
                                dispatch(showShopByCategories(items));
                                break;
                            case "TopDiscountsComponent":
                                dispatch(showTopDiscountsBanner(items));
                                break;
                            case "RotatingBannerCarouselComponent":
                                dispatch(showHomeMainBanners(items));
                                break;
                            case "StaticBannersComponent":
                                dispatch(simpleTwoBanner(items));
                                break;
                            case "SubscribeBannerComponent":
                                dispatch(subscribeBanner(items));
                                break;
                            case "SimpleBannerComponent":
                                dispatch(staticBanners(items));
                                break;
                            case "WhyChooseUsComponent":
                                dispatch(whyChooseItems(items));
                                break;
                            case "ProductCarouselComponent":
                                if (items.name.includes("Featured"))
                                    dispatch(showFeaturedProducts(items));
                                if (items.name.includes("New"))
                                    dispatch(showNewProducts(items));
                                break;
                        }

                    })
                    // console.log(trendingProducts);
                }
            }
        })
    }
}

export function staticBanners(items) {
    return {
        type: ActionTypes.GET_STATIC_BANNERS,
        payload: items
    }
}

export function subscribeBanner(items) {
    return {
        type: ActionTypes.GET_SUBSCRIBE_BANNERS,
        payload: items
    }
}

export function simpleTwoBanner(items) {
    return {
        type: ActionTypes.GET_HOME_TWO_BANNERS,
        payload: items
    }
}

export function whyChooseItems(items) {
    return {
        type: ActionTypes.GET_FOOTER_WHY_CHOOSE,
        payload: items
    }
}

export function showHomeMainBanners(items) {
    return {
        type: ActionTypes.GET_HOME_MAIN_BANNERS,
        payload: items
    }
}

export function showBanners(items) {
    return {
        type: ActionTypes.GET_HOME_BANNERS,
        payload: items
    }
}

export function showTopDiscountsBanner(items) {
    // console.log(items, "items");
    return {
        type: ActionTypes.TOP_DISCOUNTS_BANNER,
        payload: items
    }
}

export function showShopByCategories(items) {
    return {
        type: ActionTypes.SHOP_BY_CATEGORIES,
        payload: items
    }
}

export function showFeaturedCategory(category) {
    return {
        type: ActionTypes.FEATURED_CATEGORY,
        payload: category
    }
}

export function showFeaturedProducts(items) {
    return {
        type: ActionTypes.FEATURED_PRODUCTS,
        payload: items
    }
}

export function showNewProducts(items) {
    return {
        type: ActionTypes.GET_NEW_PRODUCTS,
        payload: items
    }
}

export function showRecentlyAddedProducts(products) {
    return {
        type: ActionTypes.GET_NEW_PRODUCTS,
        payload: products
    }
}

export function showTrendingProducts(products) {
    return {
        type: ActionTypes.GET_TRENDING_PRODUCTS,
        payload: products
    }
}

export function showTopRatedProducts(products) {
    return {
        type: ActionTypes.GET_TOP_RATED_PRODUCTS,
        payload: products
    }
}

export function showTopSellersProducts(products) {
    return {
        type: ActionTypes.GET_TOP_SELLING_PRODUCTS,
        payload: products
    }
}

export function showDealsofDayProducts(products) {
    return {
        type: ActionTypes.GET_TODAYS_DEAL_PRODUCTS,
        payload: products
    }
}