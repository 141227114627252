import React from 'react';
import { Link } from "react-router-dom";
import images from '../../../../../assets/images';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email_mobile: '',
            password: '',
            isRemember: true,
            isLoginWithOTP: true,
            loginOTP: ''
        }
        this.onLoadBtnLoadFlag = false;
    }

    login = (e) => {
        // console.log("login")
        e.preventDefault();
        // var flag = this.state.isLoginWithOTP;
        if (!this.props.isRequesting)
            this.props.login(this.state.email_mobile, this.state.password, this.state.isRemember, this.props.history, this.state.isLoginWithOTP, this.state.loginOTP);
    };

    componentDidMount() {
        if (this.props.userData && this.props.userData.user_id) {
            this.props.history.replace({
                pathname: ProjectRoutes.user.account.url,
                search: HelperFunctions.getQueryStringFromURL(),
            })
        }

        // this.onLoadBtnLoadFlag = true;
        // console.log("check in did");
    }

    componentDidUpdate(){
        this.onLoadBtnLoadFlag = true;
    }

    changeEmailMobileHandler = event => {
        this.setState({
            email_mobile: event.target.value
        });
    };

    changeLoginOTPHandler = event => {
        this.setState({
            loginOTP: event.target.value
        });
    };

    changePassHandler = event => {
        this.setState({
            password: event.target.value
        });
    };

    changeCheckHandler = event => {
        this.setState({
            isRemember: event.target.checked
        });
    };

    isEnabledBtn = () => {
        return (HelperFunctions.validateEmail(this.state.email_mobile) || HelperFunctions.validateMobile(this.state.email_mobile)) && HelperFunctions.validatePass(this.state.password);
    };

    isLoginOTPBtnEnabled = () => {
        return (HelperFunctions.validateMobile(this.state.email_mobile));
    }

    isAfterOTPBtnEnabled = () => {
        return (HelperFunctions.validateOTP(this.state.loginOTP));
    }

    toggleOTPAndPass = () => {
        // this.setState({
        //     isLoginWithOTP: false
        // })

        this.setState((prevState, props) => ({
            isLoginWithOTP: !prevState.isLoginWithOTP
        }));
    }

    //is-valid, is-invalid, invalid-feedback, valid-feedback

    render() {
        const { userData, isRequesting, loginWithOTPStatus } = this.props;
        
        const { isLoginWithOTP, loginOTP } = this.state;
        // console.log(this.onLoadBtnLoadFlag, "this.onLoadBtnLoadFlag");

        // console.log(this.props);
        let isInvalidEmailMob = null, isInvalidPass = null;
        if (userData && userData.errors) {
            if (userData.errors.password) {
                isInvalidPass = userData.errors.password;
            }
            if (userData.errors.email_mobile) {
                isInvalidEmailMob = userData.errors.email_mobile;
            }
        }

        // console.log(userData);

        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                    <i className="fi-rs-home mr-5"></i>Home</Link>
                                <span></span> Login
                                {/* <span></span> Cart */}
                            </div>
                        </div>
                    </div>

                    <div className="page-content animate__animated animate__slideInLeft animate__faster pt-150 pb-150 hr-login-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                                    <div className="row">
                                        {/* <div className="col-lg-6 pr-30 d-none d-lg-block">
                                            <img className="border-radius-15" src={images.loginImg} alt="" />
                                        </div> */}
                                        <div className="col-lg-6 col-md-8 hr-login-col">
                                            <div className="login_wrap widget-taber-content background-white">
                                                <div className="padding_eight_all bg-white">
                                                    <div className="heading_s1">
                                                        <h1 className="mb-5">Login</h1>
                                                        <p className="mb-30">Don't have an account?
                                                            <Link to={{ pathname: ProjectRoutes.user.register.url, search: HelperFunctions.getQueryStringFromURL() }}> Create here</Link>
                                                        </p>
                                                    </div>
                                                    {/* <form method="post" onSubmit={this.login}> */}
                                                    {
                                                        loginWithOTPStatus ? <div className="form-group animate__animated animate__slideInLeft animate__faster">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter OTP here." onChange={this.changeLoginOTPHandler}
                                                                value={this.state.loginOTP} />
                                                        </div> : <div className="form-group">
                                                            <input className={`${isInvalidEmailMob && 'is-invalid'}`}
                                                                type="text"
                                                                placeholder="Email/Mobile here..." onChange={this.changeEmailMobileHandler}
                                                                value={this.state.email_mobile} />
                                                            <div className="invalid-feedback">{isInvalidEmailMob}</div>
                                                        </div>
                                                    }


                                                    {
                                                        !isLoginWithOTP ? <div>
                                                            <div className="form-group" >
                                                                <input className={`${isInvalidPass && 'is-invalid'}`}
                                                                    type="password"
                                                                    placeholder="Password here..." onChange={this.changePassHandler} value={this.state.password} />
                                                                <div className="invalid-feedback">{isInvalidPass}</div>
                                                            </div>

                                                            <div className="login_footer hr-forgot-pass form-group mb-50">
                                                                <Link to={ProjectRoutes.user.recoverPassword.url + HelperFunctions.getQueryStringFromURL()} className="text-muted">
                                                                    Forgot password? recover here
                                                                </Link>
                                                            </div>

                                                            <div className="form-group hr-login-btn">
                                                                <a className={`btn btn-heading btn-block hover-up ${this.isEnabledBtn() ? '' : 'disabled'}`} onClick={this.login}>
                                                                    {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                                    Login
                                                                </a>
                                                            </div>
                                                        </div> : 
                                                        loginWithOTPStatus ? <div className="form-group hr-login-btn">
                                                        {/* <button type="submit" className="btn btn-heading btn-block hover-up" name="login">Log in</button> */}
                                                        <a className={`btn btn-heading btn-block hover-up ${this.isAfterOTPBtnEnabled() ? '' : 'disabled'}`} onClick={this.login}>
                                                            {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                            Login
                                                        </a>
                                                    </div> : 
                                                        <div className="form-group hr-login-btn">
                                                            {/* <button type="submit" className="btn btn-heading btn-block hover-up" name="login">Log in</button> */}
                                                            <a className={`btn btn-heading btn-block hover-up ${this.isLoginOTPBtnEnabled() ? '' : 'disabled'}`} onClick={this.login}>
                                                                {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                                Login with OTP
                                                            </a>
                                                        </div>
                                                    }

                                                    <div className="login_footer hr-or form-group mb-50">
                                                        <a className="text-muted">
                                                            OR
                                                        </a>
                                                    </div>

                                                    <div className="login_footer hr-with-pass form-group mb-50">
                                                        <a onClick={this.toggleOTPAndPass} className="text-muted">
                                                            {isLoginWithOTP ? "Login With Password" : "Login With OTP"}
                                                        </a>
                                                    </div>

                                                    {/* </form> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>


            </React.Fragment >
        )
    }
}
