import React, { Component } from 'react';
import Slider from 'react-slick';
import ProductCard from '../../../../commons/components/productCard/productCard';
import Constants from '../../../../utils/Constants';
import HelperFunctions from '../../../../utils/HelperFunctions';

function SliderBtnNext(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-prev"><i className="fi-rs-arrow-small-left"></i></span>
    );
}

function SliderBtnPrev(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-next"><i className="fi-rs-arrow-small-right"></i></span>
    );
}

class RelatedProducts extends Component {
    render() {
        const { products, wishlistItems } = this.props;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            loop: true,
            adaptiveHeight: true,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ],
            prevArrow: <SliderBtnNext />,
            nextArrow: <SliderBtnPrev />
        };

        // console.log(this.props);
        return products && products.length > 0 ? (
            <div className="row mt-60">
                <div className="col-12">
                    <h2 className="section-title style-1 mb-30">Related products</h2>
                </div>
                <div className="col-12">
                    <div className="row related-products">
                        <Slider className="carausel-8-columns carausel-arrow-center" id="carausel-10-columns" {...settings}>

                            {
                                products.map((product, index) => {
                                    const productClick = this.props.relatedItemClick.bind(this, product);
                                    return (
                                        <div className="col-lg-3 col-md-4 col-12 col-sm-6" key={"" + index}>
                                            <ProductCard
                                                wishlistItems={wishlistItems}
                                                product={product}
                                                index={index}
                                                key={index}
                                                cartProdcutId={this.props.cartProdcutId}
                                                addToCart={this.props.addToCart}
                                                addToWishlist={this.props.addToWishlist}
                                                showProductModal={this.props.showProductModal}
                                                addToCompare={this.props.addToCompare}
                                                isRequestingForCart={this.props.isRequestingForWhom}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        ) : null;
    }
}

export default RelatedProducts;