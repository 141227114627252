import * as ActionTypes from "./actionTypes";

const initialState = {
    products: null,
    totalProductCount: 0,
    recentlyAddedProducts: null,
    trendingProducts: null,
    topRatedProducts: null,
    topSellingProducts: null,

    popularProducts: null,
    dealOfDayProducts: null,
    dailyBestSellProducts: null,
    featuredCategory: null,
    banners: null,
    newProducts: null,
    featuredProducts: null,
    shopByCategoryData: null,
    topDiscountBanners: null,
    homeMainBanners: null,

    whyChooseItems: null,
    simpleTwoBanner: null,
    staticBanners: null,
    subscribeBanner: null,
    // searchProducts: [],
    // newProducts: [],
    // trendProducts: [],
    relatedProducts: [],
    homePageData : []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.GET_HOME_PAGE_CONTENT:
            return { ...state, homePageData: action.payload };

        case ActionTypes.FEATURED_CATEGORY:
            return { ...state, featuredCategory: action.payload };

        case ActionTypes.SHOP_BY_CATEGORIES:
            return { ...state, shopByCategoryData: action.payload };

        case ActionTypes.FEATURED_PRODUCTS:
            return { ...state, featuredProducts: action.payload };

        case ActionTypes.TOP_DISCOUNTS_BANNER:
            return { ...state, topDiscountBanners: action.payload };

        case ActionTypes.GET_HOME_MAIN_BANNERS:
            return { ...state, homeMainBanners: action.payload };

        case ActionTypes.GET_NEW_PRODUCTS:
            return { ...state, newProducts: action.payload };

        case ActionTypes.GET_HOME_BANNERS:
            return { ...state, banners: action.payload };


        case ActionTypes.GET_STATIC_BANNERS:
            return { ...state, staticBanners: action.payload };

        case ActionTypes.GET_SUBSCRIBE_BANNERS:
            return { ...state, subscribeBanner: action.payload };

        case ActionTypes.GET_HOME_TWO_BANNERS:
            return { ...state, simpleTwoBanner: action.payload };

        case ActionTypes.GET_FOOTER_WHY_CHOOSE:
            return { ...state, whyChooseItems: action.payload };



        // case ActionTypes.GET_NEW_PRODUCTS:
        //     return { ...state, recentlyAddedProducts: action.payload };

        case ActionTypes.GET_TRENDING_PRODUCTS:
            return { ...state, trendingProducts: action.payload };

        case ActionTypes.GET_TOP_SELLING_PRODUCTS:
            return { ...state, topSellingProducts: action.payload };

        case ActionTypes.GET_TOP_RATED_PRODUCTS:
            return { ...state, topRatedProducts: action.payload };

        case ActionTypes.GET_POPULAR_PRODUCTS:
            return { ...state, popularProducts: action.payload };

        case ActionTypes.GET_TODAYS_DEAL_PRODUCTS:
            return { ...state, dealOfDayProducts: action.payload };

        case ActionTypes.GET_BEST_SELLS_PRODUCTS:
            return { ...state, dailyBestSellProducts: action.payload };


        case ActionTypes.GET_PRODUCT:
            return { ...state, ...action.payload };
        // case ActionTypes.GET_NEW_PRODUCT:
        //     return {...state, newProducts: action.payload.products};
        // case ActionTypes.GET_TREND_PRODUCT:
        //     return {...state, trendProducts: action.payload.products};
        case ActionTypes.GET_RELATED_PRODUCT:
            return { ...state, relatedProducts: action.payload.products };
        // case ActionTypes.GET_SEARCH_PRODUCT:
        //     return {...state, searchProducts: action.payload.products};
        default:
            return { ...state }
    }
};
export default reducer;