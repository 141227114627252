import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import Breadcrumb from '../../../../../commons/components/breadcrumb';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class DividerBanner extends PureComponent {

    render() {
        return (
            <div className="page-header mt-30 mb-50">
                {/* <Breadcrumb pageName={"Shop"}
                    isBannerBreadcrumb={true}
                /> */}
            </div>
        );
    }

}