import React, { Component } from 'react';
import commonStyles from '../../../../../assets/styles';
import $ from 'jquery';
import MobileHeader from './mobileHeader';
import TrendingCategories from './trendingCategories';
import TopHeader from './topHeader';
import Navigation from './navigation';
import images from '../../../../../assets/images';
import Constants from '../../../../../utils/Constants';
import { Link } from 'react-router-dom';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';
import Wishlist from './wishlist';
import Cart from './cart';
import SearchModal from './searchModal';
import QuickViewModel from '../../../common/models/quickViewModel';
import SearchBar from '../../../common/searchBar';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openSearchModal: false
        }
    }

    toggleTrendingCat() {
        // toggle trending category items
        $(".categories-button-active, body").on("click", function (e) {
            e.preventDefault();
            if ($(this).hasClass("open")) {
                $(this).removeClass("open");
                $(this).siblings(".categories-dropdown-active-large").removeClass("open");
            } else {
                $(this).addClass("open");
                $(this).siblings(".categories-dropdown-active-large").addClass("open");
            }
        });
    }

    openMobileNav() {
        // toggle mobile navigation open 
        var navbarTrigger = $(".burger-icon"),
            endTrigger = $(".mobile-menu-close"),
            container = $(".mobile-header-active"),
            wrapper4 = $("body");

        // wrapper4.prepend('<div className="body-overlay-1"></div>');

        navbarTrigger.on("click", function (e) {
            e.preventDefault();
            container.addClass("sidebar-visible");
            wrapper4.addClass("mobile-menu-active");
        });

        endTrigger.on("click", function () {
            container.removeClass("sidebar-visible");
            wrapper4.removeClass("mobile-menu-active");
        });

        // $(".body-overlay-1").on("click", function () {
        //     container.removeClass("sidebar-visible");
        //     wrapper4.removeClass("mobile-menu-active");
        // });
    }

    // scrollToTop = () => {
    //     $("#scrollUp").animate({ scrollTop: 0 }, "slow");
    // }


    headerSticky() {
        var header = $(".sticky-bar");
        var win = $(window);
        win.on("scroll", function () {
            var scroll = win.scrollTop();
            if (scroll < 200) {
                header.removeClass("stick");
                $(".header-style-2 .categories-dropdown-active-large").removeClass("open");
                $(".header-style-2 .categories-button-active").removeClass("open");
            } else {
                header.addClass("stick");
                // $('body').append('<a id="scrollUp" href="#top" style="position: fixed; z-index: 2147483647;"><i className="fi-rs-arrow-small-up"></i></a>')
            }
        });
    }


    componentDidMount() {

        this.toggleTrendingCat();

        this.openMobileNav();

        this.headerSticky();

        // this.scrollToTop();
    }

    toggleSearchModal = () => {
        this.setState((prevState, props) => ({
            openSearchModal: !prevState.openSearchModal
        }));
    }

    goBack = () => {
        this.props.historyProps.goBack();
    }


    render() {
        const { categories, compareItemsLength, productModal, showProductModal, cartItemsLength, wishlistItemsLength, path, userData } = this.props;
        // console.log(this.props.isRequestingForWhom, "this.props.isRequestingForWhom")
        return (
            <React.Fragment>

                {
                    productModal &&
                    <React.Fragment>
                        <QuickViewModel
                            modelProduct={productModal}
                            showProductModal={showProductModal}
                            history={this.props.history}
                            addToCart={this.props.addToCart}
                            isRequesting={this.props.isRequestingForWhom === Constants.cartAddMatch} />
                        <div className="modal-backdrop fade show"></div>
                    </React.Fragment>
                }


                {this.state.openSearchModal && <SearchModal toggleSearchModal={this.toggleSearchModal} />}

                <header className="header-area header-style-1 header-style-5 header-height-2">
                    {/* <div className="mobile-promotion">
                    <span>Grand opening, <strong>up to 15%</strong> off all items. Only <strong>3 days</strong> left</span>
                </div> */}

                    <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
                        <TopHeader
                            path={path}
                            userData={userData}
                            wishlistItemsLength={wishlistItemsLength}
                            compareItemsLength={compareItemsLength}
                            cartItemsLength={cartItemsLength} />
                    </div>
                    <div className="header-bottom header-bottom-bg-color sticky-bar">
                        <div className="container">
                            <div className="header-wrap header-space-between position-relative">
                                <div className="logo logo-width-1 d-block d-lg-none">
                                    <Link to={{
                                        pathname: ProjectRoutes.home.url,
                                        search: HelperFunctions.getQueryStringFromURL(),
                                    }} className={`${path === ProjectRoutes.home.url ? "active" : ""}`} aria-current="page">
                                        <img src={images.logo} alt="logo" />
                                    </Link>
                                </div>
                                <div className="header-nav d-none d-lg-flex">

                                    {/* <TrendingCategories /> */}

                                    <Navigation
                                        path={path}
                                        categories={categories} />
                                </div>
                                <div className="hotline d-none d-lg-flex">
                                    <img src={images.headphoneIcon} alt="hotline" />
                                    <p>{Constants.address.phone}<span>24/7 Support Center</span></p>
                                </div>

                                <div className="header-action-icon-2 d-block d-lg-none">
                                    <div className="burger-icon burger-icon-white">
                                        <span className="burger-icon-top"></span>
                                        <span className="burger-icon-mid"></span>
                                        <span className="burger-icon-bottom"></span>
                                    </div>
                                </div>
                                <div className="header-action-right d-block d-lg-none">
                                    {/* <div className="search-col">
                                        <i className="search-action">
                                            <img src={images.searchIcon} />
                                        </i>
                                    </div> */}
                                    <div className="header-action-2">
                                        <Wishlist wishlistItemsLength={wishlistItemsLength} />
                                        <Cart cartItemsLength={cartItemsLength} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="search-bar">
                        <div className="search-inner">
                            <div className="search-style-2" >
                                <button onClick={this.goBack} className="back-search"><i className="fi-rs-arrow-small-left"></i></button>
                                <form onClick={this.toggleSearchModal}>
                                    <input type="text" placeholder="Search for items..." />
                                    <button className="search-form-icon"><i className="fi-rs-search"></i></button>
                                </form>

                                <div className="header-action-2">
                                    <Cart cartItemsLength={cartItemsLength} />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <MobileHeader
                    userData={userData}
                    path={path}
                    categories={categories} />
            </React.Fragment>

        );
    }
}

export default Header;