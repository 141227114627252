import React from 'react';
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import { connect } from "react-redux";
import Footer from "./components";
import { withRouter } from "react-router-dom";
import common from "../../common";
import home from '../home';
import compare from '../compare';

class FooterSmart extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Footer {...this.props} />

                
            </React.Fragment>

        )
    }
}

const mapStateToProps = state => ({
    whyChooseItems: state[home.constants.NAME].whyChooseItems,
    compareItems: state[compare.constants.NAME].compareItems,
});

const mapDispatchToProps = dispatch => {
    return {
        removeFromCompare: (itemIndex) => dispatch(compare.actions.removeFromCompare(itemIndex)),
        // userTracking: (actionVal, gameType, propObj) => dispatch(common.actions.userTracking(actionVal, gameType, propObj)),
    }
};

export const FooterContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterSmart));
export default { actionTypes, actions, reducer, constants };
