import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import HelperFunctions from "../../../../utils/HelperFunctions";
import common from "../../common";
import {FooterContainer} from "../footer";
import {HeaderContainer} from "../header";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import User from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class UserSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.history);
    }

    render() {
        // console.log(this.props.loginWithOTPStatus, "loginWithOTPStatus");
        return (
            <React.Fragment>
                <div className="main-section login-wrap">
                    <div className="page-container">
                        <HeaderContainer path={this.props.match.path} historyProps={this.props.history}/>
                        <User {...this.props}/>
                        <FooterContainer showFooterContent = {true} path={this.props.match.path}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    userData: state[constants.NAME].userData,
    orders: state[constants.NAME].orders,
    forgotPassData: state[constants.NAME].forgotPassData,
    matchOTPData: state[constants.NAME].matchOTPData,
    newPasswordData: state[constants.NAME].newPasswordData,
    loginWithOTPStatus: state[constants.NAME].loginWithOTPStatus,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
    countries: HelperFunctions.getActiveForSelect(state[constants.NAME].countries, {name: "--Select Country--", id: ''}),
    states: HelperFunctions.getActiveForSelect(state[constants.NAME].states, {name: "--Select State--", id: ''}),
    cities: HelperFunctions.getActiveForSelect(state[constants.NAME].cities, {name: "--Select City--", id: ''}),
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (routerHistory) => dispatch(actions.callOnPageLoad(routerHistory)),
        login: (email_mobile, password, isRemember, routerHistory, isLoginWithOtp, matchLoginOTP) => dispatch(actions.login(email_mobile, password, isRemember, routerHistory, isLoginWithOtp, matchLoginOTP)),
        register: (name, email, mobile, password, routerHistory) => dispatch(actions.register(name, email, mobile, password, routerHistory)),
        forgotPassword: (email_mobile, routerHistory) => dispatch(actions.forgotPassword(email_mobile, routerHistory)),
        verifyEmail: (email_mobile) => dispatch(actions.verifyEmail(email_mobile)),
        matchOTP: (email_mobile, otp, routerHistory) => dispatch(actions.matchOTP(email_mobile, otp, routerHistory)),
        matchVerificationOTP: (email_mobile, password, otp, routerHistory) => dispatch(actions.matchVerificationOTP(email_mobile, password, otp, routerHistory)),
        matchEmailVerificationOTP: (email_mobile, otp, routerHostory) => dispatch(actions.matchEmailVerificationOTP(email_mobile, otp, routerHostory)),
        newPassword: (email_mobile, password, routerHistory) => dispatch(actions.newPassword(email_mobile, password, routerHistory)),
        changePassword: (email_mobile, old_password, password) => dispatch(actions.changePassword(email_mobile, old_password, password)),
        requestOTPAgain: (email_mobile) => dispatch(actions.requestOTPAgain(email_mobile)),
        updateAddress: (address, routerHostory) => dispatch(actions.updateAddress(address, routerHostory)),
        updateUser: (name, email) => dispatch(actions.updateUser(name, email)),
        getState: (country_id) => dispatch(actions.getState(country_id)),
        getCity: (country_id, state_id) => dispatch(actions.getCity(country_id, state_id)),
        getOrders: () => dispatch(actions.getOrders()),
        logout: () => dispatch(actions.logout()),
    }
};

export const UserContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSmart));
export default {actionTypes, actions, reducer, constants};
