import React from 'react';
import { Link } from 'react-router-dom';
import ContentLoader from "../../../../../commons/components/contentLoader";
import ImagePreload from "../../../../../commons/components/imagePreload/imagePreload";
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';

export default class PrivacyPolicy extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isImagesLoaded: false };

        this.sliderImgsArr = ["/img/updated/bg-about-us.jpg"];
    }

    disableLoader = () => {
        this.setState({ isImagesLoaded: true });
    };

    render() {
        const { isImagesLoaded } = this.state;


        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <i className="fi-rs-home mr-5"></i>
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                    Home
                                </Link>
                                <span></span> Pages <span></span> Privacy Policy
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 m-auto">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                                                <div className="single-header style-2">
                                                    <h2>Privacy Policy</h2>
                                                </div>

                                                <div className="single-content mb-50">
                                                    <ol start="1">
                                                        Subject to the terms and conditions of this Agreement, we hereby grant you a limited, revocable, non-transferable and non-exclusive license to access and use the Site by displaying it on your internet browser only for the purpose of availing the services sold on the Site and not for any commercial use or use on behalf of any third party, except as explicitly permitted by us in advance. Any breach of this Agreement shall result in the immediate revocation of the license granted in this paragraph without notice to you.
                                                    </ol>

                                                    <ol start="1">
                                                        Except as permitted in the paragraph above, you may not reproduce, distribute, display, sell, lease, transmit, create derivative works from, translate, modify, reverse-engineer, disassemble, decompile or otherwise exploit this Site or any portion of it unless expressly permitted by us in writing. You may not make any commercial use of any of the information provided on the Site or make any use of the Site for the benefit of another business unless explicitly permitted by us in advance. We reserve the right to refuse service, terminate accounts, and/or cancel orders at its discretion, including, without limitation, if we believe that customer conduct violates applicable law or is harmful to our interests.                                                    </ol>

                                                    <ol start="1">
                                                        You shall not upload to, distribute, or otherwise publish through this Site any Content, information, or other material that (a) violates or infringes the copyrights, patents, trademarks, service marks, trade secrets, or other proprietary rights of any person; (b) is libelous, threatening, defamatory, obscene, indecent, pornographic, or could give rise to any civil or criminal liability under Indian or international law; or (c) includes any bugs, viruses, worms, trap doors, Trojan horses or other harmful code or properties.                                                    </ol>

                                                    <ol start="1">
                                                        Content provided on this site is solely for informational purposes. It is your sole responsibility to consult a licensed physician regarding the consumption of the snack items made available through our service. Submissions or opinions expressed on this Site are that of the individual expressing such Submission or opinion and may not reflect our opinions. We may assign you a password and account identification to enable you to access and use certain portions of this Site. Each time you use a password or identification, you will be deemed to be authorized to access and use the Site in a manner consistent with the terms and conditions of this Agreement, and we have no obligation to investigate the authorization or source of any such access or use of the Site. you will be solely responsible for all access to and use of this site by anyone using the password and identification originally assigned to you whether or not such access to and use of this site is actually authorized by you, including without limitation, all communications and transmissions and all obligations (including without limitation financial obligations) incurred through such access or use. You are solely responsible for protecting the security and confidentiality of the password and identification assigned to you. You shall immediately notify us of any unauthorized use of your password or identification or any other breach or threatened breach of this Site’s security.                                                    </ol>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>
        );

    }
}
