/*
 * This is a middle ware it gets in action when an action will
 * call a web service so basically this is a mid state somewhere action creator and reducer
 * */
import homeData from "../../assets/jsonData/homeData";
import common from "../modules/common";
import cart from "../modules/screens/cart";
import header from "../modules/screens/header";
// import cart from "../modules/screens/cart";
import home from "../modules/screens/home";
import shop from "../modules/screens/shop";
import user from "../modules/screens/user";
import wishlist from "../modules/screens/wishllist";
// import shop from "../modules/screens/shop";
// import user from "../modules/screens/user";

export default function fetchMiddleware() {
    return next => action => {
        const { promise, ...rest } = action;
        if (!promise) {
            return next(action);
        }

        // if (!action.disableGlobalLoader)
        next({ ...rest, type: common.actionTypes.REQUEST });

        // HelperFunctions.log(promise.url);
        let body = null;
        if (promise.method === 'GET') {
            body = { method: promise.method, headers: promise.headers }
        } else {
            body = { method: promise.method, headers: promise.headers, body: promise.data };
        }

        const promiseNew = fetch(promise.url, body);
        promiseNew.then(response => response.json())
            .then(response => {
                //if status code in resposde is 401 redirect user to identity sso
                if (response.statusCode === 401) {
                    next({
                        type: common.actionTypes.REQUEST_FAILURE,
                        payload: { err: { code: response.statusCode, message: response.message } }
                    })

                    if (response.statusCode === 401) {
                        // console.log('user is not authenticated');
                    }
                } else { //store user info from every API call
                    if (response && response.content && response.content.user_info) {
                        // next(common.actions.storeUserInfo(response.content.user_info));
                    }
                }

                // if (!action.disableGlobalLoader)
                setTimeout(function(){
                    next({ ...rest, type: common.actionTypes.REQUEST_COMPLETE });
                }, 500)
                

                const payload = parseResponse(action.type, response, rest);
                next({ ...rest, payload, type: action.type });
                if (action.callback)
                    action.callback(payload);
            })
            .catch(error => {

                if (action.errorCallback) {
                    action.errorCallback(error);
                }

                next({ type: common.actionTypes.REQUEST_FAILURE, payload: { err: error } })
            });

        return promiseNew;
    };

    function parseResponse(actionType, response, rest) {
        switch (actionType) {
            case common.actionTypes.GET_FEATURED_CATEGORY:
                return response.statusCode === 200 ? response.content : null;
            case shop.actionTypes.GET_SHOP_PRODUCTS:
            case shop.actionTypes.GET_SHOP_RELATED_PRODUCTS:
                return products(response, rest);

            case home.actionTypes.GET_HOME_PAGE_CONTENT:
                // response = homeData;
                return response.statusCode === 200 ? response.content : null;

            case user.actionTypes.USER_LOGIN:
                return login(response);
            case user.actionTypes.USER_REGISTER:
            case user.actionTypes.USER_FORGOT_PASSWORD:
            case user.actionTypes.USER_MATCH_OTP:
            case user.actionTypes.USER_NEW_PASSWORD:
            case user.actionTypes.GET_COUNTRY:
            case user.actionTypes.GET_STATE:
            case user.actionTypes.GET_CITY:
                return response.content ? response.content : response;
            // case home.actionTypes.GET_SEARCH_PRODUCT:
            case home.actionTypes.GET_PRODUCT:
            case cart.actionTypes.GET_CART_ITEMS:
            case wishlist.actionTypes.GET_WISHLIST_ITEMS:
                // case home.actionTypes.GET_NEW_PRODUCT:
                // case home.actionTypes.GET_TREND_PRODUCT:
                // case home.actionTypes.GET_RELATED_PRODUCT:
                return products(response, rest);
            case header.actionTypes.GET_CATEGORY:
                return categoryFilter(response);
            // case shop.actionTypes.GET_PRODUCT_TYPE:
            //     return productTypes(response, rest);
            // case user.actionTypes.UPDATE_ADDRESS:
            // case cart.actionTypes.ADD_ORDER:
            //     return parseUserData(response, rest);
            case user.actionTypes.GET_ORDER:
                return order(response, rest);
            default:
                return response;
        }
    }

    function login(response) {
        if (response.content) {
            if (response.content.is_mobile_verified === false) {
                response.content = { is_mobile_verified: false };
            }
            return response.content
        }
        else {
            return response
        }
    }

    function categoryFilter(response) {
        let content = response.content ? response.content : [];

        const categories = [];
        for (let i = 0; i < content.length; i++) {
            if (content[i].status === 'Active')
                categories.push(content[i]);
        }

        return categories;
    }

    function productTypes(response, rest) {
        let content = response.content ? response.content : [];
        const { categories, category_id } = rest.data;

        const productTypes = [];
        for (let i = 0; i < content.length; i++) {
            if (content[i].status === 'Active')
                productTypes.push({ id: content[i].id, name: content[i].name });
        }

        for (let i = 0; i < categories.length; i++) {
            if (categories[i].id === category_id) {
                if (productTypes.length > 1)
                    categories[i].product_types = productTypes;
                break;
            }
        }

        return categories;
    }

    function products(response, rest) {
        // console.log(rest, "rest");
        let content = response.content ? response.content.products : response;
        if (content && content.length > 0) {



            const products = [];

            for (let i = 0; i < content.length; i++) {
                let badge_text = "", badge_class = "";
                if (content[i].is_new) {
                    badge_text = "New Arrival";
                    badge_class = "new";
                } else if (content[i].is_trending) {
                    badge_text = "Trending";
                    badge_class = "sale";
                } else {
                    badge_text = "Save " + content[i].discount + "%";
                    badge_class = "best";
                }
                if (content[i].total_quantity <= 0) {
                    badge_text = "Soldout";
                    badge_class = "hot";
                }
                content[i]["badge_text"] = badge_text;
                content[i]["badge_class"] = badge_class;

                if (rest.payload) {
                    const { categories } = rest.payload;

                    // check if category id and product id are active for the all producst being displayed on front end
                    if (categories) {
                        let matched = false;
                        for (let j = 0; j < categories.length; j++) {
                            if (categories[j].id === content[i].category_id) { // this is the match for cat id
                                for (let k = 0; k < categories[j].product_types.length; k++) {
                                    if (categories[j].product_types[k].id === content[i].product_type_id) { // this is the match for product type id
                                        matched = true;
                                        break;
                                    }
                                }
                                break;
                            }
                        }
                        if (matched)
                            products.push(content[i]);
                    }
                } else {
                    products.push(content[i]);
                }
            }
            return { totalProductCount: response.content.count, products: products };
        }
        // console.log("hii")
        return { totalProductCount: 0, products: [] }
    }

    function parseUserData(response) {
        if (response.statusCode === 200) {
            var obj = response.content;
            obj.message = response.message;
            obj.statusCode = response.statusCode;
            return obj;
        } else {
            return response.content ? response.content : response;
        }
    }

    function order(response) {
        if (response.statusCode === 200 && response.content && response.content.orders && response.content.orders.length > 0) {
            const orders = response.content.orders;
            for (let i = 0; i < orders.length; i++) {
                const order_items = orders[i].order_items;
                const match_order_items = orders[i].match_order_items;

                let total_original_cost = 0;

                for (let j = 0; j < order_items.length; j++) {
                    order_items[j].name = match_order_items[j].name;
                    order_items[j].image_url1 = match_order_items[j].image_url1;
                    order_items[j].group_id = match_order_items[j].group_id;
                    order_items[j].category_id = match_order_items[j].category_id;
                    order_items[j].product_type_id = match_order_items[j].product_type_id;

                    // const itemCost = parseInt(item.rent_per_product - item.rent_per_product * item.discount / 100) * item.quantity;
                    total_original_cost += parseInt(order_items[j].rent_per_product * order_items[j].quantity)
                }
                let status_class = "bg-info";
                switch (orders[i].status) {
                    case "Processing":
                        status_class = "bg-info";
                        break;
                    case "Pending payment":
                        orders[i].show_re_payment_btn = true;
                        status_class = "bg-warning";
                        break;
                    case "On hold":
                        status_class = "bg-primary";
                        break;
                    case "Completed":
                        status_class = "bg-success";
                        break;
                    case "Cancelled":
                        status_class = "bg-danger";
                        break;
                    case "Refunded":
                        status_class = "bg-info";
                        break;
                    case "Failed":
                        status_class = "bg-danger";
                        break;
                }
                orders[i].status_class = status_class;
                orders[i].total_original_cost = total_original_cost;
            }
            return orders;
        } else {
            return [];
        }
    }
}