import React from 'react';
import { Link } from "react-router-dom";
import images from '../../../../../assets/images';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            mobile: '',
            password: '',
            isAcceptPolicy: false
        }
    }

    register = (e) => {
        e.preventDefault();
        if (!this.props.isRequesting)
            this.props.register(this.state.name, this.state.email, this.state.mobile, this.state.password, this.props.history);
    };

    changeNameHandler = event => {
        this.setState({
            name: event.target.value
        });
    };
    changeEmailHandler = event => {
        this.setState({
            email: event.target.value
        });
    };
    changeMobileHandler = event => {
        this.setState({
            mobile: event.target.value
        });
    };
    changePassHandler = event => {
        this.setState({
            password: event.target.value
        });
    };
    changeCheckHandler = event => {
        console.log(event, 'check box');
        this.setState({
            isAcceptPolicy: event.target.checked
        });
    };

    isEnabledBtn = () => {
        return this.state.isAcceptPolicy &&
            HelperFunctions.validateEmail(this.state.email) &&
            HelperFunctions.validateName(this.state.name) &&
            HelperFunctions.validateMobile(this.state.mobile) &&
            HelperFunctions.validatePass(this.state.password);
    };

    render() {
        const { userData, isRequesting } = this.props;
        let isInvalidMob = null, isInvalidEmail = null, isInvalidPass = null, isInvalidName = null;
        if (userData && userData.errors) {
            if (userData.errors.password) {
                isInvalidPass = userData.errors.password;
            }
            if (userData.errors.email) {
                isInvalidEmail = userData.errors.email;
            }
            if (userData.errors.mobile) {
                isInvalidMob = userData.errors.mobile;
            }
            if (userData.errors.name) {
                isInvalidName = userData.errors.name;
            }
        }

        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                    <i className="fi-rs-home mr-5"></i>Home</Link>
                                <span></span> Register
                                {/* <span></span> Cart */}
                            </div>
                        </div>
                    </div>

                    <div className="page-content animate__animated animate__slideInRight animate__faster pt-150 pb-150 hr-login-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-8 hr-login-col">
                                            <div className="login_wrap widget-taber-content background-white">
                                                <div className="padding_eight_all bg-white">
                                                    <div className="heading_s1">
                                                        <h1 className="mb-5">Create an Account</h1>
                                                        <p className="mb-30">Already have an account?
                                                            <Link to={{ pathname: ProjectRoutes.user.login.url, search: HelperFunctions.getQueryStringFromURL() }}> Login</Link>
                                                        </p>
                                                    </div>
                                                    <form method="post" onSubmit={this.register}>
                                                        <div className="form-group">
                                                            <input className={` ${isInvalidName && 'is-invalid'}`}
                                                                type="text"
                                                                placeholder="Enter your name" onChange={this.changeNameHandler}
                                                                value={this.state.name} />
                                                            <div className="invalid-feedback">{isInvalidName}</div>
                                                        </div>

                                                        <div className="form-group">
                                                            <input className={`${isInvalidEmail && 'is-invalid'}`}
                                                                type="email"
                                                                placeholder="Enter your email address" onChange={this.changeEmailHandler}
                                                                value={this.state.email} />
                                                            <div className="invalid-feedback">{isInvalidEmail}</div>
                                                        </div>

                                                        <div className="form-group">
                                                            <input className={`form-control ${isInvalidMob && 'is-invalid'}`}
                                                                type="tel"
                                                                placeholder="Enter your phone number" onChange={this.changeMobileHandler}
                                                                value={this.state.mobile} />
                                                            <div className="invalid-feedback">{isInvalidMob}</div>
                                                        </div>

                                                        <div className="form-group">
                                                            <input className={`form-control ${isInvalidPass && 'is-invalid'}`}
                                                                type="password"
                                                                placeholder="At least 6 characters" onChange={this.changePassHandler} value={this.state.password} />
                                                            <div className=" invalid-feedback">{isInvalidPass}</div>
                                                        </div>

                                                        {/* <div className="form-group">
                                                            <input required="" type="password" name="password" placeholder="Confirm password" />
                                                        </div> */}
                                                        {/* <div className="login_footer form-group">
                                                            <div className="chek-form">
                                                                <input type="text" required="" name="email" placeholder="Security code *" />
                                                            </div>
                                                            <span className="security-code">
                                                                <b className="text-new">8</b>
                                                                <b className="text-hot">6</b>
                                                                <b className="text-sale">7</b>
                                                                <b className="text-best">5</b>
                                                            </span>
                                                        </div> */}
                                                        {/* <div className="payment_option mb-50">
                                                            <div className="custome-radio">
                                                                <input className="form-check-input" required="" type="radio" name="payment_option" id="exampleRadios3" checked="" />
                                                                <label className="form-check-label" for="exampleRadios3" data-bs-toggle="collapse" data-target="#bankTranfer" aria-controls="bankTranfer">I am a customer</label>
                                                            </div>
                                                            <div className="custome-radio">
                                                                <input className="form-check-input" required="" type="radio" name="payment_option" id="exampleRadios4" checked="" />
                                                                <label className="form-check-label" for="exampleRadios4" data-bs-toggle="collapse" data-target="#checkPayment" aria-controls="checkPayment">I am a vendor</label>
                                                            </div>
                                                        </div> */}
                                                        <div className="login_footer form-group mb-50">
                                                            <div className="chek-form">
                                                                <div className="custome-checkbox">
                                                                    {/* <input className="form-check-input" id="flexCheckDefault" type="checkbox" onChange={this.changeCheckHandler} value={this.state.isAcceptPolicy} />
                                                                    <label className="form-check-label" htmlFor="exampleCheckbox12">I have read and accept policy privacy</label> */}

                                                                    {/* <input type="checkbox" name="checkbox"  onChange={this.changeCheckHandler}  checked/>
                                                                    <input type="radio" checked name="radio12" value = "one"/>
                                                                    <input type="radio" name="radio12" value = "two"/> */}
                                                                    <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox12" onChange={this.changeCheckHandler} value={this.state.isAcceptPolicy} />
                                                                    <label className="form-check-label" htmlFor="exampleCheckbox12"><span>I have read and accept policy privacy.</span></label>
                                                                </div>
                                                            </div>
                                                            {/* <a href="page-privacy-policy.html"><i className="fi-rs-book-alt mr-5 text-muted"></i>Lean more</a> */}
                                                        </div>
                                                        <div className="form-group mb-30 hr-login-btn">
                                                            {/* <button type="submit" className="btn btn-fill-out btn-block hover-up font-weight-bold" name="login">Submit &amp; Register</button> */}
                                                            <button className={`btn btn-fill-out btn-block hover-up font-weight-bold ${this.isEnabledBtn() ? '' : 'disabled'}`} type=" submit">
                                                                {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                                Create your account
                                                            </button>
                                                        </div>
                                                        <p className="font-xs text-muted"><strong>Note:</strong>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy</p>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 pr-30 d-none d-lg-block">
                                            <div className="card-login mt-115">
                                                <a className="social-login facebook-login">
                                                    <img src={images.fbIcon} alt="" />
                                                    <span>Continue with Facebook</span>
                                                </a>
                                                <a className="social-login google-login">
                                                    <img src={images.googleIcon} alt="" />
                                                    <span>Continue with Google</span>
                                                </a>
                                                <a className="social-login apple-login">
                                                    <img src="assets/imgs/theme/icons/logo-apple.svg" alt="" />
                                                    <span>Continue with Apple</span>
                                                </a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>


            </React.Fragment>
        )
    }
}
