import React from 'react';
import { Link } from 'react-router-dom';
import ContentLoader from "../../../../../commons/components/contentLoader";
import ImagePreload from "../../../../../commons/components/imagePreload/imagePreload";
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';

export default class ShippingPolicy extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isImagesLoaded: false };

        this.sliderImgsArr = ["/img/updated/bg-about-us.jpg"];
    }

    disableLoader = () => {
        this.setState({ isImagesLoaded: true });
    };

    render() {
        const { isImagesLoaded } = this.state;


        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <i className="fi-rs-home mr-5"></i>
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                    Home
                                </Link>
                                <span></span> Pages <span></span> Shipping Policy
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 m-auto">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                                                <div className="single-header style-2">
                                                    <h2>Shipping Policy</h2>
                                                </div>

                                                <div className="single-content mb-50">
                                                    <h5>DOMESTIC SHIPMENTS</h5>
                                                    <ol start="1">
                                                        <li>We need upto 2 working days to process the order. Orders will get delivered within 4 working days also it depends upon the service availability for the respective Pincode</li>
                                                        <li>We don’t take any urgent orders and we don’t ship any orders overnight also the weekend and public holidays are off we can’t process the shipments on the same. Shipments may delay in case of any natural calamities, strike, or lockdown.</li>
                                                    </ol>


                                                    <h5>INTERNATIONAL SHIPMENTS</h5>
                                                    <ol start="1">
                                                        <li>We need upto three working days to process the order and delivered within 5-7 working days.</li>
                                                        <li> Any customs charges and taxes depend on the country and it should be borne by the customer itself. We request our overseas customers to check the same with local customs. The respective charges will be added in the billing by the carrier while delivery or after a few weeks of delivery.</li>
                                                    </ol>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>
        );

    }
}
