import React, { Component } from 'react';
import $ from 'jquery';
import Constants from '../../../../../utils/Constants';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';
import { Link } from 'react-router-dom';
import images from '../../../../../assets/images';

class MobileHeader extends Component {

    handleMobNavAccordian() {
    
        $(".mega_menu > .dropdown").slideUp();
        $(".sub_menus > .dropdown").slideUp();

        $(".main_nav_expand").off('click').on("click", function (e) {
            e.preventDefault();
            if ($(this).parent().hasClass("opened")) {
                $(this).html('<i class="fi-rs-angle-small-down"></i>');
                $(this).parent().removeClass("opened");
                $(".mega_menu_content").slideUp();
            } else {
                $(this).html('<i class="fi-rs-angle-small-up"></i>');
                $(this).parent().addClass("opened");
                $(".mega_menu_content").slideDown();
            }
        });

        $(".sub_menus > span").off('click').on('click', function (e) {
            e.preventDefault();
            if ($(this).parent().hasClass("opened")) {
                $(this).parent().find('.sub_nav_expand').html('<i class="fi-rs-angle-small-down"></i>');
                $(this).parent().removeClass("opened");
                $(this).parent().find(".sub_menu_list").slideUp();
            } else {
                $(this).parent().find('.sub_nav_expand').html('<i class="fi-rs-angle-small-up"></i>');
                $(this).parent().addClass("opened");
                $(this).parent().find(".sub_menu_list").slideDown();
            }
        })

        $(".mega_menu").on('click', function () {
            $(this).show();
        })
    }

    componentDidMount() {
        this.handleMobNavAccordian();
    }

    render() {
        const { categories, path, userData } = this.props;
        return (
            <div className="mobile-header-active mobile-header-wrapper-style">
                <div className="mobile-header-wrapper-inner">
                    <div className="mobile-header-top">
                        <div className="mobile-header-logo">

                            <Link to={{
                                pathname: ProjectRoutes.home.url,
                                search: HelperFunctions.getQueryStringFromURL(),
                            }} className={`${path === ProjectRoutes.home.url ? "active" : ""}`} aria-current="page">
                                <img src={images.logo} alt="logo" />
                            </Link>
                        </div>
                        <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                            <button className="close-style search-close">
                                <i className="icon-top"></i>
                                <i className="icon-bottom"></i>
                            </button>
                        </div>
                    </div>
                    <div className="mobile-header-content-area">
                        {/* <div className="mobile-search search-style-3 mobile-header-border">
                            <form action="#">
                                <input type="text" placeholder="Search for items…" />
                                <button type="submit"><i className="fi-rs-search"></i></button>
                            </form>
                        </div> */}
                        <div className="mobile-menu-wrap mobile-header-border">
                            {/* <!-- mobile menu start --> */}
                            <nav>
                                <ul className="mobile-menu font-heading">

                                    <li className="menu-item-has-children">
                                        <Link to={{
                                            pathname: ProjectRoutes.home.url,
                                            search: HelperFunctions.getQueryStringFromURL(),
                                            key: "",
                                            state: {}
                                        }} className={``} aria-current="page">
                                            Home
                                        </Link>
                                    </li>

                                    <li className="menu-item-has-children">

                                        <Link to={{
                                            pathname: ProjectRoutes.aboutUs.url,
                                            search: HelperFunctions.getQueryStringFromURL()
                                        }}>
                                            About us
                                        </Link>
                                    </li>
                                    {
                                        categories && categories.length > 0 && <li className="menu-item-has-children mega_menu">
                                            <a>Categories</a>
                                            <span className="menu-expand main_nav_expand"><i className="fi-rs-angle-small-down"></i></span>
                                            <ul className="dropdown mega_menu_content">
                                                {
                                                    categories.map((cat, catIndex) => {
                                                        return (
                                                            <li key={catIndex} className="menu-item-has-children sub_menus">

                                                                <Link to={{
                                                                    pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(cat.name),
                                                                    search: HelperFunctions.getQueryStringFromURL(),
                                                                    key: cat.name,
                                                                    state: { category_id: cat.id }
                                                                }} className={`${path === ProjectRoutes.aboutUs.url ? "active" : ""}`} aria-current="page">
                                                                    {cat.name}
                                                                </Link>
                                                                <span className="menu-expand sub_nav_expand"><i className="fi-rs-angle-small-down"></i></span>
                                                                {
                                                                    cat.product_types && cat.product_types.length > 0 && <ul className="dropdown sub_menu_list">
                                                                        {
                                                                            cat.product_types.map((prodType, prodIndex) => {
                                                                                return (
                                                                                    <li key={prodIndex}>
                                                                                        <Link to={{
                                                                                            pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(cat.name) + "/" + HelperFunctions.replaceSpace(prodType.name),
                                                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                                                            key: cat.name,
                                                                                            state: { category_id: cat.id, product_id: prodType.id }
                                                                                        }} className={`${path === ProjectRoutes.aboutUs.url ? "active" : ""}`} aria-current="page">
                                                                                            {prodType.name}
                                                                                        </Link>
                                                                                    </li>


                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                }
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    }
                                    <li className="menu-item-has-children">
                                        <Link to={{
                                            pathname: ProjectRoutes.contactUs.url,
                                            search: HelperFunctions.getQueryStringFromURL()
                                        }} className={`${path === ProjectRoutes.contactUs.url ? "active" : ""}`} aria-current="page">
                                            Contact us
                                        </Link>
                                    </li>



                                </ul>
                            </nav>
                            {/* <!-- mobile menu end --> */}
                        </div>
                        <div className="mobile-header-info-wrap">
                            {/* <div className="single-mobile-header-info">
                                <a href="page-contact.html"><i className="fi-rs-marker"></i> Our location </a>
                            </div> */}
                            <div className="single-mobile-header-info">
                                <Link to={{ pathname: ProjectRoutes.user.url, search: HelperFunctions.getQueryStringFromURL() }}
                                    className={`${path === ProjectRoutes.user.url ? "active" : ""}`}>
                                    <i className="fi-rs-user"></i>
                                    <span className="lable ml-0">
                                        {
                                            userData ? "Account" : "Log In / Sign Up"
                                        }
                                    </span>
                                </Link>


                            </div>
                            <div className="single-mobile-header-info">
                                <a ><i className="fi-rs-headphones"></i>{Constants.address.phone}</a>
                            </div>
                        </div>
                        <div className="mobile-social-icon mb-50">
                            <h6 className="mb-15">Follow Us</h6>
                            <a ><img src={images.fbWhiteIcon} alt="" /></a>
                            <a ><img src={images.twitterWhiteIcon} alt="" /></a>
                            <a ><img src={images.instaWhiteIcon} alt="" /></a>
                            <a ><img src={images.pintWhiteIcon} alt="" /></a>
                            <a ><img src={images.youtubeWhiteIcon} alt="" /></a>
                        </div>
                        {/* <div className="site-copyright">Copyright 2021 © Hind Root. All rights reserved. Powered by AliThemes.</div> */}
                    </div>
                </div>
            </div>

        );
    }
}

export default MobileHeader;