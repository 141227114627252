import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import images from '../../../../../assets/images';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import Constants from "../../../../../utils/Constants";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import DealsOfDay from '../../../common/dealsOfDay';
// import NewProducts from '../../../common/newProducts';
import Filter from './filter';
// import ProductsListing from './productsListing';
import SortBy from './sortBy';
import $ from 'jquery';
import NewProducts from '../../../common/newProducts/newProducts';
import ContentLoader from '../../../../../commons/components/contentLoader';
import MessageContainer from '../../../../../commons/components/messageContainer';
import ProductCard from '../../../../../commons/components/productCard/productCard';

export default class Shopping extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            selectedNum: Constants.defaultPageSize,
            openFilterType: null
        }

        // console.log(props, "props");
        this.pageNumArr = [50, 100, 150, 200];
        this.currentProductForCart = null;
    }


    handleTotalNumbersShow = (num) => {
        $(".sort-by-product-area, .sort-by-cover").removeClass("show");
        $(".sort-by-product-area").find(".sort-by-dropdown").removeClass("show");
        this.setState({
            selectedNum: num
        });

        const selected_category_id = this.props.location.state ? this.props.location.state.category_id : '';
        const selected_product_type_id = this.props.location.state ? this.props.location.state.product_type_id : '';

        let propTypes = { "cat_id": selected_category_id, "prod_id": selected_product_type_id, "defaultNum": num };
        this.props.getShopProducts(propTypes);
    }

    clickExpand = (product) => {
        this.props.showProductModel(product);
    };

    componentDidMount() {
        this.props.callOnPageLoad(this.props.history);
    }

    categoryClick = (item) => {
        this.props.history.push({
            pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(item.name),
            search: HelperFunctions.getQueryStringFromURL(),
            key: item.name,
            state: { category_id: item.id, is_scroll_top: false }
        });
        // cateID, proID, pageSize, pageNumber
        // console.log(this.props.history, "this.props.history");
        let propTypes = { "cat_id": item.id, "defaultNum": Constants.defaultPageSize };
        this.props.getShopProducts(propTypes);
    };

    productTypeClick = (category_id, product_type_id) => {
        this.props.getProduct(category_id, product_type_id, true, this.state.selectedNum, 1);
    };

    paginationClick = (pageNum) => {

        // console.log(this.props.location.state, "this.props.location.state");
        const selected_category_id = this.props.location.state && this.props.location.state.category_id ? this.props.location.state.category_id : '';
        const selected_product_type_id = this.props.location.state && this.props.location.state.product_id ? this.props.location.state.product_id : '';

        this.itemToShow = this.state.selectedNum + Constants.defaultPageSize;

        this.setState({
            selectedNum: this.state.selectedNum + Constants.defaultPageSize
        })

        let propTypes = { "cat_id": selected_category_id, "prod_id": selected_product_type_id, "defaultNum": this.itemToShow };
        this.props.getShopProducts(propTypes, true);

        this.setState({ currentPage: pageNum });
    };

    priceFilter = () => {

    }

    renderPagination = () => {
        const { products } = this.props;
        // console.log(this.props);
        const { currentPage } = this.state;

        var totalProductCount = products && products.totalProductCount ? products.totalProductCount : 0;
        if (totalProductCount > this.state.selectedNum) {
            const numPages = Math.ceil(totalProductCount / Constants.defaultPageSize);

            const pages = [];
            for (let i = 0; i < numPages; i++) {
                pages.push(<li key={"pg-" + i} className={`page-item mx-1 ${currentPage === (i + 1) ? "active" : ""}`} onClick={this.paginationClick.bind(this, (i + 1))}><a className="page-link py-1">{i + 1}</a></li>);
            }

            const isPrevDisabled = currentPage === 1;
            const isNextDisabled = currentPage === numPages;

            return (
                <div className="pagination-area mt-20 mb-20">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-start">
                            {/* <li className={`page-item ${isPrevDisabled && "disabled"}`}>
                                <a className="page-link" onClick={this.paginationClick.bind(this, (currentPage - 1))}><i className="fi-rs-arrow-small-left"></i></a>
                            </li>
                            {pages}
                            <li className={`page-item ${isNextDisabled && "disabled"}`}>
                                <a className="page-link" onClick={this.paginationClick.bind(this, (currentPage + 1))}><i className="fi-rs-arrow-small-right"></i></a>
                            </li> */}
                            {
                                this.props.isLoadMore && <ContentLoader loaderType="load-more" />
                            }

                            {
                                !this.props.isLoadMore && <a className={`btn btn-sm add-loader btn-default ${this.props.isLoadMore && "disabled"}`} onClick={this.paginationClick.bind(this, (currentPage + 1))}>
                                    Load More
                                </a>
                            }
                        </ul>
                    </nav>
                </div>
            );
        }
        else return null;
    };

    renderProducts = () => {

        const { products, isRequesting, isRequestingForCart, wishlistItems } = this.props;
        const { selectedNum } = this.state;
        const productCount = products && products.totalProductCount;
        // console.log(wishlistItems, "wishlistItems");

        // console.log(isRequestingForCart, "isRequestingForCart");

        if (isRequesting) {
            return (
                <ContentLoader loaderType="content-relative" />
            )
        }
        else if (products && products.products.length > 0) {
            return (
                <React.Fragment>
                    <SortBy
                        pageNumArr={this.pageNumArr}
                        selectedNum={selectedNum}
                        handleTotalNumbersShow={this.handleTotalNumbersShow}
                        productsCount={productCount} />

                    <div className="row product-grid animate__animated animate__fadeIn">
                        {products.products.map((product, index) => {
                            // console.log(product.avg_rating, "product");

                            return (
                                <div key={index} className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <ProductCard
                                        wishlistItems={wishlistItems}
                                        product={product}
                                        index={index}
                                        key={index}
                                        cartProdcutId={this.props.cartProdcutId}
                                        addToCart={this.props.addToCart}
                                        showProductModal={this.props.showProductModal}
                                        addToWishlist={this.props.addToWishlist}
                                        addToCompare={this.props.addToCompare}
                                        isRequestingForCart={this.props.isRequestingForCart}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {/* pagination */}
                    {this.renderPagination()}
                </React.Fragment>
            );
        }
        else {
            return (
                <MessageContainer msg={"There are no matching products available at the moment"} />
            );
        }
    };

    showFilters = (type) => {
        this.setState({
            openFilterType: type
        })
    }

    componentDidUpdate(prevProps, prevState) {
        // update state 
        // console.log(prevProps, this.props);
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.callOnPageLoad(this.props.history);
            this.showFilters(null);
        }
    }

    render() {
        const { categories, products } = this.props;
        const { selectedNum, openFilterType } = this.state;
        // console.log(this.props, "this.props shopping")
        const selected_category_id = this.props.location.state ? this.props.location.state.category_id : '';
        // const selected_product_type_id = this.props.location.state ? this.props.location.state.product_type_id : '';
        const selected_product_type_id = '';

        return (
            <div className="container mb-30">
                <div className="row flex-row-reverse">

                    <div className="item-filters">
                        <div className="item-filters-inner">
                            <div className="col" onClick={this.showFilters.bind(this, 'cat')}>
                                <a className="fillter-btn">
                                    <i className="fi-rs-apps-sort"></i> Category
                                </a>
                            </div>
                            <div className="col" onClick={this.showFilters.bind(this, 'price')}>
                                <a className="fillter-btn">
                                    <i className="fi-rs-apps-sort"></i>Fill by price
                                </a>
                            </div>
                            {/* <div className="col">
                                <a className="fillter-btn">
                                    <i className="fi-rs-apps-sort"></i>Fill by price
                                </a>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4-5">
                        {/* products*/}
                        {this.renderProducts()}

                    </div>

                    <div className="col-lg-1-5 primary-sidebar sticky-sidebar">
                        <Filter
                            historyProps={this.props.history}
                            selected_category_id={selected_category_id}
                            categories={categories}
                            openFilterType={openFilterType}
                            selected_product_type_id={selected_product_type_id}
                            categoryClick={this.categoryClick}
                            showFilters={this.showFilters}
                            getShopProducts={this.props.getShopProducts}
                            selectedNum={this.state.selectedNum}
                            productTypeClick={this.productTypeClick}
                        />

                        {/* <!-- Product sidebar Widget --> */}
                        {
                            // <NewProducts
                            //     products={products} />
                        }

                    </div>
                </div>
                <DealsOfDay />
            </div>
        );
    }

}