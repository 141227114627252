import {NAME} from "./constants";
export const USER_CHECK = `${NAME}/USER_CHECK`;
export const USER_LOGIN = `${NAME}/USER_LOGIN`;
export const USER_REGISTER = `${NAME}/USER_REGISTER`;
export const USER_FORGOT_PASSWORD = `${NAME}/USER_FORGOT_PASSWORD`;
export const USER_VERIFY_EMAIL = `${NAME}/USER_VERIFY_EMAIL`;
export const USER_REQUEST_OTP = `${NAME}/USER_REQUEST_OTP`;
export const USER_MATCH_OTP = `${NAME}/USER_MATCH_OTP`;
export const USER_NEW_PASSWORD = `${NAME}/USER_NEW_PASSWORD`;
export const UPDATE_ADDRESS = `${NAME}/UPDATE_ADDRESS`;
export const UPDATE = `${NAME}/UPDATE`;
export const GET_COUNTRY = `${NAME}/GET_COUNTRY`;
export const GET_STATE = `${NAME}/GET_STATE`;
export const GET_CITY = `${NAME}/GET_CITY`;
export const GET_ORDER = `${NAME}/GET_ORDER`;
export const LOGIN_WITH_OTP_STATUS = `${NAME}/LOGIN_WITH_OTP_STATUS`;