import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import ErrorContainer from "../../../../../commons/components/errorContainer";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import Account from "./account";
import Login from "./login";
import NewPassword from "./newPassword";
import OTP from "./otp";
import RecoverPassword from "./recoverPassword";
import Register from "./register";
import Verify from "./verify";
import VerifyEmail from "./verifyEmail";

export default class User extends React.Component {

    login = (props) => (
        <Login {...props} loginWithOTPStatus = {this.props.loginWithOTPStatus} userData={this.props.userData} login={this.props.login}
               isRequesting={this.props.isRequestingForWhom === "user/login" || this.props.isRequestingForWhom === "user/register"}/>
    );

    register = (props) => (
        <Register {...props} userData={this.props.userData} register={this.props.register}
                  isRequesting={this.props.isRequestingForWhom === "user/register"}/>
    );

    recoverPass = (props) => (
        <RecoverPassword {...props} forgotPassData={this.props.forgotPassData} forgotPassword={this.props.forgotPassword}
                         isRequesting={this.props.isRequestingForWhom === "user/forgot-password"}/>
    );

    otp = (props) => (
        <OTP {...props} matchOTPData={this.props.matchOTPData} matchOTP={this.props.matchOTP} requestOTPAgain={this.props.requestOTPAgain}
             isRequesting={this.props.isRequestingForWhom === "user/match-otp"} isRequestingOTP={this.props.isRequestingForWhom === "user/request-otp"}/>
    );
    verify = (props) => (
        <Verify {...props} matchOTPData={this.props.matchOTPData} matchVerificationOTP={this.props.matchVerificationOTP} requestOTPAgain={this.props.requestOTPAgain}
                isRequesting={this.props.isRequestingForWhom === "user/match-otp"} isRequestingOTP={this.props.isRequestingForWhom === "user/request-otp"}/>
    );
    verifyEmail = (props) => (
        <VerifyEmail {...props} matchOTPData={this.props.matchOTPData} matchEmailVerificationOTP={this.props.matchEmailVerificationOTP}
                     isRequesting={this.props.isRequestingForWhom === "user/verify-email"}/>
    );
    newPas = (props) => (
        <NewPassword {...props} newPasswordData={this.props.newPasswordData} newPassword={this.props.newPassword}
                     isRequesting={this.props.isRequestingForWhom === "user/new-password"}/>
    );
    account = (props) => (
        <Account {...props} userData={this.props.userData} isRequestingForWhom={this.props.isRequestingForWhom}
                 userAddressData={this.props.userAddressData} updateAddress={this.props.updateAddress} getState={this.props.getState} getOrders={this.props.getOrders} orders={this.props.orders}
                 getCity={this.props.getCity} states={this.props.states} cities={this.props.cities} verifyEmail={this.props.verifyEmail} updateUser={this.props.updateUser} logout={this.props.logout}
                 newPasswordData={this.props.newPasswordData} changePassword={this.props.changePassword}/>
    );

    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route exact path={ProjectRoutes.user.url} render={() => (
                        <Redirect to={ProjectRoutes.user.login.url + HelperFunctions.getQueryStringFromURL()}/>
                    )}/>
                    <Route path={ProjectRoutes.user.login.url} render={this.login}/>
                    <Route path={ProjectRoutes.user.register.url} render={this.register}/>
                    <Route path={ProjectRoutes.user.recoverPassword.url} render={this.recoverPass}/>
                    <Route path={ProjectRoutes.user.otp.url} render={this.otp}/>
                    <Route path={ProjectRoutes.user.verify.url} render={this.verify}/>
                    <Route path={ProjectRoutes.user.verifyEmail.url} render={this.verifyEmail}/>
                    <Route path={ProjectRoutes.user.newPassword.url} render={this.newPas}/>
                    <Route path={ProjectRoutes.user.account.url} render={this.account}/>
                    <Route path={"*"}>
                        <ErrorContainer/>
                    </Route>
                </Switch>
            </React.Fragment>
        )
    }
}
