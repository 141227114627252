import React, { Component } from 'react';
import $ from 'jquery';
import Constants from '../../../../../utils/Constants';

class SortBy extends Component {

    state = {
        toggleSortBy: false,
        selectedFilter: {
            id: "featured",
            name: "Featured"
        },
        filterTypes: [
            {
                id: "featured",
                name: "Featured"
            },
            {
                id: "lth",
                name: "Price: Low to High"
            },
            {
                id: "htl",
                name: "Price: High to Low"
            },
            {
                id: "date",
                name: "Release Date"
            },
            {
                id: "rating",
                name: "Avg. Rating"
            }
        ]
    }

    componentDidMount() {
        // if ($(".sort-by-product-area").length) {
        //     var $body = $("body"),
        //         $cartWrap = $(".sort-by-product-area"),
        //         $cartContent = $cartWrap.find(".sort-by-dropdown");
        //     $cartWrap.on("click", ".sort-by-product-wrap", function (e) {
        //         e.preventDefault();
        //         var $this = $(this);
        //         if (!$this.parent().hasClass("show")) {
        //             $this.siblings(".sort-by-dropdown").addClass("show").parent().addClass("show");
        //         } else {
        //             $this.siblings(".sort-by-dropdown").removeClass("show").parent().removeClass("show");
        //         }
        //     });
        //     /*Close When Click Outside*/
        //     $body.on("click", function (e) {
        //         var $target = e.target;
        //         if (!$($target).is(".sort-by-product-area") && !$($target).parents().is(".sort-by-product-area") && $cartWrap.hasClass("show")) {
        //             $cartWrap.removeClass("show");
        //             $cartContent.removeClass("show");
        //         }
        //     });
        // }
    }

    toggleSortByFilter = () => {
        this.setState((prevState, props) => ({
            toggleSortBy: !prevState.toggleSortBy
        }));
    }

    selectSortFilter = (type) => {
        this.setState({
            selectedFilter: type
        })
        this.toggleSortByFilter();
    }


    render() {
        const { productsCount, pageNumArr, selectedNum } = this.props;
        const { toggleSortBy, filterTypes, selectedFilter } = this.state;
        return (
            <div className="shop-product-fillter">
                {productsCount && <div className="totall-product">
                    <p>We found <strong className="text-brand">{productsCount}</strong> items for you!</p>
                </div>}
                <div className="sort-by-product-area">
                    <div className="sort-by-cover mr-10">
                        {/* <div className="sort-by-product-wrap">
                            <div className="sort-by">
                                <span><i className="fi-rs-apps"></i>Show:</span>
                            </div>
                            <div className="sort-by-dropdown-wrap">
                                <span> {selectedNum} <i className="fi-rs-angle-small-down"></i></span>
                            </div>
                        </div> */}
                        <div className="sort-by-dropdown">
                            <ul>
                                {
                                    pageNumArr.map((num, index) => {
                                        return (
                                            <li key={index} onClick={this.props.handleTotalNumbersShow.bind(this, num)}>
                                                <a className={`${num === selectedNum ? "active" : ""}`}>{num}</a>
                                            </li>
                                        )

                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="sort-by-cover">
                        <div className="sort-by-product-wrap" onClick={this.toggleSortByFilter}>
                            <div className="sort-by">
                                <span><i className="fi-rs-apps-sort"></i>Sort by:</span>
                            </div>
                            <div className="sort-by-dropdown-wrap">
                                <span> {selectedFilter.name} <i className="fi-rs-angle-small-down"></i></span>
                            </div>
                        </div>
                        <div className={`sort-by-dropdown ${toggleSortBy ? "show" : ""}`}>
                            <ul>
                                {
                                    filterTypes.map((type, index) => {
                                        return (
                                            <li onClick={this.selectSortFilter.bind(this, type)} key={index} data-attr={type.id}><a className={selectedFilter.id === type.id ? "active" : ""} >{type.name}</a></li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SortBy;