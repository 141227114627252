import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import ContentLoader from "../../../../../commons/components/contentLoader";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import Select from 'react-select'
import images from '../../../../../assets/images';

export default class BillingInfo extends PureComponent {

    constructor(props) {
        super(props);
        const addressObj = this.props.userData && this.props.userData.address ? this.props.userData.address : {};
        this.state = {
            data: {
                address: '',
                landmark: '',
                pin_code: '',
                country_id: '5da32957a7feed0b6fce495c',
                country_name: 'India',
                state_id: '',
                state_name: '',
                city_id: '',
                city_name: '',
                ...addressObj
            },
            name: this.props.userData ? this.props.userData.name : '',

            payment_method: 'COD'
        };

        // console.log(addressObj.country_id);
        // call default state and cities
        if (addressObj.state_id) {
            if (this.props.states && this.props.states.length > 0) {
            }
            else this.props.getState(addressObj.country_id);
            this.props.getCity(addressObj.country_id, addressObj.state_id);
        } else {
            this.props.getState(this.state.data.country_id);
        }
    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    stateChange = (event) => {
        // console.log(event, "event");
        if (event.id) {

            let state_name = '';
            for (let i = 0; i < this.props.states.length; i++) {
                if (event.id === this.props.states[i].id) {
                    state_name = this.props.states[i].name;
                    break;
                }
            }

            // update state_name in state
            const dataCopy = HelperFunctions.copyObject(this.state.data);
            dataCopy['state_name'] = state_name;
            dataCopy['state_id'] = event.id;
            this.setState({
                data: dataCopy
            });

            this.props.getCity('5da32957a7feed0b6fce495c', event.id);
        }
    };

    cityChange = (event) => {
        if (event.id) {
            let city_name = '';
            for (let i = 0; i < this.props.cities.length; i++) {
                if (event.id === this.props.cities[i].id) {
                    city_name = this.props.cities[i].name;
                    break;
                }
            }

            // update city_name in state
            const dataCopy = HelperFunctions.copyObject(this.state.data);
            dataCopy['city_name'] = city_name;
            dataCopy['city_id'] = event.id;
            this.setState({
                data: dataCopy
            });
        }
    };

    componentDidMount() {
        if (this.props.userData === null) {
            window.redirectToWhere = ProjectRoutes.cart.billingInfo.url;
            this.props.history.push({ pathname: ProjectRoutes.user.login.url, search: HelperFunctions.getQueryStringFromURL() });
            return;
        }
        // window.billingInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.states !== this.props.states) {
        //     // window.stateChoice();
        // }
        // if (prevProps.cities !== this.props.cities) {
        //     // window.cityChoice();
        // }
    }

    placeOrder = () => {
        if (this.props.isRequestingForWhom !== 'cart/add-order')
            this.props.placeOrder(this.state.data, this.state.payment_method, this.props.history);
    };

    isEnabledBtn = () => {
        return (
            HelperFunctions.validateName(this.state.name) &&
            HelperFunctions.validatePIN(this.state.data.pin_code) &&
            HelperFunctions.isNotEmpty(this.state.data.landmark) &&
            HelperFunctions.isNotEmpty(this.state.data.country_id) &&
            HelperFunctions.isNotEmpty(this.state.data.state_id) &&
            HelperFunctions.isNotEmpty(this.state.data.city_id)
        )
    };

    setPaymentType = (val) => {
        console.log(val);
        this.setState({
            payment_method: val
        })
    }

    render() {

        const { cartItems, isRequestingForWhom, countries, states, cities, cartOrderData, isShowPaymentLoader } = this.props;

        // console.log(this.props, "props");
        const { data, name } = this.state;
        // console.log(data, "data");

        const userAddressData = cartOrderData;

        let isInvalidAdd = null, isInvalidPin = null, isInvalidLand = null, isInvalidState = null, isInvalidCity = null;
        if (userAddressData && userAddressData.errors) {
            if (userAddressData.errors.address) {
                isInvalidAdd = userAddressData.errors.address;
            }
            if (userAddressData.errors.pin_code) {
                isInvalidPin = userAddressData.errors.pin_code;
            }
            if (userAddressData.errors.landmark) {
                isInvalidLand = userAddressData.errors.landmark;
            }
            if (userAddressData.errors.state_id) {
                isInvalidState = userAddressData.errors.state_id;
            }
            if (userAddressData.errors.city_id) {
                isInvalidCity = userAddressData.errors.city_id;
            }
        }

        let cartTotal = 0;
        for (let i = 0; i < cartItems.length; i++) {
            const product = cartItems[i];
            cartTotal += product.quantity * parseInt(product.rent_per_product - product.rent_per_product * product.discount / 100);
        }

        if (isShowPaymentLoader) {
            return <ContentLoader loaderType={"section-loader bg-white"} />;
        }

        const statesList = [];
        const citiesList = [];

        states && states.length > 0 && states.map((state, index) => {
            statesList.push({ value: state.name, label: state.name, id: state.id })
        });

        cities && cities.length > 0 && cities.map((city, index) => {
            citiesList.push({ value: city.name, label: city.name, id: city.id })
        });


        return (
            <div className="container hr-checkout-wrap mb-80 mt-50">
                <div className="row">
                    <div className="col-lg-8 mb-40">
                        <h1 className="heading-2 mb-10">Checkout</h1>
                        <div className="d-flex justify-content-between">
                            <h6 className="text-body">There are <span className="text-brand">{cartItems.length}</span> products in your cart</h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7 hr-checkout-form">
                        <div className="row">
                            <h4 className="mb-30">Billing Details</h4>
                            <form method="post">
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <input type="text" required="" name="fname" value={name} placeholder="Full name *" readOnly disabled />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <input onChange={this.onInputChange.bind(this, "address")} type="text" name="billing_address" value={data.address} required="" placeholder="Address *" />
                                    </div>
                                </div>
                                <div className="row shipping_calculator">
                                    <div className="form-group col-lg-6">
                                        <div className="custom_select">
                                            <Select
                                                value={statesList.find(obj => obj.id === data.state_id)}
                                                onChange={this.stateChange}
                                                className="form-control select-active"
                                                options={statesList} />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <div className="custom_select">
                                            <Select
                                                onChange={this.cityChange}
                                                value={citiesList.find(obj => obj.id === data.city_id)}
                                                className="form-control select-active"
                                                options={citiesList} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <input required="" value={data["landmark"]} onChange={this.onInputChange.bind(this, "landmark")} type="text" name="landmark" placeholder="Landmark" />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <input required="" value={data["pin_code"]} onChange={this.onInputChange.bind(this, "pin_code")} value={data.pin_code} type="text" name="zipcode" placeholder="Postcode / ZIP *" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="border p-40 cart-totals ml-30 mb-50">
                            <div className="d-flex align-items-end justify-content-between mb-30">
                                <h4>Cart total</h4>
                                <div className='hr-cart-total-price'>
                                    <h4>{'₹' + cartTotal}</h4>
                                    <h6 className="text-muted">(Incl. of all taxes)</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mb-30">
                                <p className="text-muted mb-2 text-small">
                                    Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our
                                    <Link target="_blank" to={{ pathname: ProjectRoutes.privacyPolicy.url, search: HelperFunctions.getQueryStringFromURL() }}
                                        className="text-decoration-none text-hover-primary link-dark" role="button">
                                        {' privacy policy'}
                                    </Link>
                                    .
                                </p>
                            </div>
                            <div className="divider-2 mb-30"></div>
                            <div className="table-responsive order_table checkout">
                                <div className="payment ml-30">
                                    <h4 className="mb-30">Payment</h4>
                                    <div className="payment_option" >
                                        <div className="payment_option">
                                            {/* <div className="custome-radio">
                                                <input className="form-check-input" required="" type="radio" name="payment_option" id="exampleRadios3" checked="" />
                                                    <label className="form-check-label" for="exampleRadios3" data-bs-toggle="collapse" data-target="#bankTranfer" aria-controls="bankTranfer">Direct Bank Transfer</label>
                                            </div>
                                            <div className="custome-radio">
                                                <input className="form-check-input" required="" type="radio" name="payment_option" id="exampleRadios4" checked="" />
                                                    <label className="form-check-label" for="exampleRadios4" data-bs-toggle="collapse" data-target="#checkPayment" aria-controls="checkPayment">Cash on delivery</label>
                                            </div>
                                            <div className="custome-radio">
                                                <input className="form-check-input" required="" type="radio" name="payment_option" id="exampleRadios5" checked="" />
                                                    <label className="form-check-label" for="exampleRadios5" data-bs-toggle="collapse" data-target="#paypal" aria-controls="paypal">Online Getway</label>
                                            </div> */}
                                        </div>
                                        {/* <div className="custome-radio">
                                            <input className="form-check-input"  type="radio" onChange={this.setPaymentType.bind(this, "prepaid")} name="payment_type" value="prepaid"
                                                checked={this.state.payment_method === "prepaid"} id = "exampleRadios3"/>
                                            <label className="form-check-label"  htmlFor="exampleRadios3">Prepaid</label>
                                        </div>
                                        <div className="custome-radio">
                                            <input checked={this.state.payment_method === "cod"} className="form-check-input"  type="radio" name="payment_type" value="cod" />
                                            <label className="form-check-label" onClick={this.setPaymentType.bind(this, "cod")} htmlFor="exampleRadios4" >Cash on delivery</label>
                                        </div> */}
                                        {/* <input type={"radio"} defaultChecked={this.state.payment_method === "cod" ? true : false} name="payment" onChange={this.setPaymentType.bind(this, "cod")} value="cod" />
                                        <input type={"radio"} defaultChecked={this.state.payment_method === "prepaid" ? true : false} name="payment" onChange={this.setPaymentType.bind(this, "prepaid")} value="prepaid" /> */}

                                        {/* <input type={"checkbox"} checked={this.state.payment_method === "prepaid" ? true : false} name="payments" onChange={this.setPaymentType.bind(this, "prepaid")} value="prepaid" /> */}
                                    </div>
                                    <div className="payment-logo d-flex">
                                        <img className="mr-15" src={images.payZapper} alt="" />
                                        <img className="mr-15" src={images.payVisa} alt="" />
                                        <img className="mr-15" src={images.payMaster} alt="" />
                                        <img src={images.payPal} alt="" />
                                    </div>
                                    <a onClick={this.placeOrder} className="btn btn-fill-out btn-block mt-30">
                                        {isRequestingForWhom === 'cart/add-order' && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                        Place an Order<i className="fi-rs-sign-out ml-15"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


        );
    }

}