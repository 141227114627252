import React from 'react';
import { Link, Redirect, Route, Switch } from "react-router-dom";
import images from '../../../../../assets/images';
import ContentLoader from '../../../../../commons/components/contentLoader';
import ErrorContainer from "../../../../../commons/components/errorContainer";
import MessageContainer from '../../../../../commons/components/messageContainer';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import Constants from '../../../../../utils/Constants';
import HelperFunctions from "../../../../../utils/HelperFunctions";
import BillingInfo from "./billingInfo";
import CartCheckout from "./cart";
import Completed from "./completed";

export default class Cart extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    cartCheckout = (props) => (
        <CartCheckout {...props} cartItems={this.props.cartItemsDetails} changeItemQuantity={this.props.changeItemQuantity}
            removeFromCart={this.props.removeFromCart} isRequest={this.props.isRequestingForWhom === "cart/remove-update"} />
    );
    billingInfo = (props) => (
        <BillingInfo {...props} cartItems={this.props.cartItemsDetails} userData={this.props.userData} cartOrderData={this.props.cartOrderData} userAddressData={this.props.userAddressData}
            placeOrder={this.props.placeOrder} isShowPaymentLoader={this.props.isShowPaymentLoader}
            getState={this.props.getState} getCity={this.props.getCity} states={this.props.states} cities={this.props.cities} isRequestingForWhom={this.props.isRequestingForWhom} />
    );
    completed = (props) => (
        <Completed {...props} userData={this.props.userData} emptyCart={this.props.emptyCart} />
    );

    renderCon = (activeObj) => {
        const { cartItemsDetails, isCartItemsFetched, isOrderCompleted, isRequestingForWhom } = this.props;
        const isRequest = isRequestingForWhom === Constants.cartItemsMatch;

        // console.log(isRequest, "isRequest");
        if (isRequest) {
            return (
                <ContentLoader loaderType = "content-relative" />
            );
        } 
        
        if ((cartItemsDetails.length > 0 && isCartItemsFetched) || isOrderCompleted) {
            return (
                <React.Fragment>
                    {/* <ul className="nav nav-tabs nav-fill border-bottom mb-5 flex-column flex-md-row animated slideInUpLess">
                        <li className="nav-item"><a className={`nav-link ${activeObj.index === 0 ? 'active' : 'disabled'}`} aria-current="page">1. Shopping Cart</a></li>
                        <li className="nav-item"><a className={`nav-link ${activeObj.index === 1 ? 'active' : 'disabled'}`}>2. Billing Information</a></li>
                        <li className="nav-item"><a className={`nav-link ${activeObj.index === 2 ? 'active' : 'disabled'}`}>3. Completed</a></li>
                    </ul> */}

                    <Switch>
                        <Route exact path={ProjectRoutes.cart.url} render={this.cartCheckout} />
                        <Route path={ProjectRoutes.cart.billingInfo.url} render={this.billingInfo} />
                        <Route path={ProjectRoutes.cart.completed.url} render={this.completed} />
                        <Route path={"*"}>
                            <ErrorContainer />
                        </Route>
                    </Switch>
                </React.Fragment>
            );
        }
        else {
            return (
                
                   <MessageContainer icon={images.cartIcon} msg="Your Hindroot cart is empty." />
                
            );
        }
    };

    render() {
        let activeObj = { index: 0, title: 'Shopping cart' };
        switch (this.props.history.location.pathname) {
            case ProjectRoutes.cart.checkout.url:
                activeObj = { index: 0, title: 'Shopping Cart' }
                break;
            case ProjectRoutes.cart.billingInfo.url:
                activeObj = { index: 1, title: 'Billing Information' }
                break;
            case ProjectRoutes.cart.completed.url:
                activeObj = { index: 2, title: 'Completed' }
                break;
        }

        // console.log(this.props.cartItemsDetails);

        return (
            <React.Fragment>
                <main className="main">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                    <i className="fi-rs-home mr-5"></i>Home</Link>
                                <span></span> Shopping cart
                                {/* <span></span> Cart */}
                            </div>
                        </div>
                    </div>





                    {this.renderCon(activeObj)}


                </main>
            </React.Fragment>
        )
    }
}
