import React, { Component } from 'react';
import Slider from 'react-slick';
import header from '../../screens/header';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import home from '../../screens/home';
import ProjectRoutes from '../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../utils/HelperFunctions';
import common from '..';

function SliderBtnNext(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-prev"><i className="fi-rs-arrow-small-left"></i></span>
    );
}

function SliderBtnPrev(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-next"><i className="fi-rs-arrow-small-right"></i></span>
    );
}

class FeaturedCategories extends Component {
    componentDidMount() {
        // this.props.getFeaturedCategory();
    }

    render() {
        var self = this;
        const settings = {
            dots: false,
            infinite: true,
            speed: 150,
            arrows: true,
            autoplay: true,
            slidesToShow: 8,
            slidesToScroll: 1,
            loop: true,
            adaptiveHeight: true,
            initialSlide: 0,
            pauseOnHover: true,
            autoplaySpeed: 6000,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ],
            prevArrow: <SliderBtnNext />,
            nextArrow: <SliderBtnPrev />,
            // appendArrows: appendArrowsClassName
        }

        const { featuredCategory, path } = this.props;
        // console.log(featuredCategories, "featuredCategories")
        return featuredCategory && featuredCategory.data.length > 0 && (
            <section className="popular-categories section-padding featured-category-slider">
                <div className="container wow animate__animated animate__fadeIn">
                    <div className="section-title">
                        <div className="title">
                            <h3>Featured Categories</h3>
                            {/* <ul className="list-inline nav nav-tabs links">
                                <li className="list-inline-item nav-item"><a className="nav-link" href="shop-grid-right.html">Cake & Milk</a></li>
                                <li className="list-inline-item nav-item"><a className="nav-link" href="shop-grid-right.html">Coffes & Teas</a></li>
                                <li className="list-inline-item nav-item"><a className="nav-link active" href="shop-grid-right.html">Pet Foods</a></li>
                                <li className="list-inline-item nav-item"><a className="nav-link" href="shop-grid-right.html">Vegetables</a></li>
                            </ul> */}
                        </div>
                        <div className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow" id="carausel-10-columns-arrows"></div>
                    </div>
                    <div className="carausel-10-columns-cover position-relative">

                        <Slider className="carausel-10-columns" id="carausel-10-columns" {...settings}>

                            {
                                featuredCategory.data.map((cat, index) => {
                                    return (
                                        <div key={index} className="card-2 bg-9 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                            <figure className="img-hover-scale overflow-hidden">
                                                <Link to={{
                                                    pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(cat.category_name) + "/" + HelperFunctions.replaceSpace(cat.name),
                                                    search: HelperFunctions.getQueryStringFromURL(),
                                                    key: cat.name,
                                                    state: { category_id: cat.category_id, product_id: cat.id }
                                                }} className={`${path === ProjectRoutes.shop.url ? "active" : ""}`} aria-current="page">
                                                    <img src={cat.base_url + cat.image_url} alt="" />
                                                </Link>

                                            </figure>
                                            <h6>
                                                <Link to={{
                                                    pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(cat.category_name) + "/" + HelperFunctions.replaceSpace(cat.name),
                                                    search: HelperFunctions.getQueryStringFromURL(),
                                                    key: cat.name,
                                                    state: { category_id: cat.category_id, product_id: cat.id }
                                                }} className={`${path === ProjectRoutes.shop.url ? "active" : ""}`} aria-current="page">
                                                    {cat.name}
                                                </Link>
                                            </h6>
                                            <span>{cat.category_name}</span>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </section>

        );
    }
}



const mapStateToProps = state => {
    return {
        featuredCategory: state[home.constants.NAME].featuredCategory,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // getFeaturedCategory: () => dispatch(common.actions.getFeaturedCategory()),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeaturedCategories));