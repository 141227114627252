import React, { Component } from 'react';
import images from '../../../../assets/images';
import commonStyles from '../../../../assets/styles';
import TimerCounter from '../../../../commons/components/timerCounter';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import home from '../../screens/home';
import ProjectRoutes from '../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../utils/HelperFunctions';
import TimeCounter from '../countDown/timeCounter';
import DateHelper from '../../../../utils/DateHelper';
import Constants from '../../../../utils/Constants';
import ContentLoader from '../../../../commons/components/contentLoader';
import cart from '../../screens/cart';
import wishllist from '../../screens/wishllist';
import common from '..';

class DealsOfDay extends Component {

    constructor(props) {
        super(props);
        this.currentProductForCart = null;
    }


    goToCart = (product) => {
        // add the product To the cart
        if (this.props.isRequestingForWhom)
            return;
        this.currentProductForCart = product.id;
        this.props.addToCart(product, 1);
    };

    goToWishlist = (product) => {
        console.log(this.props.isRequestingForWhom, "this.props.isRequesting");
        // if (this.props.isRequesting)
        //     return;

        this.props.addToWishlist(product);
    }

    render() {
        const { dealOfDayProducts, isRequestingForWhom } = this.props;
        // console.log(dealOfDayProducts, "dealOfDayProducts");
        return dealOfDayProducts && dealOfDayProducts.data.length > 0 && (
            <section className="section-padding pb-5 deals-of-day">
                <div className="container">
                    <div className="section-title wow animate__animated animate__fadeIn" data-wow-delay="0">
                        <h3 className="">Deals Of The Day</h3>
                        {/* <a className="show-all" href="shop-grid-right.html">
                            All Deals
                            <i className="fi-rs-angle-right"></i>
                        </a> */}
                    </div>
                    <div className="row">
                        {
                            dealOfDayProducts.data.length > 0 && dealOfDayProducts.data.map((product, index) => {
                               let releaseTime = null;
                                if (product.deal_ended) {
                                    releaseTime = DateHelper.calculateMatchDateTimeDifferenceFromCurrent(DateHelper.getDateFromStringMoment(product.deal_ended));
                                }
                                return (
                                    <div className="col-xl-3 col-lg-4 col-md-6" key={index}>
                                        <div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                            <div className="product-img-action-wrap">
                                                <div className="product-img">
                                                    <Link to={{
                                                        pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                        search: HelperFunctions.getQueryStringFromURL(),
                                                        state: { product: product }
                                                    }}>
                                                        <img src={product.base_url + product.image_url1} alt="" />

                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="product-content-wrap">
                                                {
                                                   releaseTime && <div className="deals-countdown-wrap">
                                                        <TimeCounter timerValue={releaseTime} />
                                                    </div>
                                                }
                                                <div className="deals-content">
                                                    <h2>
                                                        <Link to={{
                                                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                            state: { product: product }
                                                        }}>
                                                            {product.name}
                                                        </Link>
                                                    </h2>
                                                    <div className="product-rate-cover">
                                                        <div className="product-rate d-inline-block">
                                                            <div className="product-rating" style={commonStyles.widthNinty}></div>
                                                        </div>
                                                        <span className="font-small ml-5 text-muted"> (4.0)</span>
                                                    </div>
                                                    {/* <div>
                                                        <span className="font-small text-muted">By <a href="vendor-details-1.html">NestFood</a></span>
                                                    </div> */}
                                                    <div className="product-card-bottom">
                                                        <div className="product-price">
                                                            <span>{HelperFunctions.calculateFinalPrice(product.rent_per_product, product.discount)}</span>
                                                            <span className="old-price">{'₹' + product.rent_per_product}</span>
                                                        </div>
                                                        <div className="add-cart">
                                                            <a className={`add add-loader ${isRequestingForWhom === Constants.cartAddMatch ? "disabled" : ""}`} onClick={this.goToCart.bind(this, product)}>
                                                                <i className="fi-rs-shopping-cart mr-5"></i>
                                                                {
                                                                    isRequestingForWhom === Constants.cartAddMatch && this.currentProductForCart === product.id ?
                                                                        <ContentLoader loaderType="btn-loader" /> :
                                                                        "Add"
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>

        );
    }
}

const mapStateToProps = state => {
    return {
        dealOfDayProducts: state[home.constants.NAME].dealOfDayProducts,
        isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addToCart: (modelProduct, quantity) => dispatch(cart.actions.addToCart(modelProduct, quantity)),
        addToWishlist: (modelProduct, removedItemIndex) => dispatch(wishllist.actions.addToWishlist(modelProduct, removedItemIndex))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DealsOfDay));