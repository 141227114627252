import React from 'react';
import { Redirect } from "react-router-dom";
import ErrorContainer from "../../commons/components/errorContainer";

import { HomeContainer } from "../../redux/modules/screens/home";
import {UserContainer} from "../../redux/modules/screens/user";
import {CartContainer} from "../../redux/modules/screens/cart";
import {ShopContainer} from "../../redux/modules/screens/shop";
import {InfoContainer} from "../../redux/modules/screens/info";
import HelperFunctions from "../../utils/HelperFunctions";
import ProjectRoutes from "./projectRoutes";
import { WishListContainer } from '../../redux/modules/screens/wishllist';
import { CompareContainer } from '../../redux/modules/screens/compare';
import { CategoryContainer } from '../../redux/modules/screens/categories';

const routes = [
    {
        path: ProjectRoutes.base.url,
        exact: true,
        component: HomeContainer,
    },
    {
        path: ProjectRoutes.home.url,
        component: HomeContainer,
        exact: true
    },
    {
        path: ProjectRoutes.shop.url,
        component: ShopContainer,
    },
    {
        path: ProjectRoutes.categories.url,
        component: CategoryContainer,
    },
    {
        path: ProjectRoutes.user.url,
        component: UserContainer,
    },
    {
        path: ProjectRoutes.cart.url,
        component: CartContainer,
    },

    {
        path: ProjectRoutes.aboutUs.url,
        component: InfoContainer,
        exact: true
    },
    {
        path: ProjectRoutes.contactUs.url,
        component: InfoContainer,
        exact: true
    },
    {
        path: ProjectRoutes.legal.url,
        component: InfoContainer,
        exact: true
    },
    {
        path: ProjectRoutes.privacyPolicy.url,
        component: InfoContainer,
        exact: true
    },
    {
        path: ProjectRoutes.cancellationPolicy.url,
        component: InfoContainer,
        exact: true
    },
    {
        path: ProjectRoutes.shippingPolicy.url,
        component: InfoContainer,
        exact: true
    },
    {
        path: ProjectRoutes.exchangePolicy.url,
        component: InfoContainer,
        exact: true
    },
    {
        path: ProjectRoutes.termsCondition.url,
        component: InfoContainer,
        exact: true
    },
    {
        path: ProjectRoutes.termsUse.url,
        component: InfoContainer,
        exact: true
    },
    {
        path: ProjectRoutes.wishlist.url,
        component: WishListContainer,
        exact: true
    },
    {
        path: ProjectRoutes.compare.url,
        component: CompareContainer,
        exact: true
    },
    {
        component: ErrorContainer
    }
];

export default routes;