import * as ActionTypes from "./actionTypes";

const initialState = {
    userData: undefined,
    userAddressData: {},
    forgotPassData: null,
    matchOTPData: null,
    newPasswordData: null,
    countries: null,
    states: [],
    cities: [],
    orders: null,
    loginWithOTPStatus : false
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.USER_REGISTER:
        case ActionTypes.USER_LOGIN:
            return {...state, userData: action.payload};
        case ActionTypes.USER_FORGOT_PASSWORD:
            return {...state, forgotPassData: action.payload};
        case ActionTypes.USER_NEW_PASSWORD:
            return {...state, newPasswordData: action.payload};
        case ActionTypes.USER_MATCH_OTP:
            return {...state, matchOTPData: action.payload};
        case ActionTypes.UPDATE_ADDRESS:
            return {...state, userAddressData: action.payload};
        case ActionTypes.GET_COUNTRY:
            return {...state, countries: action.payload};
        case ActionTypes.GET_STATE:
            return {...state, states: action.payload};
        case ActionTypes.GET_CITY:
            return {...state, cities: action.payload};
        case ActionTypes.GET_ORDER:
            return {...state, orders: action.payload};
        case ActionTypes.LOGIN_WITH_OTP_STATUS:
            console.log(action.payload, "LOGIN_WITH_OTP_STATUS");
            return {...state, loginWithOTPStatus: action.payload};
        default:
            return {...state}
    }
};
export default reducer;