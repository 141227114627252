import React from "react";
import ContentLoader from "../../../../../commons/components/contentLoader";
import ImagePreload from "../../../../../commons/components/imagePreload/imagePreload";
import Constants from "../../../../../utils/Constants";

export default class ExchangePolicy extends React.PureComponent {

    componentDidMount() {
        window.faqCollapse();
    }

    constructor(props) {
        super(props);
        this.state = {isImagesLoaded: false};

        this.sliderImgsArr = ["/img/updated/suit-cover.jpg"];
    }

    disableLoader = () => {
        this.setState({isImagesLoaded: true});
    };

    render() {
        const {isImagesLoaded} = this.state;

        return (
            <React.Fragment>
                {isImagesLoaded ? (
                    <section className="py-5 bg-center bg-cover animated slideInUpLess"
                             style={{backgroundImage: 'url(img/updated/suit-cover.jpg)', minHeight: '300px'}}></section>
                ) : (
                    <React.Fragment>
                        <ContentLoader loaderType={'content-relative'}/>
                        <ImagePreload disableLoader={this.disableLoader} images={this.sliderImgsArr}/>
                    </React.Fragment>
                )}
                <section>
                    <div className="container animated slideInUpLess">
                        <div className="row">
                            <div className="p-3 p-md-5">
                                <h1 className={"hero-heading"}>Exchange Policy</h1>
                                <p className="text-muted">
                                    Making a purchase indicates that you have acknowledged, understood, and agree to adhere to the following terms and conditions.
                                </p>
                                <ul className="text-muted mt-3">
                                    <li>
                                        <b><u>All the sale, discounted, brought with a coupon and gift voucher purchases are non returnable, non refundable & non
                                            exchangeable.</u></b>
                                    </li>
                                    <li>We are committed to make your shopping experience as fuss-free and enjoyable as much as possible! Please read through the
                                        following terms & conditions prior to making a
                                        purchase.
                                    </li>
                                    <li>If you have received the damaged product or wrong product, please send it back to us with your order no. After sending the product
                                        back to us, & delivered to us we shall send
                                        you a fresh piece.
                                    </li>
                                    <li>There are some items that are <b><u>Made to order</u></b> which are not eligible for returns. The return and exchange policy for
                                        such product is clearly mentioned on the
                                        product page. Please
                                        note that international orders are not eligible for returns or exchanges.
                                    </li>
                                </ul>

                                <h6>CANCELLATIONS AND EXCHANGES</h6>
                                <button className="text-small p-3 mt-1 collapsible text-muted">What is the Exchange policy on Jaipur Threads ?</button>
                                <div className="content text-small text-muted">
                                    <p>
                                        As a company policy we do not provide refund/return on any product once sold. Once the <b>Jaipur Threads</b> pieces are sold they can
                                        be exchanged for <b>replacement in a bigger or
                                        a
                                        smaller size</b> (provided the aforementioned terms and conditions are met). We do issue a credit note for our customers, only
                                        when the desired size of the product is not in
                                        stock.
                                    </p>
                                    <p>
                                        If the product is eligible for size exchange, you may write to us at <a className='text-muted'
                                                                                                                href={"mailto:info@jaipurthread.com"}>info@jaipurthread.com</a>
                                        strictly within
                                        48 hours of delivery to
                                        request exchange for exchange.
                                    </p>
                                    <p>
                                        Once acknowledged by the team, please send the product back to us yourself, we do not have reverse pickup facility. Please do not
                                        forget to take acknowledgement receipt from
                                        the
                                        courier person about the number of products you are dispatching with his signature and mobile number. Jaipur Threads will not be
                                        responsible for any lost shipment.
                                    </p>
                                    <p>
                                        We do only size exchange once for all orders, items once sold are not taken back.
                                    </p>
                                    <p>
                                        The cost of return shipping is at the customer’s expense and is non-refundable. We recommend returning items through traceable
                                        mail (We do not have reverse pick up facility ),
                                        as
                                        any returned items that is not received by Jaipur Threds.com will be treated as not returned.
                                    </p>
                                    <p>
                                        Please note that the goods are your responsibility until they are returned to Jaipur Threds.com on the following address:
                                    </p>
                                    <b>Address for the Exchange:</b>
                                    {Constants.address.name + " " + Constants.address.address + ' ' + Constants.address.pin + " " + Constants.address.area}
                                    <ul>
                                        <li>Purchase made during SALE are not eligible for any kind of return or exchange.</li>
                                        <li>Exchange can be done within 7 days for the date of delivery</li>
                                        <li>Only products which are unused, unworn, unwashed, undamaged, with all its labels and tags completely intact, in original
                                            packaging and eligible for exchange.
                                        </li>
                                        <li>Please mention your name, address, number, email and order id on the parcel along with the size needed.</li>
                                    </ul>
                                    <p>
                                        Size exchange is available (in case of size exchange, the customer is eligible to get a bigger or smaller size, equal to the
                                        amount of the exchanged product minus the
                                        shipping/COD
                                        cost of to & fro).
                                    </p>
                                    <p>

                                        Store credit equalling the amount of the exchanged product minus the shipping/COD cost of to & fro with a validity of 20 days from
                                        the date of receipt of store credit.
                                    </p>
                                    <p>
                                        The cost of shipping to & fro (reverse pickup as well as shipping charges) for the product is to be borne by you.
                                    </p>
                                </div>

                                <button className="text-small p-3 mt-1 collapsible text-muted">Can I cancel my order?</button>
                                <div className="content text-small text-muted">
                                    <p>
                                        You can cancel your Cash on Delivery order within 24 hours of order. Please call us on <a className='text-muted'
                                                                                                                                  href={"tel:+91 82336 08020"}>+91 82336
                                        08020</a> or write to us
                                        at <a className='text-muted' href={"mailto:info@jaipurthread.com"}>info@jaipurthread.com</a> to request a cancellation. You will
                                        not be able to cancel the order by yourself on
                                        the
                                        website.
                                    </p>
                                    <p>
                                        <i>Prepaid orders are not eligible for cancellation.</i>
                                    </p>
                                    <p>
                                        The customer agrees not to dispute the decision made by Jaipur Threads and accept The Jaipur Threads decision regarding the cancellation.
                                    </p>
                                </div>

                                <button className="text-small p-3 mt-1 collapsible text-muted">How will I receive the credit for my money when desired size of the
                                    exchange product is not available?
                                </button>
                                <div className="content text-small text-muted">
                                    <p>
                                        The credit note will be emailed to you, which you can use in next 20 days on the Jaipur Threads website. Please make sure that you use
                                        the credit in one go.
                                    </p>
                                </div>

                                <button className="text-small p-3 mt-1 collapsible text-muted">Can I exchange a part of my order?</button>
                                <div className="content text-small text-muted">
                                    <p>
                                        Yes. You can exchange any products that are eligible for exchange within 48 hours of delivery. Please do note there are some items
                                        that are not returnable or exchangeable.
                                    </p>
                                </div>

                                <button className="text-small p-3 mt-1 collapsible text-muted">My garment does not fit me. What can I do?</button>
                                <div className="content text-small text-muted">
                                    <p>
                                        If the product does not fit and you would like a new size, please request for a size exchange with the customer service team
                                        within 48 hours of receiving the product. Our team
                                        will
                                        try its best to help you for any size exchanges, alterations subject to availability. In case the size you have requested is not
                                        available we will offer you the option to get
                                        credit note to buy something else.
                                    </p>
                                </div>

                                <button className="text-small p-3 mt-1 collapsible text-muted">Why my exchange is not accepted?</button>
                                <div className="content text-small text-muted">
                                    <p>
                                        We endeavour to ensure that every transaction at our website is seamless. We take great care in delivering our products and adhere
                                        to the highest quality standards.
                                    </p>
                                    <ul>
                                        <li>Exchange/Return not accepted if Customer does not like the material or colour of the dress, we suggest that the customer
                                            should read the product description & have a look
                                            at
                                            all the pictures before ordering. You can also call us to know more details about the product if needed.
                                        </li>
                                        <li>Our products are made and handled by human hands involving various processes.There is bound to be variations in colour, finish
                                            and overall look. The colours you see on our
                                            website will depend on the accuracy of your monitor for which subtle variations must be acknowledged.
                                        </li>
                                        <li>10-12% Colour difference depends on the screen resolution of the device used by the client and camera lights, please don’t
                                            expect an exchange or return for the same.
                                        </li>
                                        <li>Exchange/Return not accepted if the product is washed and worn.</li>
                                        <li>All the sale, discounted, brought with a coupon and gift voucher purchases are non returnable, non refundable & non
                                            exchangeable.
                                        </li>
                                        <li>No exchange or return for Home furnishing & Jewellery.</li>
                                        <li>(Exchanges should be done within a week of delivery)</li>
                                        <li>The customers might have to send the product themselves to our warehouse in case of unavailability of pickup in your area or
                                            for urgent orders. The address:
                                        </li>
                                    </ul>
                                    <p>
                                        {Constants.address.name}<br/>
                                        {Constants.address.address}<br/>
                                        {Constants.address.pin}<br/>
                                        {Constants.address.area}<br/>
                                        Mobile No. {Constants.address.phone}
                                    </p>
                                </div>

                                <h6 className={"mt-3"}>INTERNATIONAL SHIPPING AND RETURNS</h6>

                                <button className="text-small p-3 mt-1 collapsible text-muted">Which countries does Jaipur Threads ship to?</button>
                                <div className="content text-small text-muted">
                                    <p>
                                        Jaipur Threads ships to US, UK, UAE, Australia, Hong Kong, South Africa, Qatar, Oman, Malaysia, Singapore, Mauritius, Afghanistan and
                                        South Africa
                                    </p>
                                </div>

                                <button className="text-small p-3 mt-1 collapsible text-muted">What are the international shipping costs and duties?</button>
                                <div className="content text-small text-muted">
                                    <p>
                                        For all international orders from outside India, orders under INR 30,000 (approx. USD 540) are charged INR 1500 per kg (approx.
                                        USD 30)
                                    </p>
                                    <p>
                                        Please note that custom duties and taxes will be charged at the time of delivery based on actual duties charged by your country of
                                        delivery address.
                                    </p>
                                </div>

                                <button className="text-small p-3 mt-1 collapsible text-muted">Can I return, exchange or cancel an international order?</button>
                                <div className="content text-small text-muted">
                                    <p>
                                        You can cancel your order within 24 hours of order. Please note that international orders are not eligible for returns or
                                        exchanges. To cancel your order please email us on <a
                                        className='text-muted' href={"mailto:info@jaipurthread.com"}>info@jaipurthread.com</a>
                                    </p>
                                </div>

                                <button className="text-small p-3 mt-1 collapsible text-muted">How long will it take for my order to reach me?</button>
                                <div className="content text-small text-muted">
                                    <p>
                                        From the time of shipping – it will take about 10-12 days for your international order to reach you.
                                    </p>
                                </div>

                                <button className="text-small p-3 mt-1 collapsible text-muted">What payment methods does Jaipur Threds allow for international shipping?
                                </button>
                                <div className="content text-small text-muted">
                                    <p>
                                        You can use all international credit cards to make the payment for your international shipping orders.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<button className="text-small p-3 mt-1 collapsible text-muted">Open Section 2</button>
                 <div className="content text-small">
                 <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                 ut aliquip ex ea commodo consequat.</p>
                 </div>
                 <button className="text-small p-3 mt-1 collapsible text-muted">Open Section 3</button>
                 <div className="content text-small">
                 <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                 ut aliquip ex ea commodo consequat.</p>
                 </div>*/
                }
            </React.Fragment>
        );
    }
}
