import HelperFunctions from "../utils/HelperFunctions";

export default class APIEndpoints {

    static isProduction = true; // if true live else dev

    /**
     * return base url for api server for PRODUCTION or dev
     * @returns {string}
     */
    static get baseURL() {
        return APIEndpoints.isProduction ? 'https://apidev.printfworld.in/hindroot-api/api/' : 'http://192.168.1.101:8080/api/';
    }

    /**
     * following method is used to remove double slashes from url if present
     * @param url
     * @returns {*}
     */
    static removeDoubleSlashes(url) {
        url = url.replace(new RegExp('//', 'g'), '/');
        if (url.charAt(url.length - 1) === '/') {
            url = url.slice(0, -1);
        }
        return url;
    }

    /**
     * return headers to pass while calling an API works for both PRODUCTION and STAGING
     * @returns {string}
     */
    static headers(whatIsContent = null) {
        // let userId = HelperFunctions.readCookie('user_id');
        let userId = localStorage.getItem("user_id");
        if (userId === undefined) userId = '';
        let header = {
            'user_id': userId,
            'Accept': 'application/json'
        };
        if (whatIsContent === 1) {
            header['Content-Type'] = 'application/json';
        } else if (whatIsContent === 2) {
            header['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        return header;
    }

    /******************************************************User APIs Start************************************************************/
    static login(data) {
        return {
            url: `${APIEndpoints.baseURL}user/login`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static checkLogin() {
        return {
            url: `${APIEndpoints.baseURL}user/check`,
            method: 'GET',
            headers: APIEndpoints.headers(), // for content type url encoded
        };
    }

    static addUser(data, user_id = '') {
        return {
            url: `${APIEndpoints.baseURL}user/add/${user_id}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static forgotPassword(data) {
        return {
            url: `${APIEndpoints.baseURL}user/forgot-password`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static verifyEmail(data) {
        return {
            url: `${APIEndpoints.baseURL}user/verify-email`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static updateUser(data) {
        return {
            url: `${APIEndpoints.baseURL}user/update`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static order() {
        return {
            url: `${APIEndpoints.baseURL}user/order`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static matchOTP(data) {
        return {
            url: `${APIEndpoints.baseURL}user/match-otp`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static newPassword(data) {
        return {
            url: `${APIEndpoints.baseURL}user/change-password`,
            method: 'POST',
            headers: APIEndpoints.headers(2), // for content type url encoded
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static addToCart(data) {
        return {
            url: `${APIEndpoints.baseURL}user/cart/add`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        };
    }

    static addToWishlist(data) {
        return {
            url: `${APIEndpoints.baseURL}user/wishlist/add`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        };
    }

    static removeFromCart(data) {
        return {
            url: `${APIEndpoints.baseURL}user/cart/delete`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static removeFromWishlist(data) {
        return {
            url: `${APIEndpoints.baseURL}user/wishlist/delete`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static deleteCart() {
        return {
            url: `${APIEndpoints.baseURL}user/cart/delete/all`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
        };
    }

    static updateCart(data) {
        return {
            url: `${APIEndpoints.baseURL}user/cart/update`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data),
        };
    }

    static updateAddress(data) {
        return {
            url: `${APIEndpoints.baseURL}user/address`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        };
    }

    static addOrder(data, payment_method) {
        return {
            url: `${APIEndpoints.baseURL}user/order/add/${payment_method}`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        };
    }

    static getCountry() {
        return {
            url: `${APIEndpoints.baseURL}admin/area/country`,
            method: 'GET',
            headers: APIEndpoints.headers(2),
        }
    }

    static getState(country_id = '') {
        return {
            url: `${APIEndpoints.baseURL}admin/area/state/${country_id}`,
            method: 'GET',
            headers: APIEndpoints.headers(2),
        }
    }

    static getCity(country_id = '', state_id = '', city) {
        return {
            url: `${APIEndpoints.baseURL}admin/area/city/${country_id}/${state_id}`,
            method: 'GET',
            headers: APIEndpoints.headers(2),
        }
    }

    /******************************************************User APIs End************************************************************/

    /******************************************************Product APIs Start************************************************************/
    static getProduct(group_id = '', category_id = '', productType_id = '', query = '') {
        group_id = "6038872b33d6632ce1da7e3b";
        // const query = pageSize ? `?page_size=${pageSize}&page_number=${pageNumber}&is_trending=${isTrendNumber}&is_new=${isNewNumber}` : "";
        const url1 = APIEndpoints.removeDoubleSlashes(`${group_id}/${category_id}/${productType_id}`);
        // console.log(query);
        return {
            url: `${APIEndpoints.baseURL}admin/sale/product/${url1}${query}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static getHomePageContent() {
        // group_id = "6038872b33d6632ce1da7e3b";
        // const query = pageSize ? `?page_size=${pageSize}&page_number=${pageNumber}&is_trending=${isTrendNumber}&is_new=${isNewNumber}` : "";
        // const url1 = APIEndpoints.removeDoubleSlashes(`${group_id}/${category_id}/${productType_id}`);
        // console.log(query);
        return {
            url: `${APIEndpoints.baseURL}home`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static getProductWithId(query = '') {
        
        return {
            url: `${APIEndpoints.baseURL}admin/sale/product/${query}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static getCategory(group_id) {
        group_id = "6038872b33d6632ce1da7e3b";
        return {
            url: `${APIEndpoints.baseURL}admin/sale/product/category/${group_id}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static getFeaturedCategory(group_id) {
        group_id = "6038872b33d6632ce1da7e3b";
        return {
            url: `${APIEndpoints.baseURL}admin/sale/product/product-types/featured`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static getProductType(group_id = '', category_id = '') {
        group_id = "6038872b33d6632ce1da7e3b";
        return {
            url: `${APIEndpoints.baseURL}admin/sale/product-type/${group_id}/${category_id}`,
            method: 'GET',
            headers: APIEndpoints.headers(1),
        }
    }

    static getCartItems(data) {
        return {
            url: `${APIEndpoints.baseURL}admin/sale/product/cart-items`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data)
        }
    }

    static getWishlistItems(data) {
        return {
            url: `${APIEndpoints.baseURL}admin/sale/product/wishlist-items`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data)
        }
    }

    /******************************************************Product APIs End************************************************************/

    /******************************************************Payment Link************************************************************/


    static payment() {
        return {
            url: 'https://jaipurthread.com/payment/ccavRequestHandler.php',
        }
    }

    static contactUs(data) {
        return {
            url: `${APIEndpoints.baseURL}contact-us/add`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static postRating(data) {
        return {
            url: `${APIEndpoints.baseURL}admin/sale/product/rating`,
            method: 'POST',
            headers: APIEndpoints.headers(1),
            data: JSON.stringify(data),
        }
    }

    static loginWithOTP(data) {
        return {
            url: `${APIEndpoints.baseURL}user/login-via-otp`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data)
        }
    }

    static matchLoginWithOTP(data) {
        return {
            url: `${APIEndpoints.baseURL}user/match-login-otp`,
            method: 'POST',
            headers: APIEndpoints.headers(2),
            data: HelperFunctions.toURLEncoded(data)
        }
    }
}

