import * as ActionTypes from "./actionTypes";

const wishlist_items = localStorage.getItem("wishlist_items");
const initialState = {
    wishlistItems: wishlist_items ? JSON.parse(wishlist_items) : [],
    wishlistItemsDetails: [],
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_TO_WISHLIST:
            return {...state, wishlistItems: action.payload};
        case ActionTypes.GET_WISHLIST_ITEMS:
            return {...state, wishlistItemsDetails: action.payload};
        default:
            return {...state}
    }
};
export default reducer;