import React, { Component } from 'react';
import Constants from '../../../../../utils/Constants';

class VendorDetails extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="vendor-logo d-flex mb-30">
                    <img src="assets/imgs/vendor/vendor-18.svg" alt="" />
                    <div className="vendor-name ml-15">
                        <h6>
                            <a>{Constants.address.name}</a>
                        </h6>
                        <div className="product-rate-cover text-end">
                            <div className="product-rate d-inline-block">
                                <div className="product-rating" style={{ width: "90%" }}></div>
                            </div>
                            <span className="font-small ml-5 text-muted"> (32 reviews)</span>
                        </div>
                    </div>
                </div>
                <ul className="contact-infor mb-50">
                    <li><img src="assets/imgs/theme/icons/icon-location.svg" alt="" /><strong>Address: </strong> <span>{Constants.address.address + ' ' + Constants.address.pin + ' ' + Constants.address.area}</span></li>
                    <li><img src="assets/imgs/theme/icons/icon-contact.svg" alt="" /><strong>Contact Seller:</strong><span>{Constants.address.phone}</span></li>
                </ul>
                <div className="d-flex mb-55">
                    <div className="mr-30">
                        <p className="text-brand font-xs">Rating</p>
                        <h4 className="mb-0">92%</h4>
                    </div>
                    <div className="mr-30">
                        <p className="text-brand font-xs">Ship on time</p>
                        <h4 className="mb-0">100%</h4>
                    </div>
                    <div>
                        <p className="text-brand font-xs">Chat response</p>
                        <h4 className="mb-0">89%</h4>
                    </div>
                </div>
                <p>{Constants.address.desc}</p>
            </React.Fragment>
        );
    }
}

export default VendorDetails;