import React, { Component } from 'react';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import Shop from "./components";
import * as constants from './constants';
import reducer from './reducer';
import { connect } from "react-redux";
import header, { HeaderContainer } from '../header';
import { FooterContainer } from '../footer';
import home from '../home';
import common from '../../common';
import HelperFunctions from '../../../../utils/HelperFunctions';
import cart from '../cart';
import wishlist from '../wishllist';
import Constants from '../../../../utils/Constants';
import compare from '../compare';
import user from '../user';

class ShopSmart extends Component {

    // componentDidMount() {
    //     HelperFunctions.removeMobileNavOverlay();
    // }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.props.categories !== nextProps.categories) {
    //         console.log(this.props.categories, this.props.history.location);
            
    //     }
    //     return false;
    // }

    // componentWillReceiveProps(nextProps, nextState) {
    //     console.log(this.props.history, nextProps.history)
    //     if (this.props.history.location.pathname !== nextProps.history.location.pathname) {
    //         console.log("changes");
    //         this.props.callOnPageLoad(this.props.history);
    //     }
    // }


    render() {
        // console.log(this.props.history.location.pathname, "products");
        return (
            <React.Fragment>
                <HeaderContainer path={this.props.match.path} historyProps={this.props.history} />
                <Shop {...this.props} />
                <FooterContainer showFooterContent = {true} path={this.props.match.path} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    categories: state[header.constants.NAME].categories,
    shopProducts: state[constants.NAME].shopProducts,
    isLoading: state[constants.NAME].isLoading,
    isLoadMore: state[constants.NAME].isLoadMore,
    relatedProducts: state[constants.NAME].relatedProducts,
    totalProductCount: state[home.constants.NAME].totalProductCount,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
    userData: state[user.constants.NAME].userData,
    cartProdcutId: state[cart.constants.NAME].cartProdcutId,
    wishlistItems: state[wishlist.constants.NAME].wishlistItems,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (pathname) => dispatch(actions.callOnPageLoad(pathname)),
        submitReviewForm: (data) => dispatch(actions.submitReviewForm(data)),
        getProductWithDetail: (pathname) => dispatch(actions.getProductWithDetail(pathname)),
        showProductModal: (product) => dispatch(header.actions.showProductModal(product)),
        // showProductModel: (modelProduct) => dispatch(common.actions.showProductModel(modelProduct)),
        getShopProducts: (propTypes, isLoadMore) => dispatch(actions.getShopProducts(propTypes, isLoadMore)),
        emptyShopProducts: () => dispatch(actions.getShopProducts()),
        // getProduct: (group_id, category_id, productType_id, isEmptyFirst, pageSize, pageNumber) => dispatch(home.actions.getProduct(group_id, category_id, productType_id, isEmptyFirst, pageSize, pageNumber)),
        getRelatedProduct: (category_id, productType_id) => dispatch(actions.getRelatedProduct(category_id, productType_id)),
        getProductType: (group_id, category_id, isEmptyFirst) => dispatch(actions.getProductType(group_id, category_id, isEmptyFirst)),
        addToCart: (modelProduct, quantity) => dispatch(cart.actions.addToCart(modelProduct, quantity)),
        addToWishlist: (modelProduct, removedItemIndex) => dispatch(wishlist.actions.addToWishlist(modelProduct, removedItemIndex)),
        addToCompare: (modelProduct) => dispatch(compare.actions.addToCompare(modelProduct))
    }
};

export const ShopContainer = connect(mapStateToProps, mapDispatchToProps)(ShopSmart);
export default { actionTypes, actions, reducer, constants };