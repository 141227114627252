import * as $ from 'jquery';
import { Slide, toast } from "react-toastify";
import APIEndpoints from "../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../config/routes/projectRoutes";
import Constants from '../../../../utils/Constants';
import HelperFunctions from "../../../../utils/HelperFunctions";
import common from "../../common";
import header from '../header';
import shop from "../shop";
import user from "../user";
import * as ActionTypes from "./actionTypes";
import * as constants from "./constants";

export function callOnPageLoad(routerHistory) {
    return (dispatch, getState) => {
        dispatch(getCartItems());
        dispatch({ type: ActionTypes.COMPLETE_ORDER, payload: { isOrderCompleted: false } });
        const pathname = routerHistory.location.pathname;

        // after payment URL will have order_id and status
        if (pathname.includes(ProjectRoutes.cart.completed.url)) {
            const arr = pathname.split("/");
            if (arr.length === 5) {
                const order_id = arr[3];
                const order_status = arr[4];
                console.log(order_id, order_status);
                dispatch({ type: ActionTypes.COMPLETE_ORDER, payload: { isOrderCompleted: true } });
                routerHistory.push({ pathname: ProjectRoutes.cart.completed.url, search: HelperFunctions.getQueryStringFromURL(), state: { is_scroll_top: true, order_id: order_id, order_status } });
            }
        }
        HelperFunctions.removeMobileNavOverlay();
    }
}

export function handleLocalStorageCartWhenUserLogsIn(user_cart_items = []) {
    return (dispatch, getState) => {
        let cart_items = localStorage.getItem("cart_items");
        cart_items = cart_items ? JSON.parse(cart_items) : [];

        if (cart_items.length > 0) {
            dispatch({
                type: ActionTypes.ADD_TO_USER_CART,
                payload: { isRequestingForWhom: 'cart/add' },
                promise: APIEndpoints.addToCart(cart_items),
                callback: (payload) => {
                    if (payload.statusCode === 200) {
                        //empty the local storage once cart is added to db
                        localStorage.setItem("cart_items", JSON.stringify([]));

                        // we call the API again for user cart items
                        dispatch(user.actions.checkUser());
                    }
                }
            }
            )
        }
        else { //put received user cart items into the store from db
            dispatch({ type: ActionTypes.ADD_TO_CART, payload: user_cart_items });
            // dispatch(getCartItems());
        }
    }
}

/*
    fromWhere defines we are calling addToCart from model or details page
 */
export function addToCart(modelProduct, quantity, fromWhere = '') {
    return (dispatch, getState) => {

        // console.log(modelProduct, quantity, "modelProduct, quantity");
        dispatch({ type: common.actionTypes.REQUEST, payload: { isRequestingForWhom: 'cart/add' } });

        dispatch({ type: ActionTypes.ACTIVE_PRODCUT_CART_ID, payload: modelProduct.id });

        const cartItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].cartItems);

        

        let isAlreadyAdded = false;
        for (let i = 0; i < cartItemsCopy.length; i++) {
            if (modelProduct.id === cartItemsCopy[i].id) {
                quantity = cartItemsCopy[i].quantity + quantity;
                isAlreadyAdded = true;
                break;
            }
        }

        if (isAlreadyAdded) {
            setTimeout(() => {
                this.toastId = toast("Item has been added to cart!", {
                    transition: Slide,
                    closeButton: true,
                    autoClose: 1000,
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.SUCCESS,
                    hideProgressBar: true,
                });
                dispatch({ type: common.actionTypes.REQUEST_COMPLETE });
            }, 1500);
        }
        else {
            const { userData } = getState()[user.constants.NAME];

            cartItemsCopy.push({ quantity, id: modelProduct.id });
            

            const addToStoreAndToast = () => {
                dispatch({ type: ActionTypes.ADD_TO_CART, payload: cartItemsCopy });
                this.toastId = toast("Item has been added to cart!", {
                    transition: Slide,
                    closeButton: true,
                    autoClose: 1000,
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.SUCCESS,
                    hideProgressBar: true,
                });
            };

            if (userData === null) { //this is the case when user is logged out
                setTimeout(() => {
                    localStorage.setItem("cart_items", JSON.stringify(cartItemsCopy));
                    addToStoreAndToast();
                    dispatch({ type: common.actionTypes.REQUEST_COMPLETE });
                }, 1500);
            }
            else {
                dispatch({
                    type: ActionTypes.ADD_TO_USER_CART,
                    payload: { isRequestingForWhom: Constants.cartAddMatch },
                    promise: APIEndpoints.addToCart([{ "id": modelProduct.id, "quantity": quantity }]),
                    callback: (payload) => {
                        if (payload.statusCode === 200) {
                            addToStoreAndToast();
                        }
                    }
                }
                )
            }
        }

        setTimeout(function(){
            dispatch({ type: ActionTypes.ACTIVE_PRODCUT_CART_ID, payload: null });
        }, 1000)
        

        // hide the model if the add to cart was clicked from quick view model
        if (fromWhere === 'model') {
            $(".close").trigger('click');
        }
    }
}

export function changeItemQuantity(itemIndex, quantity) {
    return (dispatch, getState) => {
        const cartItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].cartItems);
        const cartItemsDetailsCopy = HelperFunctions.copyObject(getState()[constants.NAME].cartItemsDetails);

        const item = cartItemsDetailsCopy[itemIndex];

        for (let i = 0; i < cartItemsCopy.length; i++) {
            if (cartItemsCopy[i].id === item.id) {
                if (quantity >= item.min_order_quantity && quantity <= item.total_quantity) {
                    item.quantity = quantity;
                    cartItemsCopy[i].quantity = quantity;

                    const { userData } = getState()[user.constants.NAME];

                    const toPerformAfter = () => {
                        dispatch({ type: ActionTypes.ADD_TO_CART, payload: cartItemsCopy });
                        dispatch({ type: ActionTypes.GET_CART_ITEMS, payload: cartItemsDetailsCopy });
                    };

                    if (userData === null) {
                        localStorage.setItem("cart_items", JSON.stringify(cartItemsCopy));
                        toPerformAfter();
                    }
                    else {
                        dispatch({
                            type: ActionTypes.UPDATE_USER_CART,
                            payload: { isRequestingForWhom: 'cart/remove-update' },
                            promise: APIEndpoints.updateCart(item),
                            callback: (payload) => {
                                if (payload.statusCode === 200) {
                                    toPerformAfter();
                                }
                            }
                        }
                        )
                    }

                    break;
                }
            }
        }
    }
}

export function removeFromCart(itemIndex) {
    return (dispatch, getState) => {
        const cartItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].cartItems);
        const cartItemsDetailsCopy = HelperFunctions.copyObject(getState()[constants.NAME].cartItemsDetails);

        const itemIdToRemove = cartItemsCopy[itemIndex].id;

        cartItemsCopy.splice(itemIndex, 1);
        cartItemsDetailsCopy.splice(itemIndex, 1);

        const { userData } = getState()[user.constants.NAME];

        const toPerformAfter = () => {
            dispatch({ type: ActionTypes.ADD_TO_CART, payload: cartItemsCopy });
            dispatch({ type: ActionTypes.GET_CART_ITEMS, payload: cartItemsDetailsCopy });
            this.toastId = toast("Item has been removed from cart!", {
                transition: Slide,
                closeButton: true,
                autoClose: 1000,
                position: toast.POSITION.TOP_CENTER,
                type: toast.TYPE.INFO,
                hideProgressBar: true,
            })
        };

        if (userData === null) {
            localStorage.setItem("cart_items", JSON.stringify(cartItemsCopy));
            toPerformAfter();
        } else {
            dispatch({
                type: ActionTypes.REMOVE_FROM_USER_CART,
                payload: { isRequestingForWhom: Constants.cartRemoveMatch },
                promise: APIEndpoints.removeFromCart({ id: itemIdToRemove }),
                callback: (payload) => {
                    if (payload.statusCode === 200) {
                        toPerformAfter();
                    }
                }
            }
            )
        }
    }
}

export function getCartItems() {
    return (dispatch, getState) => {
        const cartItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].cartItems);

        // console.log(cartItemsCopy, "cartItemsCopy");

        if (cartItemsCopy.length > 0) {
            let product_ids = "";
            for (let i = 0; i < cartItemsCopy.length; i++) {
                product_ids += (cartItemsCopy[i].id + (i < cartItemsCopy.length - 1 ? "," : ""));
            }

            const { categories } = getState()[header.constants.NAME];

            dispatch({
                type: ActionTypes.GET_CART_ITEMS,
                payload: { isRequestingForWhom: Constants.cartItemsMatch, categories },
                promise: APIEndpoints.getCartItems({ product_ids }),
                callback: (payload) => {
                    if (payload && payload.products && payload.products.length > 0) {
                        for (let i = 0; i < payload.products.length; i++) {
                            for (let j = 0; j < cartItemsCopy.length; j++) {
                                if (payload.products[i].id === cartItemsCopy[j].id) {
                                    payload.products[i].quantity = cartItemsCopy[j].quantity;
                                    break;
                                }
                            }
                        }
                        dispatch({ type: ActionTypes.GET_CART_ITEMS, payload: payload.products });
                    }
                }
            }
            )
        }
        else {
            dispatch({ type: ActionTypes.GET_CART_ITEMS, payload: [] });
        }

    }
}

export function emptyCart(callback = null) {
    return (dispatch, getState) => {
        dispatch({ type: ActionTypes.COMPLETE_ORDER, payload: { isOrderCompleted: true } });
        dispatch({ type: ActionTypes.ADD_TO_CART, payload: [] });
        dispatch({ type: ActionTypes.GET_CART_ITEMS, payload: [] });
        localStorage.setItem("cart_items", JSON.stringify([]));

        dispatch({
            type: ActionTypes.DELETE_USER_CART,
            payload: { isRequestingForWhom: Constants.cartDeleteMatch },
            promise: APIEndpoints.deleteCart(),
            callback: (payload) => {
                // console.log(payload)
                if (callback) {
                    callback(payload);
                }
            }
        }
        )
    }
}

export function placeOrder(address, payment_method = "Prepaid", routerHostory) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.ADD_ORDER,
            payload: { isRequestingForWhom: Constants.cartAddOrderMatch },
            promise: APIEndpoints.addOrder(address, payment_method),
            callback: (payload) => {
                if (payload && payload.statusCode === 200) {
                    if (payment_method === 'COD') {
                        dispatch(emptyCart());
                        routerHostory.push({ pathname: ProjectRoutes.cart.completed.url, search: HelperFunctions.getQueryStringFromURL(), state: { is_scroll_top: true, order_id: payload.order_id, order_status: 'COD' } });
                    } else {
                        dispatch({ type: ActionTypes.PAYMENT_LOADER, payload: { isShowPaymentLoader: true } });
                        // empty the cart as order has been created
                        dispatch(emptyCart(() => {
                            //call payment page on server side where API will be called based on order id to complete the payment
                            const d = new Date().getTime();
                            let userId = localStorage.getItem("user_id");
                            if (userId === undefined) userId = '';
                            HelperFunctions.post(APIEndpoints.payment().url, { tid: d, user_id: userId, order_id: payload.order_id });
                        }));
                    }
                }
            }
        }
        );
    }
}