import APIEndpoints from "../../../config/APIEndpoints";
import Constants from "../../../utils/Constants";
import header from "../screens/header";
import home from "../screens/home";
// import shop from "../screens/shop";
import user from "../screens/user";
import * as ActionTypes from './actionTypes';
import * as constants from "./constants";

export function callOnPageLoad() {
    return (dispatch, getState) => {
        dispatch(user.actions.checkUser());
        dispatch(header.actions.getCategory("", true));
        dispatch(home.actions.getHomePageContent());
        // dispatch(getFeaturedCategory());
        // dispatch(user.actions.getState('5da32957a7feed0b6fce495c'));
        
    }
}

export function getFeaturedCategory() {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_FEATURED_CATEGORY,
            promise: APIEndpoints.getFeaturedCategory(),
            callback: (payload) => {
            //    console.log(payload, "featured category")
            }
        })
    }
}

export function setCurrentOrientation() {
    return (dispatch, getState) => {
        const {currentOrientation} = getState()[constants.NAME];
        const orientation = window.innerHeight > window.innerWidth ? "PORTRAIT" : "LANDSCAPE";

        if (currentOrientation.orientation !== orientation) {
            // dispatch(request());
        }

        const action = {
            type: ActionTypes.SET_CURRENT_ORIENTATION,
            payload: {
                currentOrientation: {
                    orientation: orientation,
                    height: window.innerHeight,
                    width: window.innerWidth,
                }
            }
        };
        dispatch(action);
        if (currentOrientation.orientation !== orientation) {
            setTimeout(() => {
                // dispatch(requestComplete());
            }, 1000);
        }
    };
}
