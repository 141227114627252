import React from 'react';

import { Route, Switch } from "react-router-dom";
import ErrorContainer from "../../../../../commons/components/errorContainer";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import Constants from '../../../../../utils/Constants';
import AboutUs from "./aboutUs";
import CancellationPolicy from './cancellationPolicy';
import ContactUs from "./contactUs";
import ExchangePolicy from "./exchangePolicy";
import Legal from './legal';
import PrivacyPolicy from "./privacyPolicy";
import ShippingPolicy from './shippingPolicy';
import TermsConditions from './termsConditions';
import TermsOfUse from './termsOfUse';

export default class Info extends React.Component {

    render() {

        return (
            <React.Fragment>
                <Switch>
                    <Route path={ProjectRoutes.aboutUs.url}><AboutUs /></Route>
                    <Route path={ProjectRoutes.contactUs.url}>
                        <ContactUs
                            submitContactUsFrom={this.props.submitContactUsFrom}
                            isRequesting={this.props.isRequestingForWhom === Constants.submitContactFrom}
                        />
                    </Route>
                    <Route path={ProjectRoutes.legal.url}><Legal /></Route>
                    <Route path={ProjectRoutes.cancellationPolicy.url}><CancellationPolicy /></Route>
                    <Route path={ProjectRoutes.shippingPolicy.url}><ShippingPolicy /></Route>
                    <Route path={ProjectRoutes.termsCondition.url}><TermsConditions /></Route>
                    <Route path={ProjectRoutes.termsUse.url}><TermsOfUse /></Route>
                    <Route path={ProjectRoutes.privacyPolicy.url}><PrivacyPolicy /></Route>
                    <Route path={ProjectRoutes.exchangePolicy.url}><ExchangePolicy /></Route>
                    <Route path={"*"}><ErrorContainer /></Route>
                </Switch>
            </React.Fragment>
        )
    }
}
