import React from "react";
import {Link} from "react-router-dom";
import {Slide, toast} from "react-toastify";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class NewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            cpassword: ''
        }
    }

    newPassword = (e) => {

        e.preventDefault();

        const {password, cpassword} = this.state;

        if (password === cpassword) {
            if (!this.props.isRequesting) {
                const email_mobile = this.props.location.state ? this.props.location.state.email_mobile : '';
                this.props.newPassword(email_mobile, this.state.password, this.props.history);
            }
        }
        else {
            this.toastId = toast("Password and confirm pass do not match.", {
                transition: Slide,
                closeButton: true,
                autoClose: 2000,
                position: toast.POSITION.TOP_CENTER,
                type: 'error',
                hideProgressBar: true,
            })
        }
    };

    changePassHandler = event => {
        this.setState({
            password: event.target.value
        });
    };
    changeCPassHandler = event => {
        this.setState({
            cpassword: event.target.value
        });
    };

    isEnabledBtn = () => {
        return (HelperFunctions.validatePass(this.state.password) && HelperFunctions.validatePass(this.state.cpassword) && this.state.password === this.state.cpassword);
    };

    render() {
        const {newPasswordData, isRequesting} = this.props;
        let isInvalidPass = null;
        if (newPasswordData && newPasswordData.errors) {
            if (newPasswordData.errors.password) {
                isInvalidPass = newPasswordData.errors.password;
            }
        }
        return (
            <React.Fragment>
                <div className="bg-light py-2 animated slideInLeftLess">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 bg-light">
                                <li className="breadcrumb-item">
                                    <Link to={{pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL()}} className="reset-anchor">
                                        Home
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">New Password</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <section className="animated slideInUpLess">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                <h1 className="hero-heading">New Password</h1>
                                <p className="text-muted mb-5">Use form below to set new password!</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                {/* <form className="mb-5" onSubmit={this.newPassword}> */}
                                    <div className="row">
                                        <div className="form-group col-lg-6 mb-3">
                                            <label className="form-label">New Password</label>
                                            <input className={`form-control ${isInvalidPass && 'is-invalid'}`}
                                                   type="password"
                                                   placeholder="New password here..." onChange={this.changePassHandler} value={this.state.password}/>
                                            <div className=" invalid-feedback">{isInvalidPass}</div>
                                        </div>
                                        <div className="form-group col-lg-6 mb-3">
                                            <label className="form-label">Confirm Password</label>
                                            <input className={`form-control ${isInvalidPass && 'is-invalid'}`}
                                                   type="password"
                                                   placeholder="Confirm new password here..." onChange={this.changeCPassHandler} value={this.state.cpassword}/>
                                            <div className=" invalid-feedback">{isInvalidPass}</div>
                                        </div>
                                        <div className="form-group col-12">
                                            <a onClick={this.newPassword} className={`btn btn-primary ${this.isEnabledBtn() ? '' : 'disabled'}`} >
                                                {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true"/>}
                                                Set New Password
                                            </a>
                                        </div>
                                    </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
