import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HelperFunctions from '../../../utils/HelperFunctions';

class TopDiscountsBanner extends Component {
    render() {
        const { banners } = this.props;
        // console.log(banners, "bantpners");
        return banners && banners.data.length > 0 && (
            <section className="banners mb-25">
                <div className="container">

                    <div className="section-title">
                        <div className="title">
                            <h3>{banners.name}</h3>               
                        </div>
                    </div>

                    <div className="row">
                        {
                            banners.data.map((item, index) => {
                                return (
                                    <div key={index} className="col-lg-4 col-md-6">
                                        <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                            <img src={item.base_url + '' + item.image_url} alt="" />
                                            <div className="banner-text">
                                                <h4>
                                                    {item.code} <br /> {item.mediaText}
                                                </h4>

                                                <Link to={{
                                                    pathname: item.url,
                                                    search: HelperFunctions.getQueryStringFromURL(),
                                                    key: item.id,
                                                    state: { item }
                                                }} className={`btn btn-xs`} aria-current="page">
                                                    Shop Now <i className="fi-rs-arrow-small-right"></i>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

        );
    }
}

export default TopDiscountsBanner;