import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../../../../assets/images';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';

class Cart extends Component {
    render() {
        const { cartItemsLength, path } = this.props;
        // console.log(cartItemsLength);
        return (
            <div className="header-action-icon-2">

                <Link to={{ pathname: ProjectRoutes.cart.url, search: HelperFunctions.getQueryStringFromURL() }}
                    className={`mini-cart-icon ${path === ProjectRoutes.cart.url ? "active" : ""}`}>
                    <img alt="Nest" src={images.cartIcon} />
                    <span className="pro-count blue">{cartItemsLength}</span>
                </Link>

                <Link to={{ pathname: ProjectRoutes.cart.url, search: HelperFunctions.getQueryStringFromURL() }}
                    className={`mini-cart-icon ${path === ProjectRoutes.cart.url ? "active" : ""}`}>
                    <span className="lable">Cart</span>
                </Link>
                
                {/* <div className="cart-dropdown-wrap cart-dropdown-hm2">
                    <ul>
                        <li>
                            <div className="shopping-cart-img">
                                <a href="shop-product-right.html"><img alt="Nest" src="assets/imgs/shop/thumbnail-3.jpg" /></a>
                            </div>
                            <div className="shopping-cart-title">
                                <h4><a href="shop-product-right.html">Daisy Casual Bag</a></h4>
                                <h4><span>1 × </span>$800.00</h4>
                            </div>
                            <div className="shopping-cart-delete">
                                <a><i className="fi-rs-cross-small"></i></a>
                            </div>
                        </li>
                        <li>
                            <div className="shopping-cart-img">
                                <a href="shop-product-right.html"><img alt="Nest" src="assets/imgs/shop/thumbnail-2.jpg" /></a>
                            </div>
                            <div className="shopping-cart-title">
                                <h4><a href="shop-product-right.html">Corduroy Shirts</a></h4>
                                <h4><span>1 × </span>$3200.00</h4>
                            </div>
                            <div className="shopping-cart-delete">
                                <a><i className="fi-rs-cross-small"></i></a>
                            </div>
                        </li>
                    </ul>
                    <div className="shopping-cart-footer">
                        <div className="shopping-cart-total">
                            <h4>Total <span>$4000.00</span></h4>
                        </div>
                        <div className="shopping-cart-button">
                            <a href="shop-cart.html" className="outline">View cart</a>
                            <a href="shop-checkout.html">Checkout</a>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default Cart;