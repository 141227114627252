import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../../../../assets/images';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';
import { Range, getTrackBackground } from 'react-range';

const STEP = 10;
const MIN = 0;
const MAX = 1000;

class Filter extends Component {
    state = { values: [MIN, MAX] };

    rangeFilterOnchange = () => {
        const { selected_category_id, selected_product_type_id, selectedNum } = this.props;
        // console.log(this.props.historyProps)
        var minPrice = this.state.values[0];
        var maxPrice = this.state.values[1];
        var priceParam = "min_price=" + minPrice + "&max_price=" + maxPrice;

        this.props.historyProps.push({
            pathname: this.props.historyProps.location.pathname,
            search: priceParam,
            state: { category_id: selected_category_id, product_id: selected_product_type_id, is_scroll_top: false }
        });


        let propTypes = { "cat_id": selected_category_id, "prod_id": selected_product_type_id, "defaultNum": selectedNum, "price_range": priceParam };
        
        this.props.showFilters(null);
        this.props.getShopProducts(propTypes);
    }

    render() {
        const { selected_category_id, historyProps, categories, selected_product_type_id, openFilterType } = this.props;
        // console.log(historyProps, "categories");
        return (
            <React.Fragment>
                <div style={{ display: openFilterType === "cat" ? "block" : "" }} className={`animate__animated animate__slideInLeft animate__faster sidebar-widget widget-category-2 mb-30 mob-modal mobile-category-card`}>
                    <h5 className="section-title style-1 mb-30">Category
                        <a onClick={this.props.showFilters.bind(this, null)} className="close-modal"><i className="fi-rs-cross"></i></a>
                    </h5>
                    <div className="widget-inner-block">
                        <ul>
                            <li onClick={this.props.categoryClick.bind(this, { id: "", name: "" })}>
                                <Link to={{
                                    pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace("All Products"),
                                    search: HelperFunctions.getQueryStringFromURL(true),
                                    key: "All Products",
                                    state: { category_id: "", is_scroll_top: false }
                                }} role={"button"}  className={`${selected_category_id === "" ? "text-primary fw-bold" : ""}`}>
                                    <img src={images.cateIcon} alt="" />  All Products
                                </Link>
                            </li>

                            {categories && categories.map((item, index) => {
                                return (
                                    <li key={"" + index} onClick={this.props.categoryClick.bind(this, { id: item.id, name: item.name })}>
                                        <Link to={{
                                            pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(item.name),
                                            search: HelperFunctions.getQueryStringFromURL(true),
                                            state: { category_id: item.id, is_scroll_top: false }
                                        }}
                                        >
                                            <img src={images.cateIcon} alt="" />
                                            {item.name}
                                        </Link>
                                        <span className="count">{item.count}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                {/* <!-- Fillter By Price --> */}
                <div style={{ display: openFilterType === "price" ? "block" : "" }} className="animate__animated animate__slideInLeft animate__faster sidebar-widget price_range range mb-30 mob-modal mobile-filter-card">
                    <h5 className="section-title style-1 mb-30">Fill by price
                        <a onClick={this.props.showFilters.bind(this, null)} className="close-modal"><i className="fi-rs-cross"></i></a>
                    </h5>
                    <div className="widget-inner-block">
                        <div className="price-filter">
                            <div className="price-filter-inner">
                                {/* <div id="slider-range"></div>
                            <div className="price_slider_amount">
                                <div className="label-input"><span>Range:</span><input type="text" id="amount" name="price" placeholder="Add Your Price" /></div>
                            </div> */}
                                <Range
                                    step={STEP}
                                    min={MIN}
                                    max={MAX}
                                    values={this.state.values}
                                    onChange={(values) => this.setState({ values })}
                                    // onFinalChange={this.rangeFilterOnchange}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            {...props}

                                            style={{
                                                ...props.style,
                                                background: getTrackBackground({
                                                    values: this.state.values,
                                                    colors: ["#3BB77E", "#000", "#3BB77E"],
                                                    min: MIN,
                                                    max: MAX
                                                }),
                                                height: '6px',
                                                width: '100%'
                                            }}
                                        >
                                            {children}
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                width: "14px",
                                                height: "14px",
                                                lineHeight: "10px",
                                                background: "#3BB77E",
                                                border: "none",
                                                borderRadius: "100%",
                                                // top: "-5px"
                                            }}
                                        />
                                    )}
                                />

                                <div className="price_slider_amount">
                                    <div className="label-input">
                                        <span>Range:</span>
                                        {this.state.values[0] + ' - ' + this.state.values[1]}
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        {/* <a className=""><i className="fi-rs-cross mr-5"></i> Clear Filter</a> */}
                        <a onClick={this.rangeFilterOnchange} className="btn btn-sm btn-default "><i className="fi-rs-filter mr-5"></i>Fillter</a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Filter;