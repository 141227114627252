import * as $ from 'jquery';
import { Slide, toast } from "react-toastify";
import APIEndpoints from "../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../config/routes/projectRoutes";
import Constants from '../../../../utils/Constants';
import HelperFunctions from "../../../../utils/HelperFunctions";
import common from "../../common";
import header from '../header';
import shop from "../shop";
import user from '../user';
// import user from "../user";
import * as ActionTypes from "./actionTypes";
import * as constants from "./constants";

export function callOnPageLoad(routerHistory) {
    return (dispatch, getState) => {
        dispatch(getWishlistItems());
        // dispatch({type: ActionTypes.COMPLETE_ORDER, payload: {isOrderCompleted: false}});
        // const pathname = routerHistory.location.pathname;

        // // after payment URL will have order_id and status
        // if (pathname.includes(ProjectRoutes.cart.completed.url)) {
        //     const arr = pathname.split("/");
        //     if (arr.length === 5) {
        //         const order_id = arr[3];
        //         const order_status = arr[4];
        //         console.log(order_id, order_status);
        //         dispatch({type: ActionTypes.COMPLETE_ORDER, payload: {isOrderCompleted: true}});
        //         routerHistory.push({pathname: ProjectRoutes.cart.completed.url, search: HelperFunctions.getQueryStringFromURL(), state: {is_scroll_top: true, order_id: order_id, order_status}});
        //     }
        // }

        HelperFunctions.removeMobileNavOverlay();
    }
}

/*
    fromWhere defines we are calling addToWishlist from model or details page
 */
export function addToCompare(modelProduct, fromWhere = '') {
    return (dispatch, getState) => {

        // console.log(modelProduct, "modelProduct, quantity");
        dispatch({ type: common.actionTypes.REQUEST, payload: { isRequestingForWhom: Constants.compareAddMatch } });

        const compareItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].compareItems);

        // console.log(compareItemsCopy, "compareItemsCopy");

        if (compareItemsCopy.length < 3) {
            let isAlreadyAdded = false;
            if (compareItemsCopy.length > 0) {
                for (let i = 0; i < compareItemsCopy.length; i++) {
                    if (modelProduct.id === compareItemsCopy[i].id) {
                        // quantity = wishlistItemsCopy[i].quantity + quantity;
                        isAlreadyAdded = true;
                        break;
                    }
                }
            }

            if (isAlreadyAdded) {
                setTimeout(() => {
                    this.toastId = toast("Item has been added to compare!", {
                        transition: Slide,
                        closeButton: true,
                        autoClose: 1000,
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.SUCCESS,
                        hideProgressBar: true,
                    });
                    dispatch({ type: common.actionTypes.REQUEST_COMPLETE });
                }, 1500);
            }
            else {

                compareItemsCopy.push(modelProduct);

                const addToStoreAndToast = () => {
                    dispatch({ type: ActionTypes.ADD_TO_COMPARE, payload: compareItemsCopy });
                    this.toastId = toast("Item has been added to compare!", {
                        transition: Slide,
                        closeButton: true,
                        autoClose: 1000,
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.SUCCESS,
                        hideProgressBar: true,
                    });
                };

                setTimeout(() => {
                    addToStoreAndToast();
                    dispatch({ type: common.actionTypes.REQUEST_COMPLETE });
                }, 1500);
            }
        }






        // hide the model if the add to cart was clicked from quick view model
        if (fromWhere === 'model') {
            $(".close").trigger('click');
        }
    }
}

export function removeFromCompare(itemIndex) {
    return (dispatch, getState) => {
        const compareItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].compareItems);

        const itemIdToRemove = compareItemsCopy[itemIndex].id;

        compareItemsCopy.splice(itemIndex, 1);
    

        const toPerformAfter = () => {
            dispatch({ type: ActionTypes.ADD_TO_COMPARE, payload: compareItemsCopy });
            this.toastId = toast("Item has been removed from compare list!", {
                transition: Slide,
                closeButton: true,
                autoClose: 1000,
                position: toast.POSITION.TOP_CENTER,
                type: toast.TYPE.INFO,
                hideProgressBar: true,
            })
        };

        toPerformAfter();
    }
}

export function removeAllProductsFromCompare() {
    return (dispatch, getState) => {
        dispatch({ type: ActionTypes.ADD_TO_COMPARE, payload: [] });
    }
}

export function getWishlistItems() {
    return (dispatch, getState) => {
        // const wishlistItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].wishlistItems);

        // // console.log(wishlistItemsCopy, "cartItemsCopy");

        // if (wishlistItemsCopy.length > 0) {
        //     let product_ids = "";
        //     for (let i = 0; i < wishlistItemsCopy.length; i++) {
        //         product_ids += (wishlistItemsCopy[i].id + (i < wishlistItemsCopy.length - 1 ? "," : ""));
        //     }

        //     const { categories } = getState()[header.constants.NAME];

        //     dispatch({
        //         type: ActionTypes.GET_WISHLIST_ITEMS,
        //         payload: { isRequestingForWhom: 'wishlist/items', categories },
        //         promise: APIEndpoints.getWishlistItems({ product_ids }),
        //         callback: (payload) => {
        //             // console.log(payload, "wisl")
        //             if (payload && payload.products && payload.products.length > 0) {
        //                 // for (let i = 0; i < payload.products.length; i++) {
        //                 //     for (let j = 0; j < wishlistItemsCopy.length; j++) {
        //                 //         if (payload.products[i].id === wishlistItemsCopy[j].id) {
        //                 //             payload.products[i].quantity = wishlistItemsCopy[j].quantity;
        //                 //             break;
        //                 //         }
        //                 //     }
        //                 // }
        //                 dispatch({ type: ActionTypes.GET_WISHLIST_ITEMS, payload: payload.products });
        //             }
        //         }
        //     }
        //     )
        // }
        // else {
        //     dispatch({ type: ActionTypes.GET_WISHLIST_ITEMS, payload: [] });
        // }

    }
}
