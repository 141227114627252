import React, { Component } from 'react';
import {

    SideBySideMagnifier,

} from "react-image-magnifiers";

class ImageMagnify extends Component {
    render() {
        const { imagePath } = this.props;
        return (
            <figure className="border-radius-10">


                <SideBySideMagnifier
                    className='hr-magnify-add'
                    imageSrc={imagePath}
                    imageAlt="Example"
                    alwaysInPlace={true}
                    switchSides={true}
                    fillAvailableSpace={true}
                    transitionSpeed={0.1}
                // largeImageSrc = {imagePath}
                />


            </figure>
        );
    }
}

export default ImageMagnify;