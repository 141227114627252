import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../../../../assets/images';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';
import Wishlist from './wishlist';
import Cart from './cart';
import SearchBar from '../../../common/searchBar';

class TopHeader extends Component {
    render() {
        const { userData, path, compareItemsLength, cartItemsLength, wishlistItemsLength } = this.props;
        // console.log(cartItemsLength, "userData");
        return (
            <div className="container">
                <div className="header-wrap">
                    <div className="logo logo-width-1">

                        <Link to={{
                            pathname: ProjectRoutes.home.url,
                            search: HelperFunctions.getQueryStringFromURL()
                        }}>
                            <img src={images.logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="header-right">
                        <div className="search-style-2">
                            <form>
                                <SearchBar />
                                    
                            </form>
                        </div>
                        <div className="header-action-right">
                            <div className="header-action-2">

                                <div className="header-action-icon-2">

                                    <Link to={{ pathname: ProjectRoutes.compare.url, search: HelperFunctions.getQueryStringFromURL() }}
                                        className={`${path === ProjectRoutes.compare.url ? "active" : ""}`}>
                                        <img className="svgInject" src={images.compareIcon} />
                                        <span className="pro-count blue">{compareItemsLength}</span>
                                    </Link>

                                    <Link to={{ pathname: ProjectRoutes.compare.url, search: HelperFunctions.getQueryStringFromURL() }}
                                        className={`${path === ProjectRoutes.compare.url ? "active" : ""}`}>
                                        <span className="lable ml-0">Compare</span>
                                    </Link>
                                </div>

                                <Wishlist wishlistItemsLength={wishlistItemsLength} />

                                <Cart cartItemsLength={cartItemsLength} />
                                <div className="header-action-icon-2">

                                    <Link to={{ pathname: ProjectRoutes.user.url, search: HelperFunctions.getQueryStringFromURL() }}
                                        className={`${path === ProjectRoutes.user.url ? "active" : ""}`}>
                                        <img className="svgInject" src={images.userIcon} />
                                    </Link>

                                    <Link to={{ pathname: ProjectRoutes.user.url, search: HelperFunctions.getQueryStringFromURL() }}
                                        className={`${path === ProjectRoutes.user.url ? "active" : ""}`}>
                                        <span className="lable ml-0">
                                            {
                                                userData ? "Account" : "Login"
                                            }
                                        </span>
                                    </Link>
                                    {/* {
                                        userData && <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                            <ul>
                                                <li>
                                                    <Link to={{ pathname: ProjectRoutes.user.account.url, search: HelperFunctions.getQueryStringFromURL() }}
                                                        className={`${path === ProjectRoutes.user.account.url ? "active" : ""}`}>
                                                        <i className="fi fi-rs-user mr-10"></i>My Account
                                                    </Link>
                                                </li>
                                                <li>
                                                    <a href="page-account.html"><i className="fi fi-rs-location-alt mr-10"></i>Order Tracking</a>
                                                </li>
                                                <li>
                                                    <a href="page-account.html"><i className="fi fi-rs-label mr-10"></i>My Voucher</a>
                                                </li>
                                                <li>
                                                    <a href="shop-wishlist.html"><i className="fi fi-rs-heart mr-10"></i>My Wishlist</a>
                                                </li>
                                                <li>
                                                    <a href="page-account.html"><i className="fi fi-rs-settings-sliders mr-10"></i>Setting</a>
                                                </li>
                                                <li>
                                                    <a href="page-login.html"><i className="fi fi-rs-sign-out mr-10"></i>Sign out</a>
                                                </li>
                                            </ul>
                                        </div>
                                    } */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopHeader;