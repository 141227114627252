import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../../../../assets/images';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';

class Navigation extends Component {
    render() {
        const { categories, path } = this.props;
        // console.log(categories, "categories");
        return (
            <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                <nav>
                    <ul>
                        {/* <li className="hot-deals"><img src={images.hotDealsIcon} alt="hot deals" /><a href="shop-grid-right.html">Hot Deals</a></li> */}
                        <li></li>
                        <li>
                            <Link to={{
                                pathname: ProjectRoutes.home.url,
                                search: HelperFunctions.getQueryStringFromURL()
                            }} className={`${path === ProjectRoutes.home.url ? "active" : ""}`} aria-current="page">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to={{
                                pathname: ProjectRoutes.aboutUs.url,
                                search: HelperFunctions.getQueryStringFromURL()
                            }} className={`${path === ProjectRoutes.aboutUs.url ? "active" : ""}`} aria-current="page">
                                About us
                            </Link>
                        </li>
                        {
                            categories && categories.length > 0 && <li className="position-static">
                                <a>Categories <i className="fi-rs-angle-down"></i></a>
                                <ul className="mega-menu">
                                    {
                                        categories.map((cat, catIndex) => {
                                            return (
                                                <li key={catIndex} className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to={{
                                                        pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(cat.name),
                                                        // search: HelperFunctions.getQueryStringFromURL(),
                                                        key: cat.name,
                                                        state: { category_id: cat.id }
                                                    }} className={`menu-title ${path === ProjectRoutes.aboutUs.url ? "active" : ""}`} aria-current="page">
                                                        {cat.name}
                                                    </Link>
                                                    {
                                                        cat.product_types && cat.product_types.length > 0 && <ul>
                                                            {
                                                                cat.product_types.map((prodType, prodIndex) => {
                                                                    return (
                                                                        <li key={prodIndex}>
                                                                            <Link to={{
                                                                                pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(cat.name) + "/" + HelperFunctions.replaceSpace(prodType.name),
                                                                                search: HelperFunctions.getQueryStringFromURL(),
                                                                                key: cat.name,
                                                                                state: { category_id: cat.id, product_id: prodType.id }
                                                                            }} className={`${path === ProjectRoutes.aboutUs.url ? "active" : ""}`} aria-current="page">
                                                                                {prodType.name}
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                    {/* <li className="sub-mega-menu sub-mega-menu-width-34">
                                    <div className="menu-banner-wrap">
                                        <a href="shop-product-right.html"><img src="assets/imgs/banner/banner-menu.png" alt="Nest" /></a>
                                        <div className="menu-banner-content">
                                            <h4>Hot deals</h4>
                                            <h3>
                                                Don't miss<br />
                                                Trending
                                            </h3>
                                            <div className="menu-banner-price">
                                                <span className="new-price text-success">Save to 50%</span>
                                            </div>
                                            <div className="menu-banner-btn">
                                                <a href="shop-product-right.html">Shop now</a>
                                            </div>
                                        </div>
                                        <div className="menu-banner-discount">
                                            <h3>
                                                <span>25%</span>
                                                off
                                            </h3>
                                        </div>
                                    </div>
                                </li> */}
                                </ul>
                            </li>
                        }


                        <li>
                            <Link to={{
                                pathname: ProjectRoutes.contactUs.url,
                                search: HelperFunctions.getQueryStringFromURL()
                            }} className={`${path === ProjectRoutes.contactUs.url ? "active" : ""}`} aria-current="page">
                                Contact us
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Navigation;