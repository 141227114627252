import {NAME} from "./constants";

export const ADD_TO_CART = `${NAME}/ADD_TO_CART`;
export const ADD_TO_USER_CART = `${NAME}/ADD_TO_USER_CART`;
export const REMOVE_FROM_USER_CART = `${NAME}/REMOVE_FROM_USER_CART`;
export const DELETE_USER_CART = `${NAME}/DELETE_USER_CART`;
export const UPDATE_USER_CART = `${NAME}/UPDATE_USER_CART`;
export const GET_CART_ITEMS = `${NAME}/GET_CART_ITEMS`;
export const COMPLETE_ORDER = `${NAME}/COMPLETE_ORDER`;
export const ADD_ORDER = `${NAME}/ADD_ORDER`;
export const PAYMENT_LOADER = `${NAME}/PAYMENT_LOADER`;
export const ACTIVE_PRODCUT_CART_ID = `${NAME}/ACTIVE_PRODCUT_CART_ID`;