import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from "../../../assets/images";
import ProjectRoutes from '../../../config/routes/projectRoutes';
import HelperFunctions from '../../../utils/HelperFunctions';

class ErrorContainer extends Component {

    redirectToHome = () => {
        let redirectURL = window.location.origin + HelperFunctions.getQueryStringFromURL();
        window.location.href = redirectURL;
    }

    render() {
        return (

            <main className="main page-404">
                <div className="page-content pt-150 pb-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
                                {/* <p className="mb-20"><img src="assets/imgs/page/page-404.png" alt="" className="hover-up" /></p> */}
                                <h1 className="display-2 mb-30">Page Not Found</h1>
                                <p className="font-lg text-grey-700 mb-30">
                                    The link you clicked may be broken or the page may have been removed.<br />
                                    {/* visit the <a href="index.html"> <span> Homepage</span></a> or <a href="page-contact.html"><span>Contact us</span></a> about the problem */}
                                </p>
                                {/* <div className="search-form">
                                    <form action="#">
                                        <input type="text" placeholder="Search…" />
                                        <button type="submit"><i className="fi-rs-search"></i></button>
                                    </form>
                                </div> */}
                               

                                <Link to={{
                                    pathname: ProjectRoutes.home.url,
                                    search: HelperFunctions.getQueryStringFromURL()
                                }} className={`btn btn-default submit-auto-width font-xs hover-up mt-30`} aria-current="page">
                                    <i className="fi-rs-home mr-5"></i> Back To Home Page
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>


        );
    }
}

export default ErrorContainer;