import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../../../assets/images';
import ProjectRoutes from '../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../utils/HelperFunctions';

class NewProducts extends Component {
    render() {
        const { products } = this.props;
        let count = 0;
        // console.log(products, "products");
        return products && products.products.length > 0 ? (
            <div className="sidebar-widget product-sidebar mb-30 p-30 bg-grey border-radius-10">
                <h5 className="section-title style-1 mb-30">New products</h5>
                {
                    products.products.map((product, index) => {
                        count++;
                        return product.is_new && count < 5 && (
                            <div className="single-post clearfix" key={index}>
                                <div className="image">
                                    <img src={product.base_url + product.image_url1} />
                                </div>
                                <div className="content pt-10">
                                    <h5>
                                        <Link to={{
                                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                                            search: HelperFunctions.getQueryStringFromURL(),
                                            state: { product: product }
                                        }} > {product.name}</Link>

                                    </h5>
                                    <p className="price mb-0 mt-5">{'₹' + parseInt(product.rent_per_product - product.rent_per_product * product.discount / 100)}</p>
                                    <div className="product-rate">
                                        <div className="product-rating" style={{ width: "90%" }}></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        ) : null;
    }
}

export default NewProducts;