import React from 'react';
import DateHelper from '../../../../utils/DateHelper';
import HelperFunctions from '../../../../utils/HelperFunctions';
// import HelperFunction from "../../../../../utils/HelperFunctions";

class TimeCounter extends React.Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     timerValue: this.props.timerValue
        // }

        this.state = {
            timerValue: DateHelper.calculateMatchDateTimeDifferenceFromCurrent(DateHelper.getDateFromStringMoment(this.props.timerValue.date))
        }
    }

    // componentDidMount() {
    //     console.log(this.state.timerValue);
    //         this.timer = setInterval(() => {

    //             //convertToDateTime
    //             let timerValueCopy = HelperFunctions.copyObject(this.state.timerValue);
    //             timerValueCopy.milliSeconds -= 1000;
    //             if (timerValueCopy.milliSeconds >= 0) {
    //                 this.setState({
    //                     timerValue: timerValueCopy
    //                 })
    //             } else {
    //                 if (this.timer) {
    //                     clearInterval(this.timer);
    //                 }
    //                 this.props.onComplete();
    //             }
    //         }, 1000)
    // }


    componentDidMount() {
        if (!this.state.timerValue.isLive) {
            this.timer = setInterval(() => {
                let timerValueCopy = DateHelper.calculateMatchDateTimeDifferenceFromCurrent(DateHelper.getDateFromStringMoment(this.props.timerValue.date));
                if (timerValueCopy.isLive) {
                    if (this.timer) {
                        clearInterval(this.timer);
                    }
                    this.props.onComplete();
                } else {
                    this.setState({
                        timerValue: timerValueCopy
                    })
                }
            }, 1000)
        } else {
            this.props.onComplete();
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    render() {

        const timeToShow = HelperFunctions.returnTime(this.state.timerValue);
        const { timerText, isSimpleCounter } = this.props;
        return (
            <div className="deals-countdown">
                {
                    timeToShow.dayToShow !== '00' && <span className="countdown-section">
                        <span className="countdown-amount hover-up">{(Number.isNaN(timeToShow.dayToShow)) ? "00" : timeToShow.dayToShow}</span>
                        <span className="countdown-period"> days </span>
                    </span>
                }

                <span className="countdown-section">
                    <span className="countdown-amount hover-up">{(Number.isNaN(timeToShow.hourToShow)) ? "00" : timeToShow.hourToShow}</span>
                    <span className="countdown-period"> hrs </span>
                </span>

                <span className="countdown-section">
                    <span className="countdown-amount hover-up">{(Number.isNaN(timeToShow.minuteToShow)) ? "00" : timeToShow.minuteToShow}</span>
                    <span className="countdown-period"> mins </span>
                </span>

                <span className="countdown-section">
                    <span className="countdown-amount hover-up">{(Number.isNaN(timeToShow.secondToShow)) ? "00" : timeToShow.secondToShow}</span>
                    <span className="countdown-period"> mins </span>
                </span>
            </div>
        )

    }
}

export default TimeCounter;
