import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../assets/images';
import ProjectRoutes from '../../config/routes/projectRoutes';
import HelperFunctions from '../../utils/HelperFunctions';

class MessageContainer extends Component {
    render() {
        const { msg, icon } = this.props;
        return (
            <div className="text-center  animated slideInUpLess">
                <div className="no-found-item no-item-card">
                    <div className="container">
                        <div className="found-item-inner">
                            {
                                icon && <div className="no-fount-icon">
                                    <figure className="no-icon-figure">
                                        <img src={icon} />
                                    </figure>
                                </div>
                            }
                            <h2 className="no-item-title">{msg}</h2>
                            <div className="no-fount-action">
                                <Link to={{
                                    pathname: ProjectRoutes.home.url,
                                    search: HelperFunctions.getQueryStringFromURL()
                                }} className={`btn btn-primary`} aria-current="page">
                                    <i className="fas fa-shopping-bag me-2"></i>Continue shopping
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MessageContainer;