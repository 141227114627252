import React from "react";
import Invoice from "./index";

class InvoiceButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        return (
            <>
            <button onClick={this.toggle} className="btn btn-outline-dark btn-sm rounded-sm ms-1 px-2">
                Invoice
            </button>
            {/*<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
             <Invoice toggle={this.toggle} order={this.props.order}/>
             </Modal>*/}
            {this.state.modal && <div style={{position: 'absolute', display:'none'}}>
                <Invoice toggle={this.toggle} order={this.props.order}/>
            </div>
            }
            </>
        );
    }
}

export default InvoiceButton;
