const commonStyles = {
    widthNinty: {
        width: "90%"
    },
    widthEighty: {
        width: "80%"
    },
    displayNone : {
        display : "none"
    }
};

export default commonStyles;