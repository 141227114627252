import React from "react";
import {Link} from "react-router-dom";
import ContentLoader from "../../../../../commons/components/contentLoader";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class VerifyEmail extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const otp = HelperFunctions.getQueryStringValue('otp');
        const email = HelperFunctions.getQueryStringValue('email');

        if (HelperFunctions.isNotEmpty(otp) && HelperFunctions.isNotEmpty(email)) {
            this.props.matchEmailVerificationOTP(email, otp, this.props.history);
        } else {
            this.props.history.replace({
                pathname: ProjectRoutes.home.url,
                search: HelperFunctions.getQueryStringFromURL(),
            });
        }
    }

    render() {
        const {matchOTPData} = this.props;

        console.log(matchOTPData);

        if (matchOTPData) {
            const {statusCode} = matchOTPData;
            let txt = "", icon = "",iconColor="";
            if (statusCode) {
                if (statusCode === 200) {
                    txt = "Congratulations!! Your email has been verified.";
                    icon = "#checkmark-1";
                    iconColor="text-success";
                } else {
                    txt = "Sorry, we couldn't verify your email. Something went wrong. Please try again with new verification link.";
                    icon = "#deadline-1";
                    iconColor="text-danger";
                }
            }
            return (
                <React.Fragment>
                    <div className="bg-light py-2 animated slideInLeftLess">
                        <div className="container">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 bg-light">
                                    <li className="breadcrumb-item">
                                        <Link to={{pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL()}} className="reset-anchor">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Verify Email</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <section className="animated slideInUpLess">
                        <div className="container py-5">
                            <div className="text-center">
                                <svg className={`svg-icon ${iconColor} svg-icon-xl svg-icon-light mb-4`}>
                                    <use href={icon}></use>
                                </svg>
                                <h1>Email Verification</h1>
                                <div className="row">
                                    <div className="col-lg-7 mx-auto">
                                        <p className="text-muted mb-5">{txt}</p>
                                    </div>
                                </div>
                                <Link to={{pathname: ProjectRoutes.home.url}} className="btn btn-primary">
                                    <i className="fas fa-shopping-bag me-2"></i>
                                    Continue shopping
                                </Link>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
        else {
            return (
                <ContentLoader/>
            );
        }
    }
}
