import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../../../../assets/images';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';

class Wishlist extends Component {
    render() {
        const { path, wishlistItemsLength } = this.props;
        return (
            <div className="header-action-icon-2">
                <Link to={{ pathname: ProjectRoutes.wishlist.url, search: HelperFunctions.getQueryStringFromURL() }}
                    className={`${path === ProjectRoutes.wishlist.url ? "active" : ""}`}>
                    <img alt="Nest" src={images.wishlistIcon} />
                    <span className="pro-count blue">{wishlistItemsLength}</span>
                </Link>
                <Link to={{ pathname: ProjectRoutes.wishlist.url, search: HelperFunctions.getQueryStringFromURL() }}
                    className={`${path === ProjectRoutes.wishlist.url ? "active" : ""}`}>
                    <span className="lable">Wishlist</span>
                </Link>
            </div>
        );
    }
}

export default Wishlist;