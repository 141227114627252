import React from "react";
import ContentLoader from "../../../../../commons/components/contentLoader";
import ImagePreload from "../../../../../commons/components/imagePreload/imagePreload";
import SimpleMap from "./SimpleMap";
import Constants from "../../../../../utils/Constants";
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';
import { Link } from "react-router-dom";
import images from "../../../../../assets/images";


export default class Legal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isImagesLoaded: false };

        this.sliderImgsArr = ["/img/updated/bg-about-us.jpg"];
    }

    disableLoader = () => {
        this.setState({ isImagesLoaded: true });
    };

    render() {
        const { isImagesLoaded } = this.state;
        return (
            <React.Fragment>

                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <i className="fi-rs-home mr-5"></i>
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                    Home
                                </Link>
                                <span></span> Pages <span></span> Legal
                            </div>
                        </div>
                    </div>


                    <div className="page-header mt-30 mb-75">

                    </div>
                    <div className="page-content mb-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="loop-grid pr-30">
                                        <div className="row">
                                            <article className="col-xl-4 col-lg-6 col-md-6 text-center hover-up mb-30 animated">
                                                <div className="post-thumb">
                                                    <a>
                                                        <img className="border-radius-15" src={images.gst} alt="" />
                                                    </a>
                                                    {/* <div className="entry-meta">
                                                        <a className="entry-meta meta-2" href="blog-category-grid.html"><i className="fi-rs-heart"></i></a>
                                                    </div> */}
                                                </div>
                                            </article>
                                            <article className="col-xl-4 col-lg-6 col-md-6 text-center hover-up mb-30 animated">
                                                <div className="post-thumb">
                                                    <a>
                                                        <img className="border-radius-15" src={images.incorporation} alt="" />
                                                    </a>
                                                    {/* <div className="entry-meta">
                                                        <a className="entry-meta meta-2" href="blog-category-grid.html"><i className="fi-rs-heart"></i></a>
                                                    </div> */}
                                                </div>
                                            </article>
                                            <article className="col-xl-4 col-lg-6 col-md-6 text-center hover-up mb-30 animated">
                                                <div className="post-thumb">
                                                    <a>
                                                        <img className="border-radius-15" src={images.iecImg} alt="" />
                                                    </a>
                                                    {/* <div className="entry-meta">
                                                        <a className="entry-meta meta-2" href="blog-category-grid.html"><i className="fi-rs-heart"></i></a>
                                                    </div> */}
                                                </div>
                                            </article>

                                            <article className="col-xl-4 col-lg-6 col-md-6 text-center hover-up mb-30 animated">
                                                <div className="post-thumb">
                                                    <a>
                                                        <img className="border-radius-15" src={images.qualityResearch} alt="" />
                                                    </a>
                                                    {/* <div className="entry-meta">
                                                        <a className="entry-meta meta-2" href="blog-category-grid.html"><i className="fi-rs-heart"></i></a>
                                                    </div> */}
                                                </div>
                                            </article>

                                            <article className="col-xl-4 col-lg-6 col-md-6 text-center hover-up mb-30 animated">
                                                <div className="post-thumb">
                                                    <a>
                                                        <img className="border-radius-15" src={images.fssai} alt="" />
                                                    </a>
                                                    {/* <div className="entry-meta">
                                                        <a className="entry-meta meta-2" href="blog-category-grid.html"><i className="fi-rs-heart"></i></a>
                                                    </div> */}
                                                </div>
                                            </article>

                                            <article className="col-xl-4 col-lg-6 col-md-6 text-center hover-up mb-30 animated">
                                                <div className="post-thumb">
                                                    <a>
                                                        <img className="border-radius-15" src={images.udyam} alt="" />
                                                    </a>
                                                    {/* <div className="entry-meta">
                                                        <a className="entry-meta meta-2" href="blog-category-grid.html"><i className="fi-rs-heart"></i></a>
                                                    </div> */}
                                                </div>
                                            </article>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </main>


            </React.Fragment>
        );
    }
}
