import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../../../../assets/images';
import ContentLoader from '../../../../../commons/components/contentLoader';
import MessageContainer from '../../../../../commons/components/messageContainer';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import Constants from '../../../../../utils/Constants';
import HelperFunctions from '../../../../../utils/HelperFunctions';

class Compare extends Component {

    constructor(props) {
        super(props);
        this.currentProductForCart = null;
    }

    remove = (itemIndex) => {
        this.props.removeFromCompare(itemIndex);
    };

    goToCart = (product, itemIndex) => {
        // add the product To the cart
        if (this.props.isRequestingForWhom)
            return;

        this.currentProductForCart = product.id;
        this.props.addToCart(product, 1);
        // this.remove(itemIndex);
    };

    render() {
        const { compareItems, isRequestingForWhom } = this.props;
        // console.log(this.currentProductForCart, "isRequestingForWhom co")
        // const productOne = compareItems && compareItems[0] ? compareItems[0] : null;
        // const productTwo = compareItems && compareItems[1] ? compareItems[1] : null;
        return (
            <main className="main">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="reset-anchor">
                                <i className="fi-rs-home mr-5"></i>Home</Link>
                            <span></span> Compare
                            {/* <span></span> Cart */}
                        </div>
                    </div>
                </div>

                {
                    compareItems.length > 0 ? <div className="container mb-80 mt-50">
                        <div className="row">
                            <div className="col-xl-10 col-lg-12 m-auto">
                                <h1 className="heading-2 mb-10">Products Compare</h1>
                                {/* <h6 className="text-body mb-40">There are <span className="text-brand">3</span> products to compare</h6> */}
                                <div className="table-responsive">
                                    <table className="table text-center table-compare">
                                        <tbody>
                                            <tr className="pr_image">
                                                <td className="text-muted font-sm fw-600 font-heading mw-200">Preview</td>
                                                {
                                                    compareItems.map((itemImg, imgIndex) => {
                                                        return (
                                                            <td key={imgIndex} className="row_img"><img src={itemImg.base_url + itemImg.image_url1} alt="compare-img" /></td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr className="pr_title">
                                                <td className="text-muted font-sm fw-600 font-heading">Name</td>
                                                {
                                                    compareItems.map((item, index) => {
                                                        return (
                                                            <td key={index} className="product_name">
                                                                <h6>

                                                                    <Link
                                                                        className="text-heading"
                                                                        to={{
                                                                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(item.name) + '/' + item.id,
                                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                                            state: { product: item }
                                                                        }}>
                                                                        {item.name}
                                                                    </Link>
                                                                </h6>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr className="pr_price">
                                                <td className="text-muted font-sm fw-600 font-heading">Price</td>
                                                {
                                                    compareItems.map((item, index) => {
                                                        return (
                                                            <td key={index} className="product_price">
                                                                <h4 className="price text-brand">{HelperFunctions.calculateFinalPrice(item.rent_per_product, item.discount)}</h4>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr className="pr_rating">
                                                <td className="text-muted font-sm fw-600 font-heading">Rating</td>
                                                <td>
                                                    <div className="rating_wrap">
                                                        <div className="product-rate d-inline-block">
                                                            <div className="product-rating" style={{ width: "90%" }}></div>
                                                        </div>
                                                        <span className="rating_num">(121)</span>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr className="description">
                                                <td className="text-muted font-sm fw-600 font-heading">Description</td>
                                                {
                                                    compareItems.map((item, index) => {
                                                        return (
                                                            <td key={index} className="row_text font-xs">
                                                                <p className="font-sm text-muted" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr className="pr_stock">
                                                <td className="text-muted font-sm fw-600 font-heading">Stock status</td>
                                                <td className="row_stock"><span className="stock-status in-stock mb-0">In Stock</span></td>
                                            </tr>

                                            <tr className="pr_add_to_cart">
                                                <td className="text-muted font-sm fw-600 font-heading">Buy now</td>
                                                {
                                                    compareItems.map((item, index) => {
                                                        return (
                                                            <td key={index} className="row_btn">
                                                                <button className={`btn btn-sm add-loader ${isRequestingForWhom === Constants.cartAddMatch && this.currentProductForCart === item.id && "disabled"}`} onClick={this.goToCart.bind(this, item)}>
                                                                    <i className="fi-rs-shopping-bag mr-5"></i>
                                                                    {
                                                                        isRequestingForWhom === Constants.cartAddMatch && this.currentProductForCart === item.id ?
                                                                            <ContentLoader loaderType="btn-loader" /> :
                                                                            "Add To Cart"
                                                                    }

                                                                </button>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr className="pr_remove text-muted">
                                                <td className="text-muted font-md fw-600"></td>

                                                {
                                                    compareItems.map((item, index) => {
                                                        return (
                                                            <td className="row_remove">
                                                                <a onClick={this.remove.bind(this, index)} className="text-muted"><i className="fi-rs-trash mr-5"></i><span>Remove</span> </a>
                                                            </td>
                                                        )
                                                    })
                                                }


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> : <MessageContainer icon = {images.compareIcon} msg="No Products to compare" />
                }



            </main>

        );
    }
}

export default Compare;