import React, { PureComponent } from 'react';
import { Slide, toast } from "react-toastify/dist/index";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class ProfileUpdate extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: this.props.userData ? this.props.userData.name : '',
                email: this.props.userData ? this.props.userData.email : '',
                mobile: this.props.userData ? this.props.userData.mobile : '',
                old_password: '',
                password: '',
                cpassword: ''
            },
        };
    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    updateUser = (e) => {
        e.preventDefault();
        if (this.props.isRequestingForWhom !== 'user/update')
            this.props.updateUser(this.state.data.name, this.state.data.email);
    };

    verifyEmail = (e) => {
        e.preventDefault();
        const isRequestingEmail = this.props.isRequestingForWhom === "user/verify-email";
        if (isRequestingEmail)
            return;
        this.props.verifyEmail(this.state.data.email);
    };

    isEnabledBtn = () => {
        return (
            HelperFunctions.validateName(this.state.data.name) &&
            HelperFunctions.validateEmail(this.state.data.email) &&
            HelperFunctions.validateMobile(this.state.data.mobile)
        )
    };

    isPassEnabledBtn = () => {
        const { old_password, password, cpassword } = this.state.data;
        return (HelperFunctions.validatePass(old_password) && HelperFunctions.validatePass(password) && HelperFunctions.validatePass(cpassword) && password === cpassword);
    };

    newPassword = (e) => {
        e.preventDefault();

        const { old_password, password, cpassword, mobile } = this.state.data;

        if (password === cpassword) {
            const isRequesting = this.props.isRequestingForWhom === "user/update";
            if (!isRequesting) {
                this.props.changePassword(mobile, old_password, password);
            }
        }
        else {
            this.toastId = toast("Password and confirm pass do not match.", {
                transition: Slide,
                closeButton: true,
                autoClose: 2000,
                position: toast.POSITION.TOP_CENTER,
                type: 'error',
                hideProgressBar: true,
            })
        }
    };

    isEmailEnabledBtn = () => {
        return !this.props.userData.is_email_verified && (this.props.userData.email === this.state.data.email) && HelperFunctions.validateEmail(this.state.data.email);
    };
    isEmailEnabledTxt = () => {
        return this.props.userData.is_email_verified && (this.props.userData.email === this.state.data.email) && HelperFunctions.validateEmail(this.state.data.email);
    };

    render() {

        const { data } = this.state;
        const { userData, isRequestingForWhom, newPasswordData } = this.props;

        const isRequestingEmail = isRequestingForWhom === "user/verify-email";
        const isRequesting = isRequestingForWhom === "user/update";
        const isRequestingPass = isRequestingForWhom === "user/new-password";

        let isInvalidMob = null, isInvalidEmail = null, isInvalidPass = null, isInvalidOldPass = null, isInvalidName = null;

        if (userData && userData.errors) {
            if (userData.errors.email) {
                isInvalidEmail = userData.errors.email;
            }
            if (userData.errors.mobile) {
                isInvalidMob = userData.errors.mobile;
            }
            if (userData.errors.name) {
                isInvalidName = userData.errors.name;
            }
        }

        if (newPasswordData && newPasswordData.errors) {
            if (newPasswordData.errors.password) {
                isInvalidPass = newPasswordData.errors.password;
            }
            if (newPasswordData.errors.old_password) {
                isInvalidOldPass = newPasswordData.errors.old_password;
            }
        }

        return (
            <div className="card">
                <div className="card-header">
                    <h5>Account Details</h5>
                </div>
                <div className="card-body">
                    <div className="hr-account-info-card hr-account-box-wrap">

                        <div className="col-lg-6">
                            {/* <form method="post" name="enq"> */}
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label>Full Name <span className="required">*</span></label>
                                    <input
                                        value={data.name}
                                        placeholder='Enter your full name'
                                        onChange={this.onInputChange.bind(this, "name")}
                                        className={`form-control ${isInvalidName && 'is-invalid'}`}
                                        name="name"
                                        type="text" />
                                    <div className="invalid-feedback">{isInvalidName}</div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Email Address
                                        {this.isEmailEnabledBtn() && (
                                            <a className={`btn btn-success btn-sm rounded mx-1 hr-eamil-action`} onClick={this.verifyEmail}>
                                                {isRequestingEmail && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                Verify Email
                                            </a>
                                        )}
                                        {this.isEmailEnabledTxt() && (
                                            <span className="small text-success mx-2 text-lowercase fw-bold rounded">verified</span>
                                        )}
                                    </label>
                                    <input
                                        value={data.email}
                                        placeholder="Enter your email"
                                        className={`form-control ${isInvalidEmail && 'is-invalid'}`}
                                        name="email"
                                        onChange={this.onInputChange.bind(this, "email")}
                                        type="email" />
                                    <div className="invalid-feedback">{isInvalidEmail}</div>
                                </div>

                                <div className="form-group col-md-12">
                                    <label>Mobile <span className="required">*</span></label>
                                    <input disabled className={`form-control ${isInvalidMob && 'is-invalid'}`} placeholder="Enter your mobile" type="text" name="mobile" id="mobile"
                                        value={data["mobile"]} />
                                    <div className="invalid-feedback">{isInvalidMob}</div>
                                </div>

                                <div className="form-group col-md-12 hr-login-with">
                                    <a onClick={this.updateUser} className={`btn btn-primary ${this.isEnabledBtn() ? '' : 'disabled'}`} type=" submit">
                                        {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                        Update Profile
                                    </a>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>

                    <div className="hr-account-info-card hr-pass-change-box">
                        <div className="col-lg-6">
                            {/* <form method="post" name="enq"> */}
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label>Current Password <span className="required">*</span></label>
                                    <input className={`form-control ${isInvalidOldPass && 'is-invalid'}`}
                                        type="password"
                                        placeholder="Current password" value={data["old_password"]} onChange={this.onInputChange.bind(this, "old_password")} />
                                    <div className=" invalid-feedback">{isInvalidOldPass}</div>
                                </div>
                                <div className="form-group col-lg-6 mb-3">
                                    <label className="form-label">New Password</label>
                                    <input className={`form-control ${isInvalidPass && 'is-invalid'}`}
                                        type="password"
                                        placeholder="New password" value={data["password"]} onChange={this.onInputChange.bind(this, "password")} />
                                    <div className=" invalid-feedback">{isInvalidPass}</div>
                                </div>
                                <div className="form-group col-lg-6 mb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <input className={`form-control ${isInvalidPass && 'is-invalid'}`}
                                        type="password"
                                        placeholder="Confirm Password" value={data["cpassword"]} onChange={this.onInputChange.bind(this, "cpassword")} />
                                    <div className=" invalid-feedback">{isInvalidPass}</div>
                                </div>
                                <div className="form-group col-md-12 hr-login-with">
                                    <a onClick={this.newPassword} className={`btn btn-primary ${this.isPassEnabledBtn() ? '' : 'disabled'}`}>
                                        {isRequestingPass && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                        Update Password
                                    </a>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>

                </div>
            </div>
        );
    }

}