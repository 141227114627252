import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';

class ComparePopup extends Component {
    state = {
        hideCompare: false
    }

    hideBox = () => {
        this.setState({
            hideCompare: true
        })
    }


    componentWillReceiveProps(nextProps, nextState) {
    
        if (this.props.compareItems !== nextProps.compareItems) {
            this.setState({
                hideCompare: false
            })
        }
    }

    render() {
        const { compareItems, path } = this.props;
        const { hideCompare } = this.state;
        return (
            <React.Fragment>
                <div className={`hr-compare-wrap ${compareItems.length > 0 && path !== ProjectRoutes.compare.url && !hideCompare ? "show" : ""}`}>
                    <a onClick={this.hideBox} className="cross-item hr-hide-modal"><i className="fi-rs-angle-small-down"></i></a>
                    <div className="hr-compare-bar">
                        <div className="hr-compare-item-bar">
                            {
                                compareItems.length > 0 && compareItems.map((item, index) => {
                                    return (
                                        <div key={index} className="hr-compare-items">
                                            <div className="hr-product-item">
                                                <figure className="hr-item-figure">
                                                    <img className="default-img" src={item.base_url + item.image_url1} alt="" />
                                                </figure>
                                                <a onClick={this.props.removeFromCompare.bind(this, index)} className="cross-item hr-hide-item"><i className="fi-rs-cross"></i></a>
                                            </div>
                                            <div className="hr-item-name"><span>{item.name}</span></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="hr-compare-btn">
                            <Link to={{ pathname: ProjectRoutes.compare.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                <button className="btn btn-sm">Compare Now</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ComparePopup;