import { cleanData } from 'jquery';
import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import common from '../../common';
import {FooterContainer} from "../footer";
import {HeaderContainer} from "../header";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import Info from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class InfoSmart extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="main-section login-wrap">
                    <div className="page-container">
                        <HeaderContainer path={this.props.match.path} historyProps={this.props.history}/>
                        <Info {...this.props}/>
                        <FooterContainer showFooterContent = {true} path={this.props.match.path}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        submitContactUsFrom: (data) => dispatch(actions.submitContactUsFrom(data)),
    }
};

export const InfoContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoSmart));
export default {actionTypes, actions, reducer, constants};
