import React from 'react';
import { Link } from 'react-router-dom';
import ContentLoader from "../../../../../commons/components/contentLoader";
import ImagePreload from "../../../../../commons/components/imagePreload/imagePreload";
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';

export default class TermsConditions extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isImagesLoaded: false };

        this.sliderImgsArr = ["/img/updated/bg-about-us.jpg"];
    }

    disableLoader = () => {
        this.setState({ isImagesLoaded: true });
    };

    render() {
        const { isImagesLoaded } = this.state;


        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <i className="fi-rs-home mr-5"></i>
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                    Home
                                </Link>
                                <span></span> Pages <span></span> Terms & Conditions
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 m-auto">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                                                <div className="single-header style-2">
                                                    <h2>Terms & Conditions</h2>
                                                </div>

                                                <div className="single-content mb-50">
                                                    <p>
                                                        Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you shall not register as a User of the “hindroot.com” website and shall not transact on or use the website. But if you wish to use the services even after being a minor, that can be done under the guidance of your parent/guardian. We reserve the right to terminate your membership and / or refuse to provide you with the services if you do not abide by the above condition.
                                                    </p>

                                                    <p>
                                                        Subject to the terms and conditions of this Agreement, we hereby grant you a limited, revocable, non-transferable and non-exclusive license to access and use “hindroot.com” website by displaying it on your internet browser only for the purpose of availing the services sold on our website and not for any commercial use or use on behalf of any third party, except as explicitly permitted by us in advance. Any breach of this Agreement shall result in the immediate revocation of the license granted in this paragraph without notice to you & valid legal action may be taken. Except as permitted in this paragraph, you may not reproduce, distribute, display, sell, lease, transmit, create derivative works from, translate, modify, reverse-engineer, disassemble, decompile or otherwise exploit this website or any portion of it unless expressly permitted by us in writing. You may not make any commercial or non-commercial use of this website or any of the information displayed on this website for the benefit of another business unless explicitly permitted by us in advance. We reserve the right to refuse service, terminate accounts, and/or cancel orders at its discretion, including, without limitation, if we consider customer conduct violates applicable law or is harmful to our interest.
                                                    </p>

                                                    <p>
                                                        Content provided on this website is solely for informational purposes and does not claim any direct medicinal benefits. Do not use/recommend the products for any medicinal suggestions. It is your sole responsibility to consult a licensed physician regarding the consumption of the snack items made available through our service. Pls. read allergen caution for each product before buying or consuming them.
                                                    </p>

                                                    <p>
                                                        Product life depends on the standard storage conditions so, kindly follow the Storage instructions given on the package.
                                                    </p>

                                                    <p>
                                                        We may assign you a password and account identification to enable you to access and use certain portions of this Site. Each time you use a password or identification, you will be deemed to be authorized to access and use the Site in a manner consistent with the terms and conditions of this Agreement, and we have no obligation to investigate the authorization or source of any such access or use of the website.
                                                    </p>

                                                    <p>
                                                        You are solely responsible for protecting the security and confidentiality of the password and identification assigned to you. You shall immediately notify us of any unauthorized use of your password or identification or any other breach or threatened breach of this website’s security.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>
        );

    }
}
