import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import home from '../../screens/home';
import ProjectRoutes from '../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../utils/HelperFunctions';
import commonStyles from '../../../../assets/styles';
import cart from '../../screens/cart';
import Wishlist from '../../screens/wishllist/components';
import common from '..';
import wishllist from '../../screens/wishllist';
import ContentLoader from '../../../../commons/components/contentLoader';
import Constants from '../../../../utils/Constants';
import header from '../../screens/header';
import compare from '../../screens/compare';
import ProductCard from '../../../../commons/components/productCard/productCard';


function SliderBtnNext(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-prev"><i className="fi-rs-arrow-small-left"></i></span>
    );
}

function SliderBtnPrev(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-next"><i className="fi-rs-arrow-small-right"></i></span>
    );
}


class FeaturedProduct extends Component {

    constructor(props) {
        super(props);
        this.currentProductForCart = null;
       
    }

    render() {
        var appendArrowsClassName = "#carausel-4-columns-3-arrows";
        
    
        const settings = {
            dots: false,
            infinite: true,
            speed: 250,
            arrows: true,
            autoplay: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            cssEase: "linear",
            autoplaySpeed: 4500,
            loop: true,
            adaptiveHeight: true,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ],
            prevArrow: <SliderBtnNext />,
            nextArrow: <SliderBtnPrev />,
            appendArrows: appendArrowsClassName
        };

        const { featuredProducts, isRequestingForWhom, wishlistItems } = this.props;
        // console.log(featuredProducts, "isRequestingForWhom");
        return featuredProducts && featuredProducts.data.length > 0 && (
            <section className="section-padding pb-5 featured-product-slider">
                <div className="container">
                    <div className="section-title wow animate__animated animate__fadeIn">
                        <h3 className="">{featuredProducts.name}</h3>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 wow animate__animated animate__fadeIn" data-wow-delay=".4s">
                            <div className="tab-content" id="myTabContent-1">
                                <div className="tab-pane fade show active" id="tab-one-1" role="tabpanel" aria-labelledby="tab-one-1">
                                    <div className="carausel-4-columns-cover arrow-center position-relative">
                                        <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow" id="carausel-4-columns-arrows"></div>
                                        <Slider className="carausel-4-columns carausel-arrow-center featuredprod" id="carausel-10-columns" {...settings}>

                                            {
                                                featuredProducts.data.map((product, index) => {
                                                    return (
                                                        <ProductCard
                                                            wishlistItems={wishlistItems}
                                                            product={product}
                                                            index={index}
                                                            key={index}
                                                            cartProdcutId={this.props.cartProdcutId}
                                                            addToCart={this.props.addToCart}
                                                            showProductModal={this.props.showProductModal}
                                                            addToWishlist={this.props.addToWishlist}
                                                            addToCompare={this.props.addToCompare}
                                                            isRequestingForCart={this.props.isRequestingForWhom === Constants.cartAddMatch}
                                                        />
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        featuredProducts: state[home.constants.NAME].featuredProducts,
        isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
        cartProdcutId: state[cart.constants.NAME].cartProdcutId,
        wishlistItems: state[wishllist.constants.NAME].wishlistItems,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showProductModal: (product) => dispatch(header.actions.showProductModal(product)),
        addToCart: (modelProduct, quantity) => dispatch(cart.actions.addToCart(modelProduct, quantity)),
        addToWishlist: (modelProduct, removedItemIndex) => dispatch(wishllist.actions.addToWishlist(modelProduct, removedItemIndex)),
        addToCompare: (modelProduct) => dispatch(compare.actions.addToCompare(modelProduct))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeaturedProduct));