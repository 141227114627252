import * as ActionTypes from "./actionTypes";

const initialState = {
    isUserLoggedOut: false,
    categories: [],
    shopProducts : null,
    isLoading : false,
    isLoadMore : false,
    relatedProducts : null
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT_TYPE:
            return { ...state, categories: action.payload };

        case ActionTypes.GET_SHOP_PRODUCTS:
            return { ...state, shopProducts: action.payload };

        case ActionTypes.GET_SHOP_PRODUCTS_LOADING:
            return { ...state, isLoading: action.payload };

        case ActionTypes.PRODUCTS_LOAD_MORE:
            return { ...state, isLoadMore: action.payload };
            
        // case ActionTypes.GET_PRODUCT:
        //     return {...state, ...action.payload};
        // case ActionTypes.GET_NEW_PRODUCT:
        //     return {...state, newProducts: action.payload.products};
        // case ActionTypes.GET_TREND_PRODUCT:
        //     return {...state, trendProducts: action.payload.products};
        case ActionTypes.GET_SHOP_RELATED_PRODUCTS:
            return {...state, relatedProducts: action.payload.products};
        // case ActionTypes.GET_SEARCH_PRODUCT:
        //     return {...state, searchProducts: action.payload.products};
        default:
            return { ...state }
    }
};
export default reducer;