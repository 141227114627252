import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import home from '../../screens/home';
import ProjectRoutes from '../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../utils/HelperFunctions';

function SliderBtnNext(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-prev"><i className="fi-rs-arrow-small-left"></i></span>
    );
}

function SliderBtnPrev(props) {
    const { onClick } = props;
    return (
        <span onClick={onClick} className="slider-btn slider-next"><i className="fi-rs-arrow-small-right"></i></span>
    );
}

class ShopByCategory extends Component {
    render() {
        const { shopByCategoryData } = this.props;

        const settings = {
            dots: false,
            infinite: shopByCategoryData && shopByCategoryData.data.length > 3,
            speed: 500,
            arrows: true,
            autoplay: true,
            slidesToShow: 8,
            slidesToScroll: 1,
            loop: false,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ],
            prevArrow: <SliderBtnNext />,
            nextArrow: <SliderBtnPrev />,
            // appendArrows: appendArrowsClassName
        }


        // console.log(shopByCategoryData, "shopByCategory")
        return shopByCategoryData && shopByCategoryData.data.length > 0 && (
            <section className="popular-categories section-padding shop-by-category-slider">
                <div className="container">
                    <div className="section-title">
                        <div className="title">
                            <h3>{shopByCategoryData.name}</h3>
                            {/* <a className="show-all" href="shop-grid-right.html">
                                All Categories
                                <i className="fi-rs-angle-right"></i>
                            </a> */}
                        </div>
                        <div className="slider-arrow slider-arrow-2 flex-right carausel-8-columns-arrow" id="carausel-8-columns-arrows"></div>
                    </div>
                    <div className="carausel-8-columns-cover position-relative">
                        <Slider className="carausel-8-columns" id="carausel-8-columns" {...settings}>
                            {
                                shopByCategoryData.data.map((item, index) => {
                                    // console.log(item, "item");
                                    return (
                                        <div key={index} className="card-1">
                                            <figure className="img-hover-scale overflow-hidden">
                                                <Link to={{
                                                    pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(item.name),
                                                    search: HelperFunctions.getQueryStringFromURL(),
                                                    key: item.name,
                                                    state: { category_id: item.id }
                                                }} aria-current="page">
                                                    <img src={item.base_url + item.image_url} alt="" />
                                                </Link>

                                            </figure>
                                            <h6>
                                                <Link to={{
                                                    pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(item.name),
                                                    search: HelperFunctions.getQueryStringFromURL(),
                                                    key: item.name,
                                                    state: { category_id: item.id }
                                                }} aria-current="page">
                                                    {item.name}
                                                </Link>

                                            </h6>
                                        </div>
                                    )

                                })
                            }
                        </Slider>

                    </div>
                </div>
            </section>

        );
    }
}


const mapStateToProps = state => {
    return {
        shopByCategoryData: state[home.constants.NAME].shopByCategoryData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // getFeaturedCategory: () => dispatch(common.actions.getFeaturedCategory()),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopByCategory));