import React, { Component } from 'react';

class TimerCounter extends Component {
    render() {
        return (
            <div className="deals-countdown">
                <span className="countdown-section">
                    <span className="countdown-amount hover-up">1597</span>
                    <span className="countdown-period"> days </span>
                </span>

                <span className="countdown-section">
                    <span className="countdown-amount hover-up">1597</span>
                    <span className="countdown-period"> days </span>
                </span>

                <span className="countdown-section">
                    <span className="countdown-amount hover-up">1597</span>
                    <span className="countdown-period"> days </span>
                </span>
            </div>
        );
    }
}

export default TimerCounter;