import {NAME} from "./constants";

export const FEATURED_CATEGORY = `${NAME}/FEATURED_CATEGORY`;
export const GET_PRODUCT = `${NAME}/GET_PRODUCT`;
export const GET_TRENDING_PRODUCTS = `${NAME}/GET_TRENDING_PRODUCTS`;
export const GET_NEW_PRODUCTS = `${NAME}/GET_NEW_PRODUCTS`;
export const GET_TOP_SELLING_PRODUCTS = `${NAME}/GET_TOP_SELLING_PRODUCTS`;
export const GET_TOP_RATED_PRODUCTS = `${NAME}/GET_TOP_RATED_PRODUCTS`;
export const GET_TODAYS_DEAL_PRODUCTS = `${NAME}/GET_TODAYS_DEAL_PRODUCTS`;
export const GET_BEST_SELLS_PRODUCTS = `${NAME}/GET_BEST_SELLS_PRODUCTS`;
export const GET_POPULAR_PRODUCTS = `${NAME}/GET_POPULAR_PRODUCTS`;
export const GET_RELATED_PRODUCT = `${NAME}/GET_RELATED_PRODUCT`;
export const GET_HOME_PAGE_CONTENT = `${NAME}/GET_HOME_PAGE_CONTENT`;
export const GET_HOME_BANNERS = `${NAME}/GET_HOME_BANNERS`;
export const FEATURED_PRODUCTS = `${NAME}/FEATURED_PRODUCTS`;
export const SHOP_BY_CATEGORIES = `${NAME}/SHOP_BY_CATEGORIES`;
export const TOP_DISCOUNTS_BANNER = `${NAME}/TOP_DISCOUNTS_BANNER`;
export const GET_HOME_MAIN_BANNERS = `${NAME}/GET_HOME_MAIN_BANNERS`;

export const GET_STATIC_BANNERS = `${NAME}/GET_STATIC_BANNERS`;
export const GET_SUBSCRIBE_BANNERS = `${NAME}/GET_SUBSCRIBE_BANNERS`;
export const GET_HOME_TWO_BANNERS = `${NAME}/GET_HOME_TWO_BANNERS`;
export const GET_FOOTER_WHY_CHOOSE = `${NAME}/GET_FOOTER_WHY_CHOOSE`;