import PropTypes from "prop-types";
import React, {PureComponent} from 'react';
import ContentLoader from "../contentLoader";

class ImageWithLoad extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            src: ''
        }
    }

    loadImageRef = (ref) => {
        if (ref) {
            ref.onload = () => {
                this.setState({isLoaded: true});
            };
        }
    };

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.src !== props.src) {
            return {
                isLoaded: false,
                src: props.src
            }
        }
        return null
    }

    render() {
        const {src, className} = this.props;

        if (this.state.isLoaded) {
            return (
                <img className={className} src={src} alt=".."/>
            );
        } else {
            return (
                <React.Fragment>
                    <div style={{opacity: 0, position: 'absolute', top: -2000, left: -2000}}>
                        <img ref={this.loadImageRef} src={src} alt=""/>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-center">
                        <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true"/>
                    </div> */}
                    <ContentLoader loaderType = "content-relative" />
                </React.Fragment>
            );
        }
    }
}

ImageWithLoad.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
};

ImageWithLoad.defaultProps = {
    className: "",
    src: "",
};

export default ImageWithLoad;