import React, { Component } from 'react';
import images from '../../../../../assets/images';

class Features extends Component {
    render() {
        const { whyChooseItems } = this.props;
        // console.log(whyChooseItems, "whyChooseItems");
        return whyChooseItems && whyChooseItems.data && whyChooseItems.data.length > 0 && (
            <section className="featured section-padding">
                <div className="container">
                    <div className="row">
                        {
                            whyChooseItems.data.map((item, index) => {
                                return (
                                    <div key = {index} className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-md-4 mb-xl-0">
                                        <div className="banner-left-icon d-flex align-items-center wow fadeIn animated">
                                            <div className="banner-icon">
                                                <img src={item.icon} alt="" />
                                            </div>
                                            <div className="banner-text">
                                                <h3 className="icon-box-title">{item.title}</h3>
                                                <p>{item.subTitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default Features;