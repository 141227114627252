import $ from 'jquery';
import APIEndpoints from "../config/APIEndpoints";
import Constants from "./Constants";

function toFixedToOnePlace(number) {
    if (number && (number % 1 > 0)) {
        return number.toFixed(2);
    }
    return '' + number;
}

// get the top route name in any navigator
function getRouteNameInNavigator(navigator, index) {
    return navigator.routes[index !== undefined ? index : navigator.index].routeName;
}

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function copyObject(object) {
    return JSON.parse(JSON.stringify(object));
}

function getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

function appendZero(number) {
    return number < 10 ? '0' + number : '' + number
}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function postMessageToGame(message) {
    message.from = 'react_js';
    window.postMessage(message, "*");
}

function isAlphaNumeric(value) {
    var letterNumber = /^[a-z0-9]+$/i;
    if (value.match(letterNumber))
        return true;
    else
        return false;
}

function isAlphaNumericUnderscore(value) {
    var letterNumber = /^[a-z0-9_]+$/i;
    if (value.match(letterNumber))
        return true;
    else
        return false;
}

function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/; secure";
}

function createDomainCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; domain=" + '.' + document.domain + "; path=/; secure";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

function getCurrency(currencyValue) {

    switch (currencyValue) {
        case 'GBP':
            return "£";
            break;
        case 'USD':
            return "$";
            break;
        case 'EUR':
            return "€";
            break;
        default:
            return "£";
            break;
    }
}

function getCurrencyImage(currencyValue) {
    switch (currencyValue) {
        case 'GBP':
            return "assets/images/pound.png";
            break;
        case 'USD':
            return "assets/images/dollar.png";
            break;
        case 'EUR':
            return "assets/images/euro.png";
            break;
        default:
            return "assets/images/euro.png";
            break;
    }
}

function redirectToLoginSSO(redirectURl) {
    window.location.href = APIEndpoints.identitySSO.url;
}

function numberFormatter(number) {
    return number.toLocaleString('en');
}

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function generateRangeForRange(input, inputLow, inputHigh, outputLow, outputHigh) {
    return ((input - inputLow) / (inputHigh - inputLow)) * (outputHigh - outputLow) + outputLow;
}

function getQueryStringFromURL(fromCat = false) {
    return fromCat ? window.location.search : "";
}


function getRedirectGameURL(game) {
    return window.URLGamePlayingOn + "/games/" + game + "/" + HelperFunctions.getQueryStringFromURL();
}

function toURLEncoded(data) {
    const dataToPass = Object.keys(data).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join('&');
    return dataToPass;
}

function convertMS(milliseconds) {
    var day, hour, minute, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;
    return {
        day: appendZero(day),
        hour: appendZero(hour),
        minute: appendZero(minute),
        second: appendZero(seconds)
    };
}

function msToTime(s) {
    if (s) {

        var ms = s % 1000;

        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        if (ms.toString().length === 2) {
            ms = "0" + ms;
        }

        if (ms.toString().length === 1) {
            ms = "00" + ms;
        }

        hrs = appendZero(hrs);
        mins = appendZero(mins);
        secs = appendZero(secs);

        return {
            hour: hrs,
            min: mins,
            sec: secs,
            milisec: ms,
        }
        // if (hrs === "00" || hrs === "0") {
        //     return mins + ':' + secs + '.' + ms;
        // } else {
        //     return hrs + ':' + mins + ':' + secs + '.' + ms;
        // }
    }

    return null;
}

function prettyDate(date) {
    var months = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];

    return months[date.getUTCMonth()] + ' ' + date.getUTCDate();
}

function convertDateTimeToMS(dateTime) {
    var date = new Date(dateTime); // some mock date
    var currDate = new Date(); // some mock date
    var milliseconds = date.getTime();
    var currDateMilliseconds = currDate.getTime();
    var diff = milliseconds - currDateMilliseconds;

    var monthName = prettyDate(date);
    // console.log(monthName);
    //var date = date.getDate();
    /* var hours = date.getHours();
    var minutes = date.getMinutes(); */

    return { milliSeconds: diff, monthDate: monthName }
}

function returnTime(object) {
    var remainingMilliSeconds = object.milliSeconds;
    var timeObbj = convertMS(remainingMilliSeconds);
    var remainingHours = timeObbj.hour;
    var remainingDay = timeObbj.day;
    var remainingMinutes = timeObbj.minute;
    var remainingSeconds = timeObbj.second;
    var dayToShow = parseInt(remainingDay) > 0 ? (remainingDay) : '00';
    var hourToShow = parseInt(remainingHours) > 0 ? (remainingHours) : '00';
    var minuteToShow = parseInt(remainingMinutes) ? (remainingMinutes) : '00';
    var secondToShow = remainingSeconds;

    var timerValues = {
        isComplete: object.isComplete,
        dayToShow: dayToShow,
        hourToShow: hourToShow,
        minuteToShow: minuteToShow,
        secondToShow: secondToShow,
    };
    return timerValues;
}

function friendsWithBeneURL() {
    return 'https://promos.paddypower.com/promotion?promoCode=CACQFWBSPORTS';
}

function detectDevice() {
    return window.gameOrientation && window.gameOrientation.device;
}

function calculateLevelBarWidth(userXPData) {
    let xpRequired = userXPData.upcoming_level.xp_points - userXPData.user_level.xp_points;
    let totalXP = userXPData.user_xp_points - userXPData.user_level.xp_points;
    const per = (totalXP / xpRequired) * 100;
    return per === 0 ? 0 : generateRangeForRange(per, 0, 100, 4, 100);
}

function calculateLevelBarWidthNew(userXPData) {
    const per = (userXPData.current_xp / userXPData.required_xp_next_level) * 100;
    return per;
    // return per === 0 ? 0 : generateRangeForRange(per, 0, 100, 4, 100);
}

function addPlusSign(val) {

    if (val > 0) {
        return "+" + val;
    } else {
        return val;
    }
    return null;
}

function getRequiredXPForNextLevel(nextLevelXP, currentXP) {
    return (nextLevelXP - currentXP)
}

function lockedTab(data, tab) {
    let flag = null;
    if (data !== null && data.user_level.level_id) {
        let currentLevel = data.user_level.level_id;
        let definedLevel = tab === "store" ? Constants.itemsTabLevel : Constants.trainingTabLevel;
        if (currentLevel) {
            if (currentLevel < definedLevel) {
                flag = true;
            } else {
                flag = false;
            }
        }
    }
    return flag
}

function generateLocalImgPath(image) {
    return process.env.PUBLIC_URL + "/assets/images/" + image + process.env.REACT_APP_VERSION
}

function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

function validateSpecialChar(text) {
    //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
    var regex = /^[A-Za-z0-9]*$/;
    //Validate TextBox value against the Regex.
    var isValid = regex.test(text);
    return isValid;
}

function getHappyHourBarPer(value, maxValue) {
    return ((value / maxValue) * 100) + "%";
}

function toCheckPopupVisible() {
    $("body").removeClass("show-modal");
    if ($('body').css('overflow') === "hidden") {
        $('body').css('overflow', 'visible');
    }
    setTimeout(function () {
        $(".skillzone-popup-visible").each(function (index) {
            $("body").addClass("show-modal");
        });
    }, 100)

}

function strBasedOnOneMore(num = '', oneStr, moreStr) {
    if (num === 0 || num === -1 || num === 1) {
        return '' + num + ' ' + oneStr;
    }
    else {
        return ' ' + num + ' ' + moreStr;
    }
}

function MGScoreFormat(score) {
    if (score === 0) {
        score = "E";
    } else if (score > 0) {
        score = "+" + score;
    }

    return score;
}

function formatCurrency(number) {
    if (number) {
        return number.toFixed(2);
    }
    return '' + number;
}

function sorter(a, b) {
    return a.tip_per - b.tip_per;
}

function sortByTip(arr) {
    arr.sort(sorter);
}

function toSetPwrdLogo() {
    var wh = window.innerHeight;
    var th = $('.top-header').height();
    var fh = $(".theme-footer").height();

    $(".mid-wrapper").css("min-height", wh - th - fh - 58);
}

function centToDollar(cents) {
    return (cents / 100).toFixed(2);
}

function getCurrentURL() {
    return window.location;
}

function validateEmail(val) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(val) == false) {
        return false;
    }
    return true;
}

function validateMobile(val) {
    return /^\d{10}$/.test(val);
}

function validatePass(val) {
    return val.trim().length >= 6;
}

function validateOTP(val) {
    return /^\d{6}$/.test(val);
}
function validatePIN(val) {
    return /^\d{6}$/.test(val);
}

function validateName(val) {
    return /^[A-Za-z ]+$/.test(val);
}

function replaceSpace(str) {
    return str.replace(/\s/g, "-").toLowerCase();
}

function isActive(status) {
    return status === "Active";
}

function getActive(data, arr = null) {
    if (data && data.length > 0) {
        arr = arr ? arr : [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].status === "Active")
                arr.push(data[i]);
        }
        return arr;
    } else {
        return data;
    }
}

function getActiveForSelect(data, title) {
    if (data && data.length > 0) {
        const arr = [title];
        return getActive(data, arr);
    } else {
        return data;
    }
}
function isNotEmpty(str) {
    return (str !== undefined && str !== null && str.length > 0)
}

function post(path, params, method = 'post') {
    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
}

function addBodyOverlay() {
    // $("body").addClass("mobile-menu-active").prepend('<div class="body-overlay-1"></div>');
}

function removeBodyOverlay() {
    $("body").removeClass("mobile-menu-active");
    $(".body-overlay-1").remove();
}

function isOdd(num) { return num % 2; }
function calculateFinalPrice(price, discount) {
    return '₹' + parseInt(price - price * discount / 100)
}

function removeMobileNavOverlay() {
    // $('.body-overlay-1').remove();
    $('body').removeClass('mobile-menu-active').removeClass('open');
}

function scrollToTop() {
    window.scrollTo(0, 0);
}

function calculateRating(rating) {
    return rating * 20 + "%";
}

function calculateRatingAvg(rating, totalRating) {
    if (rating && totalRating) {
        return ((rating / totalRating) * 100).toFixed(1) + "%";
    }

}

function orderBadge(type) {
    switch (type) {
        case "bg-warning":
            return 'best';
        case "bg-info":
            return 'sale';
        case "bg-primary":
            return 'hot';
        case "bg-success":
            return 'new';
        case "bg-danger":
            return 'best';

    }
}

const HelperFunctions = {
    getRouteNameInNavigator,
    hexToRgb,
    getCurrencyImage,
    numberFormatter,
    formatNumber,
    copyObject,
    toFixedToOnePlace,
    getQueryStringValue,
    appendZero,
    postMessageToGame,
    isAlphaNumeric,
    isAlphaNumericUnderscore,
    createCookie,
    createDomainCookie,
    readCookie,
    eraseCookie,
    getCurrency,
    redirectToLoginSSO,
    getUrlVars,
    generateRangeForRange,
    getQueryStringFromURL,
    toURLEncoded,
    returnTime,
    convertMS,
    addPlusSign,
    convertDateTimeToMS,
    prettyDate,
    friendsWithBeneURL,
    detectDevice,
    calculateLevelBarWidth,
    getRequiredXPForNextLevel,
    lockedTab,
    generateLocalImgPath,
    ordinal_suffix_of,
    validateSpecialChar,
    getHappyHourBarPer,
    toCheckPopupVisible,
    calculateLevelBarWidthNew,
    strBasedOnOneMore,
    MGScoreFormat,
    msToTime,
    getRedirectGameURL,
    formatCurrency,
    sortByTip,
    sorter,
    toSetPwrdLogo,
    centToDollar,
    getCurrentURL,
    validateEmail, validatePass, validateMobile, validateOTP, validatePIN, validateName, replaceSpace, getActiveForSelect, isActive, isNotEmpty, post,
    addBodyOverlay,
    removeBodyOverlay,
    isOdd,
    calculateFinalPrice,
    removeMobileNavOverlay,
    scrollToTop,
    calculateRating,
    calculateRatingAvg,
    orderBadge
};

export default HelperFunctions;