import * as ActionTypes from "./actionTypes";

const initialState = {
    isUserLoggedOut: false,
    categories: [],
    productModal: null
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_CATEGORY:
        case ActionTypes.GET_PRODUCT_TYPE:
            return { ...state, categories: action.payload };
        case ActionTypes.GET_PRODUCT_MODAL:
            return { ...state, productModal: action.payload };
        // case ActionTypes.GET_NEW_PRODUCT:
        //     return {...state, newProducts: action.payload.products};
        // case ActionTypes.GET_TREND_PRODUCT:
        //     return {...state, trendProducts: action.payload.products};
        // case ActionTypes.GET_RELATED_PRODUCT:
        //     return {...state, relatedProducts: action.payload.products};
        // case ActionTypes.GET_SEARCH_PRODUCT:
        //     return {...state, searchProducts: action.payload.products};
        default:
            return { ...state }
    }
};
export default reducer;