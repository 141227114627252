import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';

class Categories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0
        }
    }
    handleTab = (index) => {
        this.setState({
            selectedIndex: index
        })
    }
    render() {
        const { categories } = this.props;
        const { selectedIndex } = this.state;
        // console.log(categories, "categories");
        // alert();
        return (
            <section className="categories-wrap">
                <div className="container">
                    <div className="categories-info content-tabs">
                        <div className="sect-tabs-bar">
                            <div className="tabs-info">
                                <ul className="tab-nav tabs-nav">
                                    {
                                        categories.length > 0 && categories.map((category, index) => {
                                            // console.log(category, "category");
                                            return (
                                                <li onClick={this.handleTab.bind(this, index)} className={selectedIndex === index ? "active" : ""} key={index}>
                                                    <a>
                                                        <div className="cat-bar">
                                                            <figure className="cat-item">
                                                                <img src={category.base_url + category.image_url} /></figure>
                                                            <span className="cat-label">{category.name}</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="sect-tabs-content">
                            <div className="content-scroll-sec">
                                {
                                    categories.length > 0 && categories.map((category, index) => {
                                        return (
                                            <div key={index} className="tab-content" style={{ display: selectedIndex === index ? "block" : "none" }}>
                                                <div className="catgory-list-wrap">
                                                    <ul className="catgory-list">

                                                        <li>
                                                            <Link to={{
                                                                pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(category.name),
                                                                search: HelperFunctions.getQueryStringFromURL(),
                                                                key: category.name,
                                                                state: { category_id: category.id }
                                                            }} aria-current="page">
                                                                <div className="cat-bar">
                                                                    <figure className="cat-item">
                                                                        <img src={category.base_url + category.image_url} />
                                                                    </figure>
                                                                    <span className="cat-label">{'All ' + category.name + ' Products'}</span>
                                                                </div>
                                                            </Link>

                                                        </li>

                                                        {
                                                            category.product_types.map((productType, prIndex) => {
                                                                return (
                                                                    <li key={prIndex}>
                                                                        <Link to={{
                                                                            pathname: ProjectRoutes.shop.url + "/" + HelperFunctions.replaceSpace(category.name) + "/" + HelperFunctions.replaceSpace(productType.name),
                                                                            search: HelperFunctions.getQueryStringFromURL(),
                                                                            key: category.name,
                                                                            state: { category_id: category.id, product_id: productType.id }
                                                                        }} aria-current="page">
                                                                            <div className="cat-bar">
                                                                                <figure className="cat-item">
                                                                                    <img src={productType.base_url + productType.image_url} />
                                                                                </figure>
                                                                                <span className="cat-label">{productType.name}</span>
                                                                            </div>
                                                                        </Link>

                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}

export default Categories;