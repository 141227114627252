import React from "react";
import ContentLoader from "../../../../../commons/components/contentLoader";
import ImagePreload from "../../../../../commons/components/imagePreload/imagePreload";
import SimpleMap from "./SimpleMap";
import Constants from "../../../../../utils/Constants";
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import HelperFunctions from '../../../../../utils/HelperFunctions';
import { Link } from "react-router-dom";


export default class ContactUs extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isImagesLoaded: false,
            name: "",
            email: "",
            mobile: "",
            subject: "",
            message: ""
        };

        this.sliderImgsArr = ["/img/updated/bg-about-us.jpg"];
    }

    changeNameHandler = event => {
        this.setState({
            name: event.target.value
        });
    };
    changeEmailHandler = event => {
        this.setState({
            email: event.target.value
        });
    };
    changeMobileHandler = event => {
        this.setState({
            mobile: event.target.value
        });
    };
    changeSubjectHandler = event => {
        this.setState({
            subject: event.target.value
        });
    };
    changeMessageHandler = event => {
        this.setState({
            message: event.target.value
        });
    };

    disableLoader = () => {
        this.setState({ isImagesLoaded: true });
    };

    submitForm = () => {
        // e.prevetDefault();
        // console.log(this.state, "data");
        var data = {
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile,
            subject: this.state.subject,
            comment: this.state.message
        }
        this.props.submitContactUsFrom(data);
    }

    isEnabledBtn = () => {
        return HelperFunctions.validateEmail(this.state.email) &&
            HelperFunctions.validateName(this.state.name) &&
            HelperFunctions.validateMobile(this.state.mobile) &&
            HelperFunctions.validateName(this.state.subject) &&
            HelperFunctions.validateName(this.state.message);
    };

    render() {
        const { isImagesLoaded } = this.state;
        console.log(this.props);
        return (
            <React.Fragment>

                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <i className="fi-rs-home mr-5"></i>
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                    Home
                                </Link>
                                <span></span> Pages <span></span> Contact us
                            </div>
                        </div>
                    </div>

                    <div className="page-content pt-50 hr-contact-wrap">

                        <section className="container mb-50 d-none d-md-block contact-map-wrap">
                            <div className="border-radius-15 overflow-hidden">
                                <SimpleMap />
                            </div>
                        </section>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-10 col-lg-12 m-auto">
                                    <section className="mb-50">
                                        <div className="row mb-60">
                                            <div className="col-xl-6">
                                                <div className="contact-from-area padding-20-row-col">
                                                    <h5 className="text-brand mb-10">Contact form</h5>
                                                    <h2 className="mb-10">Have Question?</h2>
                                                    <p className="text-muted mb-30 font-sm">Your email address will not be published. Required fields are marked *</p>
                                                    <div className="contact-form-style mt-30" id="contact-form" >
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="input-style mb-20">
                                                                    <input name="name"
                                                                        placeholder="First Name"
                                                                        onChange={this.changeNameHandler}
                                                                        value={this.state.name}
                                                                        type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="input-style mb-20">
                                                                    <input name="email"
                                                                        placeholder="Your Email"
                                                                        onChange={this.changeEmailHandler}
                                                                        value={this.state.email}
                                                                        type="email" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="input-style mb-20">
                                                                    <input name="telephone"
                                                                        placeholder="Your Phone"
                                                                        onChange={this.changeMobileHandler}
                                                                        value={this.state.mobile}
                                                                        type="tel" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="input-style mb-20">
                                                                    <input name="subject"
                                                                        placeholder="Subject"
                                                                        onChange={this.changeSubjectHandler}
                                                                        value={this.state.subject}
                                                                        type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="textarea-style mb-30">
                                                                    <textarea name="message"
                                                                        placeholder="Message"
                                                                        onChange={this.changeMessageHandler}
                                                                        value={this.state.message}
                                                                    >  </textarea>
                                                                </div>
                                                                <a className={`btn btn-xs ${this.isEnabledBtn() ? '' : 'disabled'}`} onClick={this.submitForm}>
                                                                    {this.props.isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                                                    Send message
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="form-messege"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-4 mb-md-0 hr-contact-info">
                                                <div className="hr-contact-box">
                                                    <h4 className="mb-15 text-brand">Office</h4>
                                                    <p className="hr-contact-pre">
                                                        <span className="hr-contact-phr-contact-pre-labelre">{Constants.address.name}</span><br />
                                                        {Constants.address.address}<br />
                                                        {Constants.address.pin}<br />
                                                        {Constants.address.area + "  "}
                                                    </p>
                                                    <div className="hr-contact-label">
                                                        <abbr title="Phone">Phone:</abbr> {Constants.address.phone}<br />
                                                    </div>


                                                    <div className="hr-contact-label">
                                                        <abbr title="Email">Email: </abbr>{Constants.address.email}<br />
                                                    </div>


                                                    {/* <a className="btn btn-sm font-weight-bold text-white mt-20 border-radius-5 btn-shadow-brand hover-up"><i className="fi-rs-marker mr-5"></i>View map</a> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">

                                            {/* <div className="col-lg-4 pl-50 d-lg-block d-none">
                                                    <img className="border-radius-15 mt-50" src="assets/imgs/page/contact-2.png" alt="" />
                                                </div> */}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>


            </React.Fragment>
        );
    }
}
