import React from 'react';
import { Link } from 'react-router-dom';
import ContentLoader from "../../../../../commons/components/contentLoader";
import ImagePreload from "../../../../../commons/components/imagePreload/imagePreload";
import ProjectRoutes from '../../../../../config/routes/projectRoutes';
import Constants from '../../../../../utils/Constants';
import HelperFunctions from '../../../../../utils/HelperFunctions';

export default class CancellationPolicy extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isImagesLoaded: false };

        this.sliderImgsArr = ["/img/updated/bg-about-us.jpg"];
    }

    disableLoader = () => {
        this.setState({ isImagesLoaded: true });
    };

    render() {
        const { isImagesLoaded } = this.state;


        return (
            <React.Fragment>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <i className="fi-rs-home mr-5"></i>
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }}>
                                    Home
                                </Link>
                                <span></span> Pages <span></span> Cancellation Policy
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 m-auto">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                                                <div className="single-header style-2">
                                                    <h2>Cancellation Policy</h2>
                                                </div>

                                                <div className="single-content mb-50">
                                                    <h5>Refund and Cancellation Policy</h5>
                                                    <ol start="1">
                                                        We want you to be completely satisfied with your order from {Constants.address.name}. In the event, if you are displeased with the product provided, we will try to make it right, provided the reasons are genuine and proved after investigation. Please contact us if you don’t like your purchase for any reason.                                                    </ol>
                                                    <ol>
                                                        Refunds & Cancellation may be initiated within 12 hours of purchase time, as long as they are accompanied by proof of purchase.
                                                    </ol>

                                                    <h5>Refund Policy</h5>
                                                    <ol start="1">
                                                        If you are not happy with your order for any reason, please send us an email at info@hindroot.com. Let us know why you are unhappy. We’ll give you a refund or send out a replacement – whatever suits you best.
                                                    </ol>
                                                    <ol start="1">
                                                        Your refund will be initiated by us within 2 business days of receiving the refund request and authorization of the refund. No refunds will be made after the 12 hours of cancellation window.
                                                    </ol>

                                                    <ol>
                                                        <p>Late or missing refunds</p>
                                                        <ol>
                                                            <li>If you haven’t received a refund yet, first check your bank account again.</li>
                                                            <li>Then contact your debit or credit card company, it may take some time before your refund is officially posted.</li>
                                                            <li>Next, contact your bank. There is often some processing time before a refund is posted.</li>
                                                            <li>If you’ve done all of this and you still have not received your refund yet, please contact us at info@hindroot.com</li>

                                                        </ol>
                                                    </ol>

                                                    <h5>Cancellation Policy</h5>
                                                    <ol start="1">
                                                        We admit that it is upsetting to see an order cancelled :(. But we understand that there are many reasons why one would want to cancel an order placed on our website. Before cancelling, do reach out to our snack experts to find out if we can resolve your issue without any cancellations. No order will be cancelled after 12 hours of placing your order.
                                                    </ol>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>
        );

    }
}
