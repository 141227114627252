import React, { PureComponent } from 'react';

class ImagePreload extends PureComponent {
    constructor(props) {
        super(props);
        this.imageLoadCount = 0;
    }

    loadImageRef = (num, ref) => {
        if (ref) {
            ref.onload = () => {
                this.imageLoadCount++;
                if (this.imageLoadCount >= this.props.images.length) {
                    this.props.disableLoader();
                }
            };
        }
    };

    render() {
        const { images } = this.props;
        return (
            <div style={{ opacity: 0, position: 'absolute', top: -2000, left: -2000 }}>
                {
                    images.map((image, index) => {
                        return (
                            <img key={index} ref={this.loadImageRef.bind(this, index)} src={image} alt="" />
                        )
                    })
                }
            </div>
        );
    }
}

export default ImagePreload;