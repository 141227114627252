const BASE_URL = "/";

const ProjectRoutes = {
    base: { url: BASE_URL },
    home: {
        url: BASE_URL + "home",
    },
    user: {
        url: BASE_URL + "user",
        login: {
            url: BASE_URL + "user/login",
        },
        register: {
            url: BASE_URL + "user/register",
        },
        recoverPassword: {
            url: BASE_URL + "user/recover-password",
        },
        otp: {
            url: BASE_URL + "user/otp",
        },
        newPassword: {
            url: BASE_URL + "user/new-password",
        },
        verify: {
            url: BASE_URL + "user/verify",
        },
        verifyEmail: {
            url: BASE_URL + "user/verify-email",
        },
        account: {
            url: BASE_URL + "user/account",
        }
    },
    aboutUs: {
        url: BASE_URL + "about-us",
    },
    contactUs: {
        url: BASE_URL + "contact-us",
    },
    privacyPolicy: {
        url: BASE_URL + "privacy-policy",
    },
    exchangePolicy: {
        url: BASE_URL + "exchange-policy",
    },
    cancellationPolicy: {
        url: BASE_URL + "cancellation-policy",
    },
    termsCondition: {
        url: BASE_URL + "terms-and-condition",
    },
    termsUse: {
        url: BASE_URL + "terms-of-use",
    },
    shippingPolicy: {
        url: BASE_URL + "shipping-policy",
    },
    legal: {
        url: BASE_URL + "legal",
    },
    shop: {
        url: BASE_URL + "shop",
       
        detail: {
            url: BASE_URL + "shop/detail",
        }
    },
    categories: {
        url: BASE_URL + "categories",
    },
    cart: {
        url: BASE_URL + "cart",
        checkout: {
            url: BASE_URL + "cart/checkout",
        },
        billingInfo: {
            url: BASE_URL + "cart/billing-info",
        },
        completed: {
            url: BASE_URL + "cart/completed",
        },
    },
    wishlist: {
        url: BASE_URL + "wishlist",
    },
    compare: {
        url: BASE_URL + "compare",
    },
};

export default ProjectRoutes;
export { BASE_URL };