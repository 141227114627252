import APIEndpoints from "../../../../config/APIEndpoints";
import Constants from "../../../../utils/Constants";
import HelperFunctions from "../../../../utils/HelperFunctions";
import home from "../home";
import shop from "../shop";
import * as ActionTypes from "./actionTypes";

export function callOnPageLoad(history) {
    return (dispatch, getState) => {

        dispatch(getCategory("", true, history))
    }
}

export function getCategory(group_id, isEmptyFirst = true, history = null) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_CATEGORY,
            payload: { isRequestingForWhom: isEmptyFirst && "sale/category/list" },
            promise: APIEndpoints.getCategory(group_id),
            callback: function (payload) {
                if (payload) {
                    // get featured categories
                    var featuredCat = [];

                    var catId = null;
                    var prodId = null;
                    var filteredPath = [];
                    if (history && history.location.state === undefined && history.location.pathname.includes("shop") && !history.location.pathname.includes("detail")) {
                        var pathName = history.location.pathname;
                        var pathArr = pathName.split("/");
                        filteredPath = pathArr.filter(function (el) {
                            return el != '';
                        });
                    }

                    payload.map((category, index) => {

                        if (filteredPath.length === 2) {
                            if (HelperFunctions.replaceSpace(category.name) === filteredPath[1]) {
                                catId = category.id;
                            }
                        }
                        category.product_types && category.product_types.length > 0 && category.product_types.map((prod, prodIndex) => {

                            if (filteredPath.length === 3) {
                                if (HelperFunctions.replaceSpace(prod.name) === filteredPath[2]) {
                                    prodId = prod.id;
                                }
                            }

                            if (HelperFunctions.isOdd(prodIndex)) {
                                prod.category_name = category.name;
                                // prod.category_id = category.id;
                                featuredCat.push(prod);
                            }
                        })
                    })

                    if (catId || prodId) {
                        let propTypes = { "cat_id": catId, "prod_id": prodId, "defaultNum": Constants.defaultPageSize };
                        dispatch(shop.actions.getShopProducts(propTypes))
                    }
                }
            },
        })
    }
}

export function showProductModal(product) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_PRODUCT_MODAL,
            payload: product,
        })
    }
}