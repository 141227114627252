import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import routes from "../../../../../config/routes";
import QuickViewModel from "../../../common/models/quickViewModel";
import ScrollToTop from '../../../common/scrollToTop';

function RouteWithSubRoutes(route) {
    return (
        <Route
            exact={route.exact}
            path={route.path}
            render={route.render ? route.render : props => (
                // pass the sub-routes down to keep nesting
                <route.component {...props} routes={route.routes} />
            )}
        />
    );
}

export default class RootNavigator extends React.PureComponent {

    // componentDidMount() {
    //     //back to top button
    //     window.backTopButton();
    // }

    // backTop = () => {
    //     window.scrollTo(0, 0);
    // };

    render() {
        return (
            <React.Fragment>
                <Router basename={process.env.RELATIVE_PATH}>
                    <ScrollToTop>
                        <Switch>
                            {routes.map((route, i) => (
                                <RouteWithSubRoutes key={i} {...route} />
                            ))}
                        </Switch>
                    </ScrollToTop>
                    <ToastContainer />
                    
                    <a id="back-top-button" className="text-decoration-none" onClick={this.backTop}>
                    </a>
                </Router>
            </React.Fragment>
        );
    }

}