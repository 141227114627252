/*
* This is a common place where application state resides known as store
* combineReducer() fuction is used to merge more than one reducer into a single unit
* applyMiddleware() function is used to apply middleware for mid task i.e. Web service calling
* createStore() method return store created with the help of reducers and middlewares
* */

import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import fetchMiddleware from "../middleware";
import common from "../modules/common";
import rootNavigator from "../modules/navigator/rootNavigator";
import home from "../modules/screens/home";
import header from "../modules/screens/header";
import shop from "../modules/screens/shop";
import user from "../modules/screens/user";
import cart from "../modules/screens/cart";
import wishllist from "../modules/screens/wishllist";
import compare from "../modules/screens/compare";

const reducers = combineReducers({
    [common.constants.NAME]: common.reducer,
    [rootNavigator.constants.NAME]: rootNavigator.reducer,
    [home.constants.NAME]: home.reducer,
    [header.constants.NAME]: header.reducer,
    [user.constants.NAME]: user.reducer,
    [cart.constants.NAME]: cart.reducer,
    [shop.constants.NAME]: shop.reducer,
    [wishllist.constants.NAME]: wishllist.reducer,
    [compare.constants.NAME]: compare.reducer,
});

/**
 * This root reducer will be used if we want a specific action to change all reducers state at once
 * @param state
 * @param action
 * @returns {any}
 */
const rootReducer = (state, action) => {
    return reducers(state, action)
};

let store = createStore(rootReducer, compose(applyMiddleware(thunk, fetchMiddleware,)));

export default store;