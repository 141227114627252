import APIEndpoints from "../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../config/routes/projectRoutes";
import Constants from "../../../../utils/Constants";
import HelperFunctions from "../../../../utils/HelperFunctions";
import home from "../home";
import shop from "../shop";
import * as ActionTypes from "./actionTypes";
import * as constants from "./constants";
import { Slide, toast } from "react-toastify";


export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {
    }
}




export function submitContactUsFrom(data) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.SUBMIT_CONTACT_US_FORM,
            payload: { isRequestingForWhom: Constants.submitContactFrom },
            promise: APIEndpoints.contactUs(data),
            callback: (payload) => {
                // console.log(payload, "payload");
                if (payload.statusCode === 200) {
                    this.toastId = toast(payload.message, {
                        transition: Slide,
                        closeButton: true,
                        autoClose: 5000,
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.SUCCESS,
                        hideProgressBar: true,
                    })
                } else {
                    this.toastId = toast(payload.message, {
                        transition: Slide,
                        closeButton: true,
                        autoClose: 2500,
                        position: toast.POSITION.TOP_CENTER,
                        type: 'error',
                        hideProgressBar: true,
                    })
                }
            }
        });
    }
}