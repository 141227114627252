import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProjectRoutes from '../../../config/routes/projectRoutes';
import Constants from '../../../utils/Constants';
import HelperFunctions from '../../../utils/HelperFunctions';
import ContentLoader from '../contentLoader';

class ProductCard extends Component {

    constructor(props) {
        super(props);
        this.currentProductForCart = null;
        // console.log("call cons");
    }

    goToCart = (product) => {
        // this.currentProductForCart = null;
        // add the product To the cart
        // console.log(this.currentProductForCart, "current");
        if (this.props.isRequestingForCart)
            return;
        // this.currentProductForCart = product.id;
        this.props.addToCart(product, 1);
    };

    goToWishlist = (product, wishListId) => {
        // console.log(this.props.isRequesting, "this.props.isRequesting");
    
        this.props.addToWishlist(product, wishListId);
    }

    quickView = (product) => {
        this.props.showProductModal(product)
    }

    addToCompare = (product) => {
        this.props.addToCompare(product)
    }

    render() {
        const { product, wishlistItems, addToCompare, cartProdcutId, index, isRequestingForCart, isRequestingForWhom } = this.props;
        // console.log(wishlistItems, "this.isRequestingForWhom");

        let isWishlisted = false;
        let wishListId = null;
        wishlistItems.length > 0 && wishlistItems.map((item, index) => {
            if(item.id === product.id) {
                isWishlisted = true;
                wishListId = index;
                return;
            }
        })
        return  (
            // <div key={index} className="col-lg-1-5 col-md-4 col-12 col-sm-6">
            <div className="product-cart-wrap mb-30">
                <div className="product-img-action-wrap">
                    <div className="product-img product-img-zoom">
                        <Link to={{
                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                            search: HelperFunctions.getQueryStringFromURL(),
                            state: { product: product }
                        }}>
                            <img className="default-img" src={product.base_url + product.image_url1} alt="" />
                            <img className="hover-img" src={product.base_url + product.image_url2} alt="" />
                        </Link>
                    </div>
                    <div className="product-action-1">
                        <a onClick={this.quickView.bind(this, product)} aria-label="Quick view" className="action-btn" data-bs-toggle="modal" data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></a>
                        <a onClick={this.goToWishlist.bind(this, product, wishListId)} aria-label="Add To Wishlist" className={`action-btn ${isWishlisted ? "active" : ""}`}><i className={`fi-rs-heart`}></i></a>
                        <a onClick={this.addToCompare.bind(this, product)} aria-label="Compare" className="action-btn"><i className="fi-rs-shuffle"></i></a>
                    </div>
                    {
                        product.badge_text && <div className="product-badges product-badges-position product-badges-mrg">
                            <span className={`${product.badge_class}`}>{product.badge_text}</span>
                        </div>
                    }

                </div>
                <div className="product-content-wrap">
                    

                    <h2>
                        <Link to={{
                            pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(product.name) + '/' + product.id,
                            search: HelperFunctions.getQueryStringFromURL(),
                            state: { product: product }
                        }} >
                            {product.name}
                        </Link>
                    </h2>
                    {
                        product.avg_rating && <div className="product-rate-cover">
                            <div className="product-rate d-inline-block">
                                <div className="product-rating" style={{ width: HelperFunctions.calculateRating(product.avg_rating) }}></div>
                            </div>
                            <span className="font-small ml-5 text-muted"> ({product.avg_rating})</span>
                        </div>
                    }

                    <div>
                        <span className="font-small text-muted">By <a>{Constants.address.name}</a></span>
                    </div>
                    <div className="product-card-bottom">
                        <div className="product-price">
                            <span>{'₹' + parseInt(product.rent_per_product - product.rent_per_product * product.discount / 100)}</span>
                            <span className="old-price">{'₹' + product.rent_per_product}</span>
                        </div>
                        <div className="add-cart">
                            <a className={`add add-loader ${isRequestingForCart && cartProdcutId === product.id && "disabled"}`} onClick={this.goToCart.bind(this, product)}>
                                <i className="fi-rs-shopping-cart mr-5"></i>

                                {
                                    isRequestingForCart && cartProdcutId === product.id ?
                                        <ContentLoader loaderType="btn-loader" /> :
                                        "Add"
                                }

                            </a>
                        </div>
                    </div>
                </div>
            </div>
            // </div>
        );
    }
}

export default ProductCard;