import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import images from '../../../../../assets/images';
import ContentLoader from "../../../../../commons/components/contentLoader";
import MessageContainer from '../../../../../commons/components/messageContainer';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class CartCheckout extends PureComponent {

    changeQuan = (itemIndex, quantity) => {
        this.props.changeItemQuantity(itemIndex, quantity);
    };
    remove = (itemIndex) => {
        this.props.removeFromCart(itemIndex);
    };

    render() {

        const { cartItems, isRequest } = this.props;
        let cartTotal = 0;

        if (cartItems.length > 0)
            return (
                <div className="container mb-80 mt-50 hr-cart-wrap hr-cart-wrap-page">
                    <div className="row">
                        <div className="col-lg-8 mb-40 hr-cart-title">
                            <h1 className="heading-2 mb-10">Your Cart</h1>
                            <div className="d-flex justify-content-between">
                                <h6 className="text-body">There are <span className="text-brand">{cartItems.length}</span> products in your cart</h6>
                                {/* <h6 className="text-body"><a className="text-muted"><i className="fi-rs-trash mr-5"></i>Clear Cart</a></h6> */}
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-lg-8">
                            <div className="table-responsive shopping-summery">
                                <table className="table table-wishlist">
                                    <thead>
                                        <tr className="main-heading">
                                            <th className="custome-checkbox start pl-30">
                                                {/* <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox11" value="" />
                                                <label className="form-check-label" htmlFor="exampleCheckbox11"></label> */}
                                            </th>
                                            <th scope="col" colSpan="2">Product</th>
                                            <th scope="col">Unit Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Subtotal</th>
                                            <th scope="col" className="end">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cartItems.map((product, index) => {
                                                const { name, base_url, description, discount, fabric, image_url1, rent_per_product, total_quantity, quantity, badge_text, badge_class, id } = product;

                                                cartTotal += quantity * parseInt(rent_per_product - rent_per_product * discount / 100);

                                                return (
                                                    <tr key={"" + index}>
                                                        <td className="custome-checkbox pl-30">
                                                            {/* <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox2" value="" />
                                                            <label className="form-check-label" htmlFor="exampleCheckbox2"></label> */}
                                                        </td>
                                                        <td className="image product-thumbnail"><img src={base_url + image_url1} alt="#" /></td>
                                                        <td className="product-des product-name">
                                                            <h6 className="mb-5">
                                                                <Link to={{
                                                                    pathname: ProjectRoutes.shop.detail.url + '/' + HelperFunctions.replaceSpace(name) + '/' + id,
                                                                    search: HelperFunctions.getQueryStringFromURL(),
                                                                    state: { product: product },
                                                                    key: product.id
                                                                }} className="product-name mb-10 text-heading">
                                                                    {name}
                                                                </Link>
                                                            </h6>
                                                            <div className="product-rate-cover">
                                                                <div className="product-rate d-inline-block">
                                                                    <div className="product-rating" style={{ width: "90%" }}>
                                                                    </div>
                                                                </div>
                                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                                            </div>
                                                        </td>
                                                        <td className="price" data-title="Price">
                                                            <h4 className="text-body">{HelperFunctions.calculateFinalPrice(rent_per_product, discount)} </h4>
                                                        </td>
                                                        <td className="text-center detail-info" data-title="Stock">
                                                            <div className="detail-extralink mr-15">
                                                                <div className="detail-qty border radius">
                                                                    <a className="qty-down" onClick={this.changeQuan.bind(this, index, quantity - 1)}><i className="fi-rs-angle-small-down"></i></a>
                                                                    <span className="qty-val">{quantity}</span>
                                                                    <a className="qty-up" onClick={this.changeQuan.bind(this, index, quantity + 1)}><i className="fi-rs-angle-small-up"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="price" data-title="Price" tempkjk={HelperFunctions.calculateFinalPrice(rent_per_product, discount)}>
                                                            <h4 className="text-brand">{"₹" + parseInt(HelperFunctions.calculateFinalPrice(rent_per_product, discount).replace("₹", "")) * quantity} </h4>
                                                        </td>
                                                        <td className="action text-center" data-title="Remove">
                                                            <a className="text-body" onClick={this.remove.bind(this, index)}><i className="fi-rs-trash"></i></a>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="divider-2 mb-30"></div>
                            <div className="cart-action d-flex justify-content-between">
                                <Link to={{ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() }} className="btn btn-outline-primary mb-2">
                                    <i className="fi-rs-arrow-left mr-10"></i>
                                    Continue shopping
                                </Link>
                                {/* <a className="btn  mr-10 mb-sm-15"><i className="fi-rs-refresh mr-10"></i>Update Cart</a> */}
                            </div>
                            {/* <div className="row mt-50">
                                <div className="col-lg-7">
                                    <div className="calculate-shiping p-40 border-radius-15 border">
                                        <h4 className="mb-10">Calculate Shipping</h4>
                                        <p className="mb-30"><span className="font-lg text-muted">Flat rate:</span><strong className="text-brand">5%</strong></p>
                                        <form className="field_form shipping_calculator">
                                            <div className="form-row">
                                                <div className="form-group col-lg-12">
                                                    <div className="custom_select">
                                                        <select className="form-control select-active w-100">
                                                            <option value="">United Kingdom</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row row">
                                                <div className="form-group col-lg-6">
                                                    <input required="required" placeholder="State / Country" name="name" type="text" />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <input required="required" placeholder="PostCode / ZIP" name="name" type="text" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="p-40">
                                        <h4 className="mb-10">Apply Coupon</h4>
                                        <p className="mb-30"><span className="font-lg text-muted" />Using A Promo Code?</p>
                                        <form action="#" >
                                            <div className="d-flex justify-content-between">
                                                <input className="font-medium mr-15 coupon" name="Coupon" placeholder="Enter Your Coupon" />
                                                <button className="btn"><i className="fi-rs-label mr-10"></i>Apply</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-4">
                            <div className="border p-md-4 cart-totals ml-30">
                                <div className="table-responsive">
                                    <table className="table no-border">
                                        <tbody>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted">Subtotal</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h4 className="text-brand text-end">{'₹' + cartTotal}</h4>
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td scope="col" colSpan="2">
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr> */}
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted">Shipping</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h5 className="text-heading text-end">Free</h5>
                                                </td>

                                                {/* <td scope="col" colSpan="2">
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td> */}
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted">Total</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h4 className="text-brand text-end">{'₹' + cartTotal}</h4>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Link to={{ pathname: ProjectRoutes.cart.billingInfo.url, search: HelperFunctions.getQueryStringFromURL(), state: { is_scroll_top: true } }} className="btn mb-20 w-100">
                                    <i className="fi-rs-sign-out ml-15"></i>
                                    Proceed To CheckOut
                                </Link>
                                {/* <a className="btn mb-20 w-100">Proceed To CheckOut<i className="fi-rs-sign-out ml-15"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            );
        else
            return (
                <div className="text-center animated slideInUpLess">
                    <MessageContainer icon={images.cartIcon} msg="Your Hindroot cart is empty." />
                </div>
            );
    }

}