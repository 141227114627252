import React, { Component } from 'react';
import $ from 'jquery';
import HelperFunctions from '../../../../../utils/HelperFunctions';

class SubmitReview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reviewMessage: '',
        };
        this.clickedFlag = false;
    }

    isEnabledBtn = () => {
        return HelperFunctions.validateName(this.state.reviewMessage);
    };

    onMouseEnterHandler = (val) => {
        this.value = val;
        this.clickedFlag = false;
        if (!this.clickedFlag) {
            for (let j = 1; j <= 10; j++) {
                $("#rate_" + j).removeClass("active");
            }
        }
        for (let i = 1; i <= val; i++) {
            $("#rate_" + i).addClass("active");
        }
    }

    onMouseClick = () => {
        this.setState({
            value: this.value,
        });
        this.clickedFlag = true;
    }

    onMouseLeaveHandler = () => {
        for (let j = 1; j <= 10; j++) {
            $("#rate_" + j).removeClass("active");
        }
        for (let i = 0; i <= this.state.value; i++) {
            $("#rate_" + i).addClass("active");
        }
    }

    createStar = () => {
        let starList = []

        // Outer loop to create parent
        for (let i = 1; i < 6; i++) {
            // <i className="star-icon"></i>
            starList.push(
                <i className="star-icon" id={"rate_" + i} onMouseEnter={this.onMouseEnterHandler.bind(this, i)}
                    onMouseLeave={this.onMouseLeaveHandler}
                    onTouchStart={this.onMouseEnterHandler.bind(this, i)}
                    onTouchEnd={this.onMouseLeaveHandler}
                    onClick={this.onMouseClick}>

                </i>)

        }
        return starList
    }

    changeMessageHandler = event => {
        this.setState({
            reviewMessage: event.target.value
        });
    };

    submitForm = () => {
        // e.prevetDefault();
        // console.log(this.state, "data");
        var data = {
            user_id: this.props.userData ? this.props.userData.user_id : '',
            product_id: this.props.location.state && this.props.location.state.product ? this.props.location.state.product.id : '',
            rating: this.state.value,
            comment: this.state.reviewMessage
        }
        this.props.submitReviewForm(data);
    }

    render() {
        const { userData } = this.props;
        return userData && (
            <div className="comment-form">
                <h4 className="mb-15">Add a review</h4>
                <div className="star-rating-bar">
                    <div className="star-rating">
                        {this.createStar()}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="form-contact comment_form">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <textarea value={this.state.reviewMessage} onChange={this.changeMessageHandler} className="form-control w-100" name="comment" id="comment" cols="30" rows="9" placeholder="Write Comment">

                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <a className={`btn btn-xs ${this.isEnabledBtn() ? '' : 'disabled'}`} onClick={this.submitForm}>
                                    {this.props.isRequestingForReview && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                    Submit Review
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SubmitReview;