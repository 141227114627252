import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class Completed extends PureComponent {
    componentDidMount() {
        if (this.props.userData === null) {
            window.redirectToWhere = ProjectRoutes.cart.completed.url;
            this.props.history.push({ pathname: ProjectRoutes.user.login.url, search: HelperFunctions.getQueryStringFromURL() });
        }
        else {
            // this.props.emptyCart();
            const order_id = this.props.location.state ? this.props.location.state.order_id : null;
            if (order_id === null) {
                this.props.history.replace({ pathname: ProjectRoutes.home.url, search: HelperFunctions.getQueryStringFromURL() });
            }
        }
    }

    render() {

        const order_id = this.props.location.state ? this.props.location.state.order_id : null;
        const order_status = this.props.location.state ? this.props.location.state.order_status : null;

        return (
            <div className="text-center ">
                <div className="no-found-item no-item-card">
                    <div className="container">
                        <div className="found-item-inner">
                            <div className="no-fount-icon">
                                <div className="alert-icon success-icon animate__animated animate__rubberBand ">
                                    <i className="fi-rs-check"></i>
                                </div>
                                {/* <div className="alert-icon eror-icon">
                                    <i className="fi-rs-cross"></i>
                                </div> */}
                            </div>
                            <h2 className="no-item-title">Thank you for your purchase.</h2>
                            {order_id && <h3 className="no-item-sm-title">Your order number is: <b>{order_id}</b></h3>}
                            {order_status && <h3 className="no-item-sm-title">Your payment status is: <b>{order_status}</b></h3>}
                            <div className="no-fount-action">
                                <Link to={{
                                    pathname: ProjectRoutes.home.url,
                                    search: HelperFunctions.getQueryStringFromURL()
                                }} className={`btn btn-primary`} aria-current="page">
                                    <i className="fas fa-shopping-bag me-2"></i>Continue shopping
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}