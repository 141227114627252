import React, { PureComponent } from 'react';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import Select from 'react-select'

export default class Address extends PureComponent {

    constructor(props) {
        super(props);
        const addressObj = this.props.userData && this.props.userData.address ? this.props.userData.address : {};
        this.state = {
            data: {
                address: '',
                landmark: '',
                pin_code: '',
                country_id: '5da32957a7feed0b6fce495c',
                country_name: 'India',
                state_id: '',
                state_name: '',
                city_id: '',
                city_name: '',
                ...addressObj
            },
        };
        // call default state and cities
        if (addressObj.state_id) {
            if (this.props.states && this.props.states.length > 0) {
            }
            else this.props.getState(addressObj.country_id);
            this.props.getCity(addressObj.country_id, addressObj.state_id);
        }
    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    stateChange = (event) => {
        if (event.id) {

            let state_name = '';
            for (let i = 0; i < this.props.states.length; i++) {
                if (event.id === this.props.states[i].id) {
                    state_name = this.props.states[i].name;
                    break;
                }
            }

            // update state_name in state
            const dataCopy = HelperFunctions.copyObject(this.state.data);
            dataCopy['state_name'] = state_name;
            dataCopy['state_id'] = event.id;
            this.setState({
                data: dataCopy
            });

            this.props.getCity('5da32957a7feed0b6fce495c', event.id);
        }
    };

    cityChange = (event) => {
        if (event.id) {
            let city_name = '';
            for (let i = 0; i < this.props.cities.length; i++) {
                if (event.id === this.props.cities[i].id) {
                    city_name = this.props.cities[i].name;
                    break;
                }
            }

            // update city_name in state
            const dataCopy = HelperFunctions.copyObject(this.state.data);
            dataCopy['city_name'] = city_name;
            dataCopy['city_id'] = event.id;
            this.setState({
                data: dataCopy
            });
        }
    };

    componentDidMount() {
        if (this.props.userData === null) {
            window.redirectToWhere = ProjectRoutes.cart.billingInfo.url;
            this.props.history.push({ pathname: ProjectRoutes.user.login.url, search: HelperFunctions.getQueryStringFromURL() });
            return;
        }
        // window.billingInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.states !== this.props.states) {
            // window.stateChoice();
        }
        if (prevProps.cities !== this.props.cities) {
            // window.cityChoice();
        }
    }

    update = (e) => {
        e.preventDefault();
        if (this.props.isRequestingForWhom !== 'user/address')
            this.props.updateAddress(this.state.data, this.props.history);
    };

    isEnabledBtn = () => {
        return (
            HelperFunctions.validatePIN(this.state.data.pin_code) &&
            HelperFunctions.isNotEmpty(this.state.data.landmark) &&
            HelperFunctions.isNotEmpty(this.state.data.country_id) &&
            HelperFunctions.isNotEmpty(this.state.data.state_id) &&
            HelperFunctions.isNotEmpty(this.state.data.city_id)
        )
    };

    render() {

        const { cartItems, isRequestingForWhom, countries, states, cities, userAddressData } = this.props;

        const isRequesting = isRequestingForWhom === "user/address";

        const { data } = this.state;

        let isInvalidAdd = null, isInvalidPin = null, isInvalidLand = null, isInvalidState = null, isInvalidCity = null;
        if (userAddressData && userAddressData.errors) {
            if (userAddressData.errors.address) {
                isInvalidAdd = userAddressData.errors.address;
            }
            if (userAddressData.errors.pin_code) {
                isInvalidPin = userAddressData.errors.pin_code;
            }
            if (userAddressData.errors.landmark) {
                isInvalidLand = userAddressData.errors.landmark;
            }
            if (userAddressData.errors.state_id) {
                isInvalidState = userAddressData.errors.state_id;
            }
            if (userAddressData.errors.city_id) {
                isInvalidCity = userAddressData.errors.city_id;
            }
        }

        const statesList = [];
        const citiesList = [];

        states && states.length > 0 && states.map((state, index) => {
            statesList.push({ value: state.name, label: state.name, id: state.id })
        });

        cities && cities.length > 0 && cities.map((city, index) => {
            citiesList.push({ value: city.name, label: city.name, id: city.id })
        });

        return (
            <div className="card animate__animated animate__fadeIn">
                <div className="card-header">
                    <h3 className="mb-0">Shipping Details</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="form-group col-12 mb-3">
                            <label className="form-label small text-uppercase">Address</label>

                            <input className={`form-control ${isInvalidAdd && 'is-invalid'}`} placeholder="Enter your main address" type="text" name="address" id="address"
                                value={data["address"]} onChange={this.onInputChange.bind(this, "address")} />
                            <div className="invalid-feedback">{isInvalidAdd}</div>
                        </div>
                        <div className="form-group col-6 mb-3">
                            <label className="form-label small text-uppercase">Landmark</label>
                            <input className={`form-control ${isInvalidLand && 'is-invalid'}`} placeholder="Enter a landmark" type="text" name="landmark" id="landmark"
                                value={data["landmark"]} onChange={this.onInputChange.bind(this, "landmark")} />
                            <div className="invalid-feedback">{isInvalidLand}</div>
                        </div>
                        <div className="form-group col-6 mb-3">
                            <label className="form-label small text-uppercase">Pin code</label>
                            <input className={`form-control ${isInvalidPin && 'is-invalid'}`} placeholder="Enter city postal code" type="text" name="pin_code" id="pin_code"
                                value={data["pin_code"]} onChange={this.onInputChange.bind(this, "pin_code")} />
                            <div className="invalid-feedback">{isInvalidPin}</div>
                        </div>
                        <div className="form-group col-lg-6 mb-3">
                            <label className="form-label small text-uppercase">
                                State
                                {isRequestingForWhom === 'state/list' && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                            </label>
                            {/* <select className={`form-control js-state ${isInvalidState && 'is-invalid'}`} name="state_id" id="state_id" value={data["state_id"]} onChange={this.stateChange}>
                                {states && states.length > 0 && states.map((item, index) => {
                                    return (
                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                    )
                                })}
                            </select> */}
                            <Select
                                value={statesList.find(obj => obj.id === data.state_id)}
                                onChange={this.stateChange}
                                className="form-control select-active hr-custom-select"
                                options={statesList} />
                            <div className="invalid-feedback">{isInvalidState}</div>
                        </div>
                        <div className="form-group col-lg-6 mb-3">

                            <label className="form-label small text-uppercase">
                                City
                                {isRequestingForWhom === 'city/list' && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                            </label>
                            {/* <select className={`form-control js-city ${isInvalidCity && 'is-invalid'}`} name="city_id" id="city_id" value={data["city_id"]} onChange={this.cityChange}>
                                    {cities && cities.length > 0 && cities.map((item, index) => {
                                        return (
                                            <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                        )
                                    })}
                                </select> */}

                            <Select
                                onChange={this.cityChange}
                                value={citiesList.find(obj => obj.id === data.city_id)}
                                className="form-control select-active hr-custom-select"
                                options={citiesList} />
                            <div className="invalid-feedback">{isInvalidCity}</div>
                        </div>

                        <div className="form-group col-md-12 hr-login-with">
                            <a onClick={this.update} className={`btn btn-primary ${this.isEnabledBtn() ? '' : 'disabled'}`} type=" submit">
                                {isRequesting && <span className="spinner-grow spinner-grow-sm mr-5" role="status" aria-hidden="true" />}
                                Update Address
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}