const Constants = function () {
    return {
        rewardsConfig: {
            confetti: {
                type: 'confetti',
                fakingRequest: false,
                angle: 90,
                decay: 0.91,
                spread: 50,
                startVelocity: 40,
                elementCount: 60,
                elementSize: 12,
                lifetime: 100,
                zIndex: 9999999,
                springAnimation: true
            },
            welcome: {
                type: 'coin',
                fakingRequest: false,
                angle: 90,
                decay: 0.91,
                spread: 50,
                startVelocity: 60,
                elementCount: 20,
                elementSize: 20,
                lifetime: 100,
                zIndex: 10,
                springAnimation: true
            },
        },
        defaultPageSize: 30,
        swiperOptions: {
            margin: 15,
            responsive: {
                0: {
                    items: 2
                },
                640: {
                    items: 3
                },
                768: {
                    items: 4
                },
                1024: {
                    items: 5
                }
            },
        },
        address: {
            name: 'Hindroot',
            address: 'C-44-A, Shree Balaji Tower, Opp. Road No. 5, Vishwakarma Industrial Area,',
            pin: "Sikar road - 302013,",
            area: 'Jaipur, Rajasthan, INDIA',
            email:'info@hindroot.com',
            phone:'7300094000',
            desc:'Hindroot Desc'
        },
        socialLinks : {
            fb : "https://www.facebook.com/hindroot/"
        },
        cartAddMatch : "cart/add",
        cartRemoveMatch : "cart/remove-update",
        cartDeleteMatch : "cart/delete",
        cartAddOrderMatch : "cart/add-order",
        cartItemsMatch : "cart/items",

        compareAddMatch : "compare/add",
        userOrderMatch : "user/order",
        submitContactFrom : "submit/contact-us",
        submitReviewFrom : "submit/review-form",


    };
}();

export default Constants;