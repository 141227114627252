import * as $ from 'jquery';
import { Slide, toast } from "react-toastify";
import APIEndpoints from "../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../utils/HelperFunctions";
import common from "../../common";
import header from '../header';
import shop from "../shop";
import user from '../user';
// import user from "../user";
import * as ActionTypes from "./actionTypes";
import * as constants from "./constants";

export function callOnPageLoad(routerHistory) {
    return (dispatch, getState) => {
        dispatch(getWishlistItems());
        // dispatch({type: ActionTypes.COMPLETE_ORDER, payload: {isOrderCompleted: false}});
        // const pathname = routerHistory.location.pathname;

        // // after payment URL will have order_id and status
        // if (pathname.includes(ProjectRoutes.cart.completed.url)) {
        //     const arr = pathname.split("/");
        //     if (arr.length === 5) {
        //         const order_id = arr[3];
        //         const order_status = arr[4];
        //         console.log(order_id, order_status);
        //         dispatch({type: ActionTypes.COMPLETE_ORDER, payload: {isOrderCompleted: true}});
        //         routerHistory.push({pathname: ProjectRoutes.cart.completed.url, search: HelperFunctions.getQueryStringFromURL(), state: {is_scroll_top: true, order_id: order_id, order_status}});
        //     }
        // }

        HelperFunctions.removeMobileNavOverlay();
    }
}

export function handleLocalStorageWishlistWhenUserLogsIn(user_wishlist_items = []) {
    return (dispatch, getState) => {
        let wishlist_items = localStorage.getItem("wishlist_items");
        wishlist_items = wishlist_items ? JSON.parse(wishlist_items) : [];

        if (wishlist_items.length > 0) {
            dispatch({
                type: ActionTypes.ADD_TO_USER_WISHLIST,
                payload: { isRequestingForWhom: 'wishlist/add' },
                promise: APIEndpoints.addToWishlist(wishlist_items),
                callback: (payload) => {
                    if (payload.statusCode === 200) {
                        //empty the local storage once cart is added to db
                        localStorage.setItem("wishlist_items", JSON.stringify([]));
                        dispatch({ type: ActionTypes.ADD_TO_WISHLIST, payload: [] });
                        // we call the API again for user cart items
                        dispatch(user.actions.checkUser());
                    }
                }
            }
            )
        }
        else { //put received user cart items into the store from db
            dispatch({ type: ActionTypes.ADD_TO_WISHLIST, payload: user_wishlist_items });
            // dispatch(getCartItems());
        }
    }
}

/*
    fromWhere defines we are calling addToWishlist from model or details page
 */
export function addToWishlist(modelProduct, removedItemIndex = null) {
    return (dispatch, getState) => {

        if (removedItemIndex !== null) {
            dispatch(removeFromWishlist(removedItemIndex));
            return;
        }

        dispatch({ type: common.actionTypes.REQUEST, payload: { isRequestingForWhom: 'wishlist/add' } });

        const wishlistItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].wishlistItems);


        let isAlreadyAdded = false;
        for (let i = 0; i < wishlistItemsCopy.length; i++) {
            if (modelProduct.id === wishlistItemsCopy[i].id) {
                // quantity = wishlistItemsCopy[i].quantity + quantity;
                isAlreadyAdded = true;
                break;
            }
        }

        if (isAlreadyAdded) {
            setTimeout(() => {
                this.toastId = toast("Item has been added to wislist!", {
                    transition: Slide,
                    closeButton: true,
                    autoClose: 1000,
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.SUCCESS,
                    hideProgressBar: true,
                });
                dispatch({ type: common.actionTypes.REQUEST_COMPLETE });
            }, 1500);
        }
        else {
            const { userData } = getState()[user.constants.NAME];

            wishlistItemsCopy.push({ id: modelProduct.id });

            const addToStoreAndToast = () => {
                dispatch({ type: ActionTypes.ADD_TO_WISHLIST, payload: wishlistItemsCopy });
                this.toastId = toast("Item has been added to wishlist!", {
                    transition: Slide,
                    closeButton: true,
                    autoClose: 1000,
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.SUCCESS,
                    hideProgressBar: true,
                });
            };

            if (userData === null) { //this is the case when user is logged out
                setTimeout(() => {
                    localStorage.setItem("wishlist_items", JSON.stringify(wishlistItemsCopy));
                    addToStoreAndToast();
                    dispatch({ type: common.actionTypes.REQUEST_COMPLETE });
                }, 1500);
            }
            else {
                dispatch({
                    type: ActionTypes.ADD_TO_USER_WISHLIST,
                    payload: { isRequestingForWhom: 'wishlist/add' },
                    promise: APIEndpoints.addToWishlist([{ "id": modelProduct.id }]),
                    callback: (payload) => {
                        if (payload.statusCode === 200) {
                            addToStoreAndToast();
                        }
                    }
                }
                )
            }
        }

        // hide the model if the add to cart was clicked from quick view model
        // if (fromWhere === 'model') {
        //     $(".close").trigger('click');
        // }
    }
}

export function removeFromWishlist(itemIndex) {
    return (dispatch, getState) => {
        const wishlistItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].wishlistItems);
        const wishlistItemsDetailsCopy = HelperFunctions.copyObject(getState()[constants.NAME].wishlistItemsDetails);

        const itemIdToRemove = wishlistItemsCopy[itemIndex].id;

        wishlistItemsCopy.splice(itemIndex, 1);
        wishlistItemsDetailsCopy.splice(itemIndex, 1);

        const { userData } = getState()[user.constants.NAME];

        // console.log(wishlistItemsCopy, wishlistItemsDetailsCopy, "wishlistItemsDetailsCopy");

        const toPerformAfter = () => {
            dispatch({ type: ActionTypes.ADD_TO_WISHLIST, payload: wishlistItemsCopy });
            dispatch({ type: ActionTypes.GET_WISHLIST_ITEMS, payload: wishlistItemsDetailsCopy });
            this.toastId = toast("Item has been removed from wishlist!", {
                transition: Slide,
                closeButton: true,
                autoClose: 1000,
                position: toast.POSITION.TOP_CENTER,
                type: toast.TYPE.INFO,
                hideProgressBar: true,
            })
        };

        if (userData === null) {
            localStorage.setItem("wishlist_items", JSON.stringify(wishlistItemsCopy));
            toPerformAfter();
        } else {
            dispatch({
                type: ActionTypes.REMOVE_FROM_USER_WISHLIST,
                payload: { isRequestingForWhom: 'wishlist/remove-update' },
                promise: APIEndpoints.removeFromWishlist({ id: itemIdToRemove }),
                callback: (payload) => {
                    if (payload.statusCode === 200) {
                        toPerformAfter();
                    }
                }
            }
            )
        }
    }
}

export function getWishlistItems() {
    return (dispatch, getState) => {
        const wishlistItemsCopy = HelperFunctions.copyObject(getState()[constants.NAME].wishlistItems);

        // console.log(wishlistItemsCopy, "cartItemsCopy");

        if (wishlistItemsCopy.length > 0) {
            let product_ids = "";
            for (let i = 0; i < wishlistItemsCopy.length; i++) {
                product_ids += (wishlistItemsCopy[i].id + (i < wishlistItemsCopy.length - 1 ? "," : ""));
            }

            const { categories } = getState()[header.constants.NAME];

            dispatch({
                type: ActionTypes.GET_WISHLIST_ITEMS,
                payload: { isRequestingForWhom: 'wishlist/items', categories },
                promise: APIEndpoints.getWishlistItems({ product_ids }),
                callback: (payload) => {
                    // console.log(payload, "wisl")
                    if (payload && payload.products && payload.products.length > 0) {
                        // for (let i = 0; i < payload.products.length; i++) {
                        //     for (let j = 0; j < wishlistItemsCopy.length; j++) {
                        //         if (payload.products[i].id === wishlistItemsCopy[j].id) {
                        //             payload.products[i].quantity = wishlistItemsCopy[j].quantity;
                        //             break;
                        //         }
                        //     }
                        // }
                        dispatch({ type: ActionTypes.GET_WISHLIST_ITEMS, payload: payload.products });
                    }
                }
            }
            )
        }
        else {
            dispatch({ type: ActionTypes.GET_WISHLIST_ITEMS, payload: [] });
        }

    }
}
