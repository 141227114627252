import React, { Component } from 'react';
import SearchBar from '../../../common/searchBar';

class SearchModal extends Component {
    render() {
        const { toggleSearchModal } = this.props;
        return (
            <div className="full-search-wrap">
                <div className="full-search-bar">
                    <div className="search-inner">
                        <div className="search-style-2">
                            <form action="#">
                                <button className="back-search" onClick={toggleSearchModal}>
                                    <i className="fi-rs-arrow-small-left"></i>
                                </button>
                                <SearchBar />
                                <button className="search-form-icon"><i className="fi-rs-search"></i></button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default SearchModal;