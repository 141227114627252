import React, { Component } from 'react';
import commonStyles from '../../../../../assets/styles';
import { BrowserRouter, Link, Redirect, Route, Switch } from "react-router-dom";
import ErrorContainer from "../../../../../commons/components/errorContainer";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import DividerBanner from './dividerBanner';
import Shopping from './shopping';
import DetailsRenderer from './detailsRenderer';
import Constants from '../../../../../utils/Constants';

const ShopTemp = (props) => {
    // const { category, productType} = useParams();
 
    console.log(props, "category, productType");
    // useEffect(() => {
    //     // Any fetch logic based on params
    // }, [profession, country, value]);
 
    return (
       null
    )
 }

class Shop extends Component {

    shop = (props) => (
        
        <React.Fragment>
            <DividerBanner />
            <Shopping {...props}
                categories={this.props.categories}
                isLoadMore={this.props.isLoadMore}
                wishlistItems={this.props.wishlistItems}
                getProduct={this.props.getProduct}
                products={this.props.shopProducts}
                getShopProducts={this.props.getShopProducts}
                emptyShopProducts={this.props.emptyShopProducts}
                isRequesting={this.props.isLoading}
                path={this.props.location.pathname}
                isRequestingForCart={this.props.isRequestingForWhom === Constants.cartAddMatch}
                getProductType={this.props.getProductType}
                totalProductCount={this.props.totalProductCount}
                addToCart={this.props.addToCart}
                addToWishlist={this.props.addToWishlist}
                showProductModal={this.props.showProductModal}
                addToCompare={this.props.addToCompare}
                cartProdcutId={this.props.cartProdcutId}
                callOnPageLoad={this.props.callOnPageLoad} />
        </React.Fragment>
    );

    details = (props) => (
        <DetailsRenderer
            {...props}
            getRelatedProduct={this.props.getRelatedProduct}
            products={this.props.relatedProducts}
            wishlistItems={this.props.wishlistItems}
            userData={this.props.userData}
            submitReviewForm={this.props.submitReviewForm}
            isRequesting={this.props.isRequestingForWhom === Constants.cartAddMatch}
            isRequestingForReview={this.props.isRequestingForWhom === Constants.submitReviewFrom}
            isRequestingForDetails={this.props.isRequestingForWhom === "shop/product-detail"}
            showProductModel={this.props.showProductModel}
            getProductWithDetail={this.props.getProductWithDetail}
            addToCart={this.props.addToCart}
            showProductModal={this.props.showProductModal}
            addToWishlist={this.props.addToWishlist}
            cartProdcutId={this.props.cartProdcutId}
            addToCompare={this.props.addToCompare}
            callOnPageLoad={this.props.callOnPageLoad} />
    );


    render() {
        // console.log(this.props);
        return (
            <main className="main">
                <Switch>
                    <Route path={ProjectRoutes.shop.detail.url} render={this.details} />
                    <Route path={ProjectRoutes.shop.url} render={this.shop} />
                    <Route path={"*"}>
                        <ErrorContainer />
                    </Route>
                </Switch>
            </main>
        );
    }
}

export default Shop;