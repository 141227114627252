import React, { PureComponent } from 'react';
import ContentLoader from "../../../../../commons/components/contentLoader";
import MessageContainer from '../../../../../commons/components/messageContainer';
import Detail from "./detail";

export default class DetailsRenderer extends PureComponent {

    componentDidMount() {
        // this.props.callOnPageLoad(this.props.history);
        // console.log(this.props.location.pathname, "det")
        this.props.getProductWithDetail(this.props.history);
    }

    render() {
        if (this.props.isRequestingForDetails) {
            return (
                <ContentLoader loaderType="content-relative" />
            )
        } else if (this.props.location.state && this.props.location.state.product) {
            return (
                <Detail {...this.props} />
            );
        } else {
            return <MessageContainer msg="No Prdouct Matching as per your request" />;
        }
    }

}