import APIEndpoints from "../config/APIEndpoints";

const images = function () {
    //const PUBLIC_URL = APIEndpoints.isProduction ? "https://cdn.geniusgames.com.au/arcade-games/home/images" : "https://cdn.geniusgames.com.au/arcade-games/home/dev/images";
    const PUBLIC_URL = process.env.PUBLIC_URL;
    return {
        redCard: PUBLIC_URL + '/assets/images/red-card.png' + process.env.REACT_APP_VERSION,
        // logo: PUBLIC_URL + '/img/updated/jt-logo.png' + process.env.REACT_APP_VERSION,

        cartIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-cart.svg' + process.env.REACT_APP_VERSION,
        hotDealsIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-hot-white.svg' + process.env.REACT_APP_VERSION,
        // logo: PUBLIC_URL + '/assets/imgs/theme/logo.svg' + process.env.REACT_APP_VERSION,
        logo: PUBLIC_URL + '/assets/imgs/hindroot-1.png' + process.env.REACT_APP_VERSION,
        userIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-user.svg' + process.env.REACT_APP_VERSION,
        wishlistIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-heart.svg' + process.env.REACT_APP_VERSION,
        fbWhiteIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-facebook-white.svg' + process.env.REACT_APP_VERSION,
        twitterWhiteIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-twitter-white.svg' + process.env.REACT_APP_VERSION,
        instaWhiteIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-instagram-white.svg' + process.env.REACT_APP_VERSION,
        pintWhiteIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-pinterest-white.svg' + process.env.REACT_APP_VERSION,
        youtubeWhiteIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-youtube-white.svg' + process.env.REACT_APP_VERSION,
        searchIcon: PUBLIC_URL + '/assets/imgs/theme/icons/search.png' + process.env.REACT_APP_VERSION,
        compareIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-compare.svg' + process.env.REACT_APP_VERSION,
        phoneCallIcon: PUBLIC_URL + '/assets/imgs/theme/icons/phone-call.svg' + process.env.REACT_APP_VERSION,
        
        sliderOneImg: PUBLIC_URL + '/assets/imgs/slider/slider-1.png' + process.env.REACT_APP_VERSION,
        headphoneIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-headphone-white.svg' + process.env.REACT_APP_VERSION,
        
        defaultPrImg: PUBLIC_URL + '/assets/imgs/shop/product-1-1.jpg' + process.env.REACT_APP_VERSION,
        hoverPrImg: PUBLIC_URL + '/assets/imgs/shop/product-1-2.jpg' + process.env.REACT_APP_VERSION,
       
        bannerImg: PUBLIC_URL + '/assets/imgs/banner/banner-5.png' + process.env.REACT_APP_VERSION,
        hoverPrImg: PUBLIC_URL + '/assets/imgs/shop/product-1-2.jpg' + process.env.REACT_APP_VERSION,
        
        cateIcon: PUBLIC_URL + '/assets/imgs/theme/icons/category-1.svg' + process.env.REACT_APP_VERSION,
        newPrImg: PUBLIC_URL + '/assets/imgs/shop/thumbnail-3.jpg' + process.env.REACT_APP_VERSION,
        loginImg: PUBLIC_URL + '/assets/imgs/page/login-1.png' + process.env.REACT_APP_VERSION,
        fbIcon: PUBLIC_URL + '/assets/imgs/theme/icons/logo-facebook.svg' + process.env.REACT_APP_VERSION,
        googleIcon: PUBLIC_URL + '/assets/imgs/theme/icons/logo-google.svg' + process.env.REACT_APP_VERSION,
        iconLocation: PUBLIC_URL + '/assets/imgs/theme/icons/icon-location.svg' + process.env.REACT_APP_VERSION,
        iconContact: PUBLIC_URL + '/assets/imgs/theme/icons/icon-contact.svg' + process.env.REACT_APP_VERSION,
        iconEmail: PUBLIC_URL + '/assets/imgs/theme/icons/icon-email-2.svg' + process.env.REACT_APP_VERSION,
        iconClock: PUBLIC_URL + '/assets/imgs/theme/icons/icon-clock.svg' + process.env.REACT_APP_VERSION,
        featureIconOne: PUBLIC_URL + '/assets/imgs/theme/icons/icon-1.svg' + process.env.REACT_APP_VERSION,
        featureIconTwo: PUBLIC_URL + '/assets/imgs/theme/icons/icon-2.svg' + process.env.REACT_APP_VERSION,
        featureIconThree: PUBLIC_URL + '/assets/imgs/theme/icons/icon-3.svg' + process.env.REACT_APP_VERSION,
        featureIconFour: PUBLIC_URL + '/assets/imgs/theme/icons/icon-4.svg' + process.env.REACT_APP_VERSION,
        featureIconFive: PUBLIC_URL + '/assets/imgs/theme/icons/icon-5.svg' + process.env.REACT_APP_VERSION,
        featureIconSix: PUBLIC_URL + '/assets/imgs/theme/icons/icon-6.svg' + process.env.REACT_APP_VERSION,
        newsletterIcon: PUBLIC_URL + '/assets/imgs/banner/banner-9.png' + process.env.REACT_APP_VERSION,
        aboutUsOne: PUBLIC_URL + '/assets/imgs/page/about-1.png' + process.env.REACT_APP_VERSION,
        aboutUsTwo: PUBLIC_URL + '/assets/imgs/page/about-2.png' + process.env.REACT_APP_VERSION,
        aboutUsThree: PUBLIC_URL + '/assets/imgs/page/about-3.png' + process.env.REACT_APP_VERSION,
        aboutUsFour: PUBLIC_URL + '/assets/imgs/page/about-4.png' + process.env.REACT_APP_VERSION,
        aboutUsFive: PUBLIC_URL + '/assets/imgs/page/about-5.png' + process.env.REACT_APP_VERSION,
        fbBrandIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-facebook-brand.svg' + process.env.REACT_APP_VERSION,
        twitterBrandIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-twitter-brand.svg' + process.env.REACT_APP_VERSION,
        instaBrandIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-instagram-brand.svg' + process.env.REACT_APP_VERSION,
        youtubeBrandIcon: PUBLIC_URL + '/assets/imgs/theme/icons/icon-youtube-brand.svg' + process.env.REACT_APP_VERSION,
      
        iecImg: PUBLIC_URL + '/assets/imgs/IEC.jpg' + process.env.REACT_APP_VERSION,
        qualityResearch: PUBLIC_URL + '/assets/imgs/quality-research.jpg' + process.env.REACT_APP_VERSION,
        fssai: PUBLIC_URL + '/assets/imgs/fssai.jpg' + process.env.REACT_APP_VERSION,
        udyam: PUBLIC_URL + '/assets/imgs/udyam.jpg' + process.env.REACT_APP_VERSION,
        gst: PUBLIC_URL + '/assets/imgs/gst.jpg' + process.env.REACT_APP_VERSION,
        incorporation: PUBLIC_URL + '/assets/imgs/incorporation.jpg' + process.env.REACT_APP_VERSION,
        payZapper: PUBLIC_URL + '/assets/imgs/theme/icons/payment-zapper.svg' + process.env.REACT_APP_VERSION,
        payMaster: PUBLIC_URL + '/assets/imgs/theme/icons/payment-master.svg' + process.env.REACT_APP_VERSION,
        payVisa: PUBLIC_URL + '/assets/imgs/theme/icons/payment-visa.svg' + process.env.REACT_APP_VERSION,
        payPal: PUBLIC_URL + '/assets/imgs/theme/icons/payment-paypal.svg' + process.env.REACT_APP_VERSION,
        avatarImg: PUBLIC_URL + '/assets/imgs/avtar.png' + process.env.REACT_APP_VERSION,
        emptyCart: PUBLIC_URL + '/assets/imgs/empty-cart.png' + process.env.REACT_APP_VERSION,
        heartIcon: PUBLIC_URL + '/assets/imgs/heart.png' + process.env.REACT_APP_VERSION,
    };
}();

export default images;
