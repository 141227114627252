import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import HelperFunctions from "../../../../utils/HelperFunctions";
import common from "../../common";
import {FooterContainer} from "../footer";
import {HeaderContainer} from "../header";
import user from "../user";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import Cart from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class CartSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.history);
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-section login-wrap">
                    <div className="page-container">
                        <HeaderContainer path={this.props.match.path} historyProps={this.props.history}/>
                        <Cart {...this.props}/>
                        <FooterContainer showFooterContent = {true} path={this.props.match.path}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    cartItems: state[constants.NAME].cartItems,
    isShowPaymentLoader: state[constants.NAME].isShowPaymentLoader,
    cartItemsDetails: state[constants.NAME].cartItemsDetails,
    isCartItemsFetched: state[constants.NAME].cartItemsDetails && state[constants.NAME].cartItemsDetails.length > 0 && state[constants.NAME].cartItemsDetails[0].name,
    isOrderCompleted: state[constants.NAME].isOrderCompleted,
    userData: state[user.constants.NAME].userData,
    userAddressData: state[user.constants.NAME].userAddressData,
    cartOrderData: state[constants.NAME].cartOrderData,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
    countries: HelperFunctions.getActiveForSelect(state[user.constants.NAME].countries, {name: "--Select Country--", id: ''}),
    states: HelperFunctions.getActiveForSelect(state[user.constants.NAME].states, {name: "--Select State--", id: ''}),
    cities: HelperFunctions.getActiveForSelect(state[user.constants.NAME].cities, {name: "--Select City--", id: ''}),
});

const mapDispatchToProps = dispatch => {
    return {
        changeItemQuantity: (itemIndex, quantity) => dispatch(actions.changeItemQuantity(itemIndex, quantity)),
        removeFromCart: (itemIndex) => dispatch(actions.removeFromCart(itemIndex)),
        callOnPageLoad: (routerHistory) => dispatch(actions.callOnPageLoad(routerHistory)),
        emptyCart: () => dispatch(actions.emptyCart()),
        placeOrder: (address,payment_method, routerHostory) => dispatch(actions.placeOrder(address,payment_method, routerHostory)),
        getState: (country_id) => dispatch(user.actions.getState(country_id)),
        getCity: (country_id, state_id) => dispatch(user.actions.getCity(country_id, state_id)),
    }
};

export const CartContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CartSmart));
export default {actionTypes, actions, reducer, constants};
