import {NAME} from "./constants";

// export const GET_PRODUCT = `${NAME}/GET_PRODUCT`;

export const GET_PRODUCT_TYPE = `${NAME}/GET_PRODUCT_TYPE`;
export const GET_SHOP_PRODUCTS = `${NAME}/GET_SHOP_PRODUCTS`;
export const GET_SHOP_PRODUCTS_LOADING = `${NAME}/GET_SHOP_PRODUCTS_LOADING`;
export const PRODUCTS_LOAD_MORE = `${NAME}/PRODUCTS_LOAD_MORE`;
export const GET_SHOP_RELATED_PRODUCTS = `${NAME}/GET_SHOP_RELATED_PRODUCTS`;
export const SUBMIT_REVIEW_FORM = `${NAME}/SUBMIT_REVIEW_FORM`;
export const GET_PRODUCT_WITH_ID = `${NAME}/GET_PRODUCT_WITH_ID`;